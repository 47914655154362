import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { SubriderRequest } from 'src/app/core/models/subrider-request';
import { ModalService } from 'src/app/shared';
import { WaitlistService, Util } from 'src/app/core';
import { ActivatedRoute } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { flatMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'admin-subrider-rq-dtl',
  templateUrl: './subrider-rq-dtl.component.html',
  styleUrls: ['./subrider-rq-dtl.component.css']
})
export class SubriderRqDtlComponent implements OnInit {

  dateString = Util.dateString;
  loading = false;

  srdrRq: SubriderRequest;
  comment: string;

  constructor(
    private Activatedroute: ActivatedRoute,
    private wlistSvc: WaitlistService,
    private modal: ModalService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.loadData();
  }

  loadData() {
    let rqid = parseInt(this.Activatedroute.snapshot.paramMap.get("rqid"));
    this.wlistSvc.getSubriderRequest(rqid).subscribe(
      srdrRq => {
        this.srdrRq = srdrRq;
        srdrRq.comment = this.comment = srdrRq.comment||"";
        this.loading = false;
      },
      e => this.modal.error(e).subscribe(() => this.loading = false)
    );
  }

  dlg: {
    start_date?:NgbDateStruct,
    min_date?:NgbDateStruct,
    comment?: string
  }

  @ViewChild("approveDialog") approveDialog: TemplateRef<any>;
  approve() {
    this.dlg = {
      start_date: Util.toNgbDate(Util.addDays(1)),
      min_date: Util.toNgbDate(Util.addDays(1)),
    }
    this.modal.show({
      title: "Approve Sub-rider request",
      okBtn: "Ok",
      cancelBtn: "Cancel",
      template: this.approveDialog
    }).pipe(
      flatMap(res => {
        this.loading = res;
        return res ?
          this.wlistSvc.approveSubriderRequest(
            this.srdrRq.id,
            Util.fromNgbDate(this.dlg.start_date)
          )
          : of(false)
      })
    ).subscribe(
      res => res && this.loadData(),
      e => this.modal.error(e).subscribe(() => this.loading = false)
    );
  }

  @ViewChild("rejectDialog") rejectDialog: TemplateRef<any>;
  reject() {
    this.dlg = {
      comment: ""
    }
    this.modal.show({
      title: "Reject Sub-rider request",
      okBtn: "Ok",
      cancelBtn: "Cancel",
      template: this.rejectDialog,
      okBtnDisabled: () => !this.dlg.comment,
      okBtnHelp: () => "Please enter comment prior to finalizing rejection"
    }).pipe(
      flatMap(res => {
        this.loading = res;
        return res ?
          this.wlistSvc.rejectSubriderRequest(
            this.srdrRq.id,
            this.dlg.comment
          )
          : of(false)
      })
    ).subscribe(
      res => res && this.loadData(),
      e => this.modal.error(e).subscribe(() => this.loading = false)
    );
  }

  saveComment() {
    this.wlistSvc.saveSubriderRequestComment(this.srdrRq.id, this.comment).subscribe(
      res => res && this.loadData(),
      e => this.modal.error(e).subscribe(() => this.loading = false)
    )
  }
}
