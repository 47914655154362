import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as chartJs from 'chart.js';

import { childRouteLinkItems, LinkItem } from '../admin-child-routes';
import { faHandPaper, faDharmachakra, faStarHalfAlt, faStar, faHandPointer, faHandPointUp,faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import {AdminSummary} from '../models/admin-summary'
import { Util } from 'src/app/core';
import { ChartEvent } from 'chart.js';

@Component({
  selector: 'admin-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  newRqIcon = faHandPaper;
  ptnlOfferIcon = faStarHalfAlt;
  rcntOffersIcon = faStar;
  driverIcon = faDharmachakra;
  pendDrvrIcon = faHandPointer;
  srdrRqIcon = faHandPointUp;
  biweeklyIcon = faCalendarCheck;
  smry: AdminSummary;

  linkItems: LinkItem[];
  loading = false;
  
  newRequests = 0;
  potentialOffers = 0;
  pendingOffers = 0;
  assignedOffers = 0;
  declinedOffers = 0;
  expiredOffers = 0;
  pendingDrivers = 0;
  subriderRequests = 0;

  driverLeaving: {name:string,date:Date}[] = [];
  driverLeavingOrderByName = true;

  pieChartLabels: string[] = [
    'Pending Offers',
    'Assigned Offers',
    'Declined Offers',
    'Expired Offers'
  ];
  pieChartData = {
    datasets:[{
      data:[0,0,0,0],
      backgroundColor: ['#FFA1B5', '#00A1F1', '#4CAF50', '#FFBB00'],
      hoverOffset: 4
    }],
    labels:this.pieChartLabels
  };
  pieChartOptions: chartJs.ChartOptions = {
    plugins: {
          legend: {
            position: 'top'
        },
    },
    pie:{
      
    },
    animation:false,
  };


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private readonly httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    this.linkItems = childRouteLinkItems;
    this.loading = true;
    this.httpClient.get<AdminSummary>('api/adm/smry')
    .subscribe(s=>{
      this.smry = s;
      this.newRequests = s.newRequests;
      this.potentialOffers = s.potentialOffers;
      this.pendingOffers = s.pendingOffers;
      this.assignedOffers = s.assignedOffers;
      this.declinedOffers = s.declinedOffers;
      this.expiredOffers = s.expiredOffers;
      this.pendingDrivers = s.pendingDrivers;
      this.subriderRequests = s.subriderRequests;
      this.pieChartData.datasets[0].data = [s.pendingOffers,s.assignedOffers,s.declinedOffers,s.expiredOffers];
      this.driverLeaving = s.driversLeaving.sort((x, y) => Util.stringCompareNoCase(x.name,y.name));
      this.driverLeaving.forEach(v=>Util.FixDates(v,["date"]));
      this.loading = false;
    });
  }

  reorderDriverLeaving() {
    this.driverLeavingOrderByName = !this.driverLeavingOrderByName;
    let c:(x:{name:string,date:Date},y:{name:string,date:Date})=>number = this.driverLeavingOrderByName?
      (x, y) => Util.stringCompareNoCase(x.name, y.name):
      (x, y) => x.date.getTime() - y.date.getTime();
    this.driverLeaving = [...this.driverLeaving.sort(c)];
  }

  showChart():boolean {
    return this.pieChartData.datasets[0].data.some(d => d != 0);
  }

  getSegment(e: {event?: ChartEvent,active?: object[]}) {
    if (e.active.length) {
      const idx = e.active[0]["index"];
      const label = this.pieChartLabels[idx];
      let queryParams = null;
      if (label === 'Pending Offers') {
        queryParams = {recent_pending:1};
      }
      if (label === 'Assigned Offers') {
        queryParams = { recent_assigned: 1 };
      }
      if (label === 'Declined Offers') {
        queryParams = { recent_declined: 1 };
      }
      if (label === 'Expired Offers') {
        queryParams = { recent_expired: 1 };
      }
      if (queryParams) {
        this.router.navigate(['../requests'], {
          relativeTo: this.route,
          queryParams,
          queryParamsHandling: 'merge'
        });
      }
    }
  }

  driversLeavingQry(vpname:string) {
    let res= {filter:[`vpname~=${vpname}`,"status~=Active"]};
    return res;
  }

}
