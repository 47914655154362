import { Component, OnInit } from '@angular/core';
import { WaitlistService } from 'src/app/core';
import { ModalService } from 'src/app/shared';

@Component({
  selector: 'admin-poffers',
  templateUrl: './poffers.component.html',
  styleUrls: ['./poffers.component.css']
})
export class PoffersComponent implements OnInit {
  poffers:{name:string,count:number}[];
  loading= false;
  constructor(
    private modal: ModalService,
    private wlistSvc: WaitlistService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.wlistSvc.getPotentialOffers().subscribe(
      poffers => {
        this.poffers = poffers;
        this.loading=false;
      },
      e => this.modal.error(e, 'Can not get potential offers').subscribe()
    )
  }

}
