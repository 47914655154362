import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VanpoolDtlRoutingModule } from './vanpool-dtl-routing.module';
import { VanpoolDtlComponent } from './vanpool-dtl/vanpool-dtl.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerModule } from '@uiowa/spinner';
import { ModalService, SharedModule } from 'src/app/shared';
import { ConfigService, MembersService, Util } from 'src/app/core';


@NgModule({
  declarations: [VanpoolDtlComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    SpinnerModule,
    NgbModule,
    VanpoolDtlRoutingModule
  ],
  providers: [
    ModalService,
    MembersService,
    ConfigService,
    ModalService,
    Util
  ]
})
export class VanpoolDtlModule { }
