import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { SortDirection, SortPropDir, TableColumn } from '@siemens/ngx-datatable';
import { of } from 'rxjs';
import { flatMap, switchMap } from 'rxjs/operators';
import { ConfigService, Util, VehicleService } from 'src/app/core';
import { TotalCharge } from 'src/app/core/models/total-charge';
import { DesiredCapacity } from 'src/app/core/models/desired-capacity';
import { ModalService } from 'src/app/shared';
import { DatatableComponent } from 'src/app/shared/components/datatable/datatable.component';
import { Vehicle } from 'src/app/core/models/vehicle';

@Component({
  selector: 'vehicle-dtl',
  templateUrl: './vehicle-dtl.component.html',
  styleUrls: ['./vehicle-dtl.component.css'],
})
export class VehicleDtlComponent implements OnInit, AfterViewInit {
  loading = false;
  vhclid: number;
  original: Vehicle;
  vehicle: Vehicle;
  vhclTypes: string[];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private vhclSvc: VehicleService,
    private cfgSvc: ConfigService,
    private modal: ModalService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.vhclTypes = this.cfgSvc.current.vhcl_types;
    this.activatedRoute.params.subscribe((p) =>
      setTimeout(() => {
        this.vhclid = parseInt(p['id']);
        if (this.vhclid == undefined) return;
        this.loadData();
      })
    );
  }

  loadData() {
    this.loading = true;
    this.vhclSvc.getVehicleDetails(this.vhclid).subscribe(
      (v: Vehicle) => {
        v.comment = v.comment || '';
        this.vehicle = { ...v };
        this.original = v;
        this.loading = false;
      },
      (e) => this.modal.error(e).subscribe(() => (this.loading = false))
    );
  }

  update() {
    this.vhclSvc.update(this.vehicle).subscribe(
      (res) => {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['admin', 'vehicle-dtl', res]);
      },
      (e) => (this.loading = !this.modal.error(e).subscribe())
    );
  }

  dirty(): boolean {
    return !Util.compareProperties(this.original, this.vehicle);
  }
}
