<div class="d-flex m-0 h-100">
  <div id="side-nav" class="d-none d-lg-block flex-shrink-0 side-nav p-0 pt-1">
    <ul class="nav flex-column">
      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: true }"
          routerLink="dash-board"
          title="Admin Home"
        >
          <fa-icon
            [icon]="faHome"
            [fixedWidth]="true"
            aria-hidden="true"
          ></fa-icon>
          <span *ngIf="expanded">Admin Home</span>
        </a>
      </li>
      <li class="nav-item" *ngFor="let item of linkItems">
        <a
          class="nav-link"
          routerLinkActive="active-link"
          routerLink="{{ item.path }}"
          [title]="item.text"
        >
          <fa-icon
            *ngIf="item.icon"
            [icon]="$any(item.icon)"
            [fixedWidth]="true"
            aria-hidden="true"
          ></fa-icon>
          <span *ngIf="expanded">{{ item.text }}</span>
        </a>
      </li>
      <a class="nav-link" [title]="expanded?'Collapse':'Expand'" (click)="expanded=!expanded">
        <fa-icon [icon]="expanded?faAngleDoubleLeft:faAngleDoubleRight" [fixedWidth]="true" aria-hidden="true"></fa-icon>
      </a>

    </ul>

  </div>
  <div id="main-content" class="flex-fill px-3" style="overflow-y: auto;">
    <router-outlet></router-outlet>
  </div>
</div>
