import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { PersonalInfo } from '../models/personal-info';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class ProfileService {
  private readonly api = 'api/profile';
  constructor(private readonly http: HttpClient) {}

  getMyPersonalInfo(): Observable<PersonalInfo> {
    return this.http.get<PersonalInfo>(this.api);
  }

  acceptDriverAgreement(): Observable<boolean> {
    return this.http.post<any>(this.api+'/drv_agree', null)
      .pipe(switchMap(() => of(true)));
  }
  
  acceptBackupDriverAgreement(): Observable<boolean> {
    return this.http.post<any>(this.api+'/bdrv_agree', null)
      .pipe(switchMap(() => of(true)));
  }
  
}
