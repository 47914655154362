import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WaitlistService, vanpoolService, Util } from 'src/app/core';
import { WlRequest } from 'src/app/core/models/wl-request';
import { ModalService } from 'src/app/shared';
import { VacantSeats } from 'src/app/core/models/vacant-seats';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { flatMap } from 'rxjs/operators';
import { Observable, empty, of } from 'rxjs';

// $$Implements SW.ADM.WLIST.PG_DTL
@Component({
  selector: 'admin-wl-rq-dtl',
  templateUrl: './wl-rq-dtl.component.html',
  styleUrls: ['./wl-rq-dtl.component.css']
})
export class WlRqDtlComponent implements OnInit {
  @ViewChild("removeRequestDialog") removeRequestDialog: TemplateRef<any>;
  @ViewChild("offerDialog") offerDialog: TemplateRef<any>;
  @ViewChild("extendOfferDialog") extendOfferDialog: TemplateRef<any>;
  @ViewChild("changeAvailableDialog") changeAvailableDialog: TemplateRef<any>;
  dateString = Util.dateString;
  loading = false;
  wlrq: WlRequest;
  comment: string;
  decl_expl: string;
  vacant: VacantSeats = {count:0,date:null}

  constructor(
    private Activatedroute: ActivatedRoute,
    private wlistSvc: WaitlistService,
    private vanpoolSvc: vanpoolService,
    private modal: ModalService
  ) {
    
  }

  ngOnInit(): void {
    this.loading = true;
    this.loadData();
  }

  loadData() {
    let rqid = parseInt(this.Activatedroute.snapshot.paramMap.get("rqid"));
    this.wlistSvc.getRequestDetails(rqid).subscribe(
      wlrq => {
        this.wlrq = wlrq;
        this.comment = wlrq.comment = wlrq.comment||"";
        this.loading = false;
        if (wlrq.status == 'Active Waiting')
          this.vanpoolSvc.getVacantSeats(wlrq.vpoolid).subscribe(
            vacant => this.vacant=vacant,
            e => this.modal.error(e, 'Can not get number of vacant seats').subscribe()
          )
      },
      e => this.modal.error(e).subscribe(() => this.loading = false)
    );
  }

  removeReason = 0;

  removeRequest() {
    this.removeReason = (this.wlrq.status == 'Active Waiting')?1:5;

    this.modal.show({
      title: "Remove Waiting List Request",
      okBtn: "Remove request",
      cancelBtn: "Cancel",
      template: this.removeRequestDialog
    }).pipe(
      flatMap(res => {
        this.loading = res;
        return res ?
          this.wlistSvc.removeOffer(
            this.wlrq.id,
            this.removeReason
          )
          : of(false)
      })
    ).subscribe(
      res=> res&& this.loadData(),
      e => this.modal.error(e).subscribe(() => this.loading = false)
    );
  }

  offer:{
    available_date?: NgbDateStruct,
    expiration_date?: NgbDateStruct,
    today?:NgbDateStruct,
    min_date:NgbDateStruct
  }

  offerSeat() {
    if (!this.vacant.count)
      return;
    this.offer = {
      available_date: Util.toNgbDate(this.vacant.date),
      expiration_date: Util.toNgbDate(Util.addBDays(5)),
      today: Util.toNgbDate(Util.today),
      min_date: Util.toNgbDate(this.vacant.date),
    };
    this.modal.show({
      title:"Offer Seat", 
      okBtn: "Submit offer",
      cancelBtn:"Cancel",
      template:this.offerDialog
    }).pipe(
      flatMap(res => {
        this.loading = res;
        return res ? 
        this.wlistSvc.submitOffer(
          this.wlrq.id, 
          Util.fromNgbDate(this.offer.available_date), 
          Util.fromNgbDate(this.offer.expiration_date)
        )
        :of(false)
      })
    ).subscribe(
      res=>res&&this.loadData(),
      e => this.modal.error(e).subscribe(() => this.loading = false)
    );
  }

  saveComment() {
    this.wlistSvc.saveComment(this.wlrq.id,this.comment).subscribe(
      res=>res&&this.loadData(),
      e => this.modal.error(e).subscribe(() => this.loading = false)
    )
  }

  extendOffer() {
    this.offer = {
      expiration_date: Util.toNgbDate(Util.addBDays(5, this.wlrq.xpr_date)),
      min_date: Util.toNgbDate(Util.addDays(1, this.wlrq.xpr_date)),
    };
    this.modal.show({
      title: "Extend Offer",
      okBtn: "Ok",
      cancelBtn: "Cancel",
      template: this.extendOfferDialog
    }).pipe(
      flatMap(res => {
        this.loading = res;
        return res ?
          this.wlistSvc.extendOffer(
            this.wlrq.id,
            Util.fromNgbDate(this.offer.expiration_date)
          )
          : of(false)
      })
    ).subscribe(
      res => res && this.loadData(),
      e => this.modal.error(e).subscribe(() => this.loading = false)
    );
  }

  changeAvailableDate() {
    this.offer = {
      available_date: Util.toNgbDate(this.wlrq.seat_date),
      min_date: Util.toNgbDate(Util.today),
    };
    this.modal.show({
      title: "Change Available Date",
      okBtn: "Ok",
      cancelBtn: "Cancel",
      template: this.changeAvailableDialog
    }).pipe(
      flatMap(res => {
        this.loading = res;
        return res ?
          this.wlistSvc.changeAvailableDate(
            this.wlrq.id,
            Util.fromNgbDate(this.offer.available_date)
          )
          : of(false)
      })
    ).subscribe(
      res => res && this.loadData(),
      e => this.modal.error(e).subscribe(() => this.loading = false)
    );
  }

}
