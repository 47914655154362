import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubridersRoutingModule } from './subriders-routing.module';
import { SubridersComponent } from './subriders/subriders.component';
import { SharedModule } from 'src/app/shared';
import { Util } from 'src/app/core';


@NgModule({
  declarations: [SubridersComponent],
  imports: [
    CommonModule,
    SharedModule,
    SubridersRoutingModule
  ],
  providers: [
    Util
  ]
})
export class SubridersModule { }
