export class LoanerHistory {
  id: number = 0;
  eqno: string = '';
  type: string = '';
  make: string = '';
  model: string = '';
  year: number = 0;
  color: string = '';
  capacity: number = 0;
  startDate: Date = new Date(0);
  endDate: Date = new Date(0);
  comment: string = '';
}
