export class ApplicationUser {
  /**
   * Application User View Model.
   */
  constructor(
    public hawkId: string = '',
    public originalUser?: string | null,
    public role: string = ''
  ) {}

  isAuthenticated(): boolean {
    return !!this.hawkId;
  }

  isEmployee(): boolean {
    return (
      this.role === 'Employee' ||
      this.role === 'Admin'
    );
  }

  isAdmin(): boolean {
    return this.role === 'Admin';
  }

}
