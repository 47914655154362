export class PayrollDeduction {
  univId: string = '';
  deductionCode: string = '';
  dedDescription: string = '';
  effectiveDate: Date = new Date(0);
  endDate: Date = new Date(0);
  rate: number = 0;
  deductAmount: number = 0;
  vanpoolId: number = 0;
  vanpoolName: string = '';
  personId: number = 0;
  memberStartDate: Date = new Date(0);
  memberEndDate?: Date = new Date(0);
  firstName: string = '';
  lastName: string = '';
  paymentTypeId: number = 0;
  deductionReason: string = '';
  memberTypeId: number = 0;
  code: string = '';
}
