import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VehicleAddRoutingModule } from './vehicle-add-routing.module';
import { VehicleAddComponent } from './vehicle-add/vehicle-add.component';
import { ModalService, SharedModule } from 'src/app/shared';
import { ConfigService, Util } from 'src/app/core';
import { FormsModule } from '@angular/forms';
import { SpinnerModule } from '@uiowa/spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [VehicleAddComponent],
  imports: [CommonModule, SharedModule, FormsModule, SpinnerModule, NgbModule, VehicleAddRoutingModule],
  providers: [ModalService, ConfigService, Util],
})
export class VehicleAddModule {}
