import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

enum Day {
    Sunday = 0,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday
};

@Injectable({
    providedIn: 'root'
})
export class Util {
    static holidayCache:{[year:number]:{[date:string]:number}} = {};
    static holidays(year: number): { [date: string]: number } {
        if (this.holidayCache.hasOwnProperty(year))
            return this.holidayCache[year];
        let res = [
            (()=>{// New Year
                let ny = new Date(year,0,1);
                if (ny.getDay() == Day.Sunday)
                    ny = new Date(year, 0, 2);
                else if (ny.getDay()==Day.Saturday)
                    ny = new Date(year-1, 11, 31);
                return ny;
            })(),
            (() => {// MLK
                let jan1 = new Date(year,0,1);
                return Util.range(14,7)
                    .map(d => Util.addDays(d, jan1)).filter(d=>d.getDay()==Day.Monday)[0];
            })(),
            (() => {// Memorial Day
                let may1 = new Date(year, 4, 1);
                return Util.range(24, 7)
                    .map(d => Util.addDays(d, may1)).filter(d => d.getDay() == Day.Monday)[0];
            })(),
            new Date(year,6,4), // Independence Day
            (() => {// Labor Day
                let sep1 = new Date(year, 8, 1);
                return Util.range(0, 7)
                    .map(d => Util.addDays(d, sep1)).filter(d => d.getDay() == Day.Monday)[0];
            })(),
            (() => {// Thanksgiving
                let nov1 = new Date(year, 10, 1);
                return Util.range(21, 7)
                    .map(d => Util.addDays(d, nov1)).filter(d => d.getDay() == Day.Thursday)[0];
            })(),
            (() => {// Black Friday
                let nov1 = new Date(year, 10, 1);
                return Util.range(22, 7)
                    .map(d => Util.addDays(d, nov1)).filter(d => d.getDay() == Day.Friday)[0];
            })(),
            (() => {// Christmas
                let xmas = new Date(year, 11, 25);
                if (xmas.getDay() == Day.Sunday)
                    xmas = new Date(year, 11, 27);
                else if (xmas.getDay() == Day.Saturday)
                    xmas = new Date(year, 11, 23);
                return xmas;
            })(),
            (() => {// Christmas
                let xmas1 = new Date(year, 11, 26);
                if (xmas1.getDay() == Day.Wednesday || xmas1.getDay() == Day.Saturday)
                    xmas1 = new Date(year, 11, 24);
                return xmas1;
            })(),
        ].reduce((dict,date)=>{dict[date.toISOString()]=1;return dict;},{});
        this.holidayCache[year]=res;
        return res;
    }

    static IsBDay(date:Date):boolean {
        if (date.getDay() == Day.Saturday || date.getDay() == Day.Sunday)
            return false;
        let holidays = Util.holidays(date.getFullYear());
        if (holidays.hasOwnProperty(new Date(date.setHours(0,0,0,0)).toISOString()))
            return false;
        return true;
    }

    
    static get today():Date {
        return new Date(new Date().setHours(0,0,0,0));
    }

    public static addDays(days: number, date?: Date): Date {
        date = date||Util.today;
        return new Date(new Date(date).setDate(date.getDate() + days));
    }

    public static addBDays(days:number, date?: Date): Date {
        date = date || Util.today;
        if (days == 0 && !this.IsBDay(date))
            days = 1;
        for (let count=0; count < days;) {
            date = Util.addDays(1,date);
            if (this.IsBDay(date))
                count++;
        }
        return date;
    }

    public static addMonths(months: number, date?: Date): Date {
        date = date || Util.today;
        return new Date(new Date(date).setMonth(date.getMonth() + months));
    }

    public static minDate(d1:Date,d2:Date) {
        return new Date(Math.min(Number(d1),Number(d2)));
    }

    public static maxDate(d1:Date,d2:Date) {
        return new Date(Math.max(Number(d1),Number(d2)));
    }

    public static parseAsLocalDate(s:string) {
        let d = new Date(s);
        return new Date(new Date(d).setMinutes(d.getMinutes() + d.getTimezoneOffset()));
    }
    static monthFmt = new Intl.DateTimeFormat("en", { month: "long" });
    public static monthName(month:number): string {
        return Util.monthFmt.format(new Date().setMonth(month));
    }

    static dateFmt: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    }
    public static dateString(date:Date):string {
        return date && date.toLocaleDateString('en-US', Util.dateFmt) || "";
    }

    static dateTimeFormat = new Intl.DateTimeFormat('en', { timeStyle: 'short', dateStyle: 'short' });

    public static dateTimeString(date: Date): string {
        return date && Util.dateTimeFormat.format(date) || "";
    }

    public static toNgbDate(date:Date):{year:number,month:number,day:number} {
        return date && {year:date.getFullYear(),month:date.getMonth()+1,day:date.getDate()} || null;
    }

    public static fromNgbDate(ngbd: { year: number, month: number, day: number }):Date {
        return ngbd && new Date(ngbd.year,ngbd.month-1,ngbd.day) || null;
    }
    
    public static toISODateString(date:Date): string {
        return date&&date.toISOString().split('T')[0]||"";
    }

    static currency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', });
    public static currencyString(v:number) {
        return Util.currency.format(v);
    }

    public static FixDates<T>(o: T, keys:readonly string[]):T {
        keys.forEach(k => { o[k] = o[k]&&(new Date(o[k]))} );
        return o;
    }

    public static DatePropertiesKeys(o: object): string[] {
        let result:string[] = [];
        Object.keys(o).forEach(k => {if (o[k] instanceof Date) result.push(k)});
        return result;
    }

    static range (start:number, count:number): number[] {
        return Array(count).fill(0).map((x, i) => start + i);
    }

    public static collatorNoCase = new Intl.Collator('en-US', { sensitivity: 'base' });
    public static stringCompareNoCase(x:string, y: string):number {
        return Util.collatorNoCase.compare(x,y);
    }

    static range2(start:number, stop: number, step?: number) {
        step = step || ((start<stop)?1:-1);
        let count = Math.ceil(Math.abs((stop-start)/step));
        return Array(count).fill(0).map((x,i)=>start+i*step);
    }

    static getEM(): number {
        return Number(getComputedStyle(document.body, null).fontSize.replace(/[^\d]/g, ''))
    }

    static CommandToHttpParams(cmd:any):HttpParams {
        let params = new HttpParams();
        Object.keys(cmd).forEach(k => {
            let val = cmd[k];
            if (val instanceof Date)
                val = this.toISODateString(val);
            if (Array.isArray(val))
                val.forEach(e => {
                    if (e instanceof Date)
                        e = this.toISODateString(e);
                    params = params.append(k, e);
                });
            else
                params = params.append(k, val)
        });
        return params;
    }

    static CommandToParams(cmd:any):{[key: string]: any} {
        let params=[];
        Object.keys(cmd).forEach(k => {
            let val = cmd[k];
            if (val instanceof Date)
                val = this.toISODateString(val);
            else
            if (Array.isArray(val))
                val = val.map(e => (e instanceof Date)? this.toISODateString(e):e);
            params[k] =val;
        });
        return params;
    }

    static CommandToFormData(cmd: any): FormData {
        let formData = new FormData();
        Object.keys(cmd).forEach(k => {
            let val = cmd[k];
            if (val instanceof Date)
                val = this.toISODateString(val);
            if (Array.isArray(val))
                val.forEach(e => {
                    if (e instanceof Date)
                        e = this.toISODateString(e);
                    formData.append(k, e);
                });
            else
                formData.append(k, val)
        });
        return formData;
    }

    static compareProperties(o1:object,o2:object):boolean {
        return Object.keys(o1).every(k => !o2.hasOwnProperty(k) || (
            (o1[k] instanceof Date) ? +o2[k] == +o1[k] :
            (typeof (o1[k]) == 'object') ? Util.objectsEqual(o1[k], o2[k]) :
            o2[k] == o1[k]
        ));
    }

    static objectsEqual(o1:object,o2:object):boolean {
        return o1 == null && o2 == null || (
            o1 != null && o2 != null &&
            Object.keys(o1).length === Object.keys(o2).length
            && Object.keys(o1).every(k => 
                (o1[k] instanceof Date) ? +o2[k] == +o1[k] : 
                (typeof (o1[k]) == 'object') ? Util.objectsEqual(o1[k],o2[k]):
                o2[k] == o1[k]
            )
        );
    }

    static arraysEqual(a1:object[],a2:object[]):boolean{
        return a1.length === a2.length && a1.every((o, idx) => Util.objectsEqual(o, a2[idx]));
    }

    static errorMessage(err): string {
        if (typeof err == "string")
            return err;
        if (err.error && typeof err.error == "string")
            return err.error;
        let result = "";
        if (err.message)
            result = err.message;
        if (err.error && err.error.Message)
            result += (result && '\n') + err.error.Message;
        return result;
    }

    static queryParameters(qry?:string) {
        if (qry === undefined)
            qry = window.location.search;
        qry = qry.replace(/^\?/, '');
        let res = {};
        for (let par of qry.split('&')) {
            let kv=par.split('=');
            res[kv[0]]=kv[1];
        }
        return res;
    }

    static copy(src:object,tgt:object) {
        Object.keys(tgt).forEach(k=>{
            if (src.hasOwnProperty(k))
                tgt[k]=src[k];
        })
    }

    static undef(o:object) {
        Object.keys(o).forEach(k => {
            o[k]=undefined;
        });
    }

    static toArray<T>(o: Object, sel: (kvp: [k: string, v: T]) => T, order:(T)=>string):T[] {
        return Object.entries(o).map(sel).sort((a, b) => order(a).localeCompare(order(b)))
    }

    static getProp(o:Object, p:string|string[]) {
        let path:string[];
        if (typeof(p)=="string")
            path = p.split('.');
        else
            path = p;
        let v = o[path[0]]
        if (path.length == 1)
            return v;
        return this.getProp(v,path.slice(1));
    }
}
