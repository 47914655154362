export class MemberRecord {
  id: number = 0;
  vpoolid: number = 0;
  vpname: string = "";
  hours: string = "";
  type: string = "";
  univId: string = "";
  userId: number = 0;
  first_name: string = "";
  last_name: string = "";
  status: string = "";
  start_date: Date = new Date(0);
  end_date?: Date = new Date(0);
  end_reason: string = "";
  requests: number = 0;
  payment: string = "";
  comment: string = "";
  hawkid?: string;
  drq_status?: string;
  drq_date?: Date = new Date(0);
  driver_end_date?: Date = new Date(0);
  bdrq_status?: string;
  bdrq_date?: Date = new Date(0);
  bdriver_end_date?: Date = new Date(0);
  member_till_date?: Date = new Date(0);
  swap_start_date?: Date = new Date(0);
  swap_end_date?: Date = new Date(0);
  has_future_members?: boolean;
}