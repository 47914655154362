import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WaitlistService, Util } from 'src/app/core';
import { ModalService } from 'src/app/shared';
import { SubriderRecord } from 'src/app/core/models/subrider-record';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { flatMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'subrider-dtl',
  templateUrl: './subrider-dtl.component.html',
  styleUrls: ['./subrider-dtl.component.css']
})
export class SubriderDtlComponent implements OnInit {
  dateString = Util.dateString;
  loading = false;
  srdr: SubriderRecord;
  comment:string;

  constructor(
    private Activatedroute: ActivatedRoute,
    private wlistSvc: WaitlistService,
    private modal: ModalService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.loadData();
  }

  loadData() {
    let rqid = parseInt(this.Activatedroute.snapshot.paramMap.get("rqid"));
    this.wlistSvc.getSubriderRecord(rqid).subscribe(
      srdr => {
        this.srdr = srdr;
        srdr.comment = this.comment = srdr.comment || "";
        this.loading = false;
      },
      e => this.modal.error(e).subscribe(() => this.loading = false)
    );
  }


  dlg: {
    end_date?: NgbDateStruct,
    min_date?: NgbDateStruct,
  }

  @ViewChild("endDialog") endDialog: TemplateRef<any>;
  end() {
    this.dlg = {
      end_date: Util.toNgbDate(Util.today),
      min_date: Util.toNgbDate(Util.today),
    }
    this.modal.show({
      title: "End Sub-ridership",
      okBtn: "Ok",
      cancelBtn: "Cancel",
      template: this.endDialog
    }).pipe(
      flatMap(res => {
        this.loading = res;
        return res ?
          this.wlistSvc.endSubriderRecord(
            this.srdr.id,
            Util.fromNgbDate(this.dlg.end_date)
          )
          : of(false)
      })
    ).subscribe(
      res => res && this.loadData(),
      e => this.modal.error(e).subscribe(() => this.loading = false)
    );
  }

  saveComment() {
    this.wlistSvc.saveSubriderRecordComment(this.srdr.id, this.comment).subscribe(
      res => res && this.loadData(),
      e => this.modal.error(e).subscribe(() => this.loading = false)
    )
  }

}
