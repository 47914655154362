import { Component, OnInit } from '@angular/core';
import { childRouteLinkItems, LinkItem } from '../admin-child-routes';
import { faHome,faAngleDoubleRight,faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
  faHome = faHome;
  faAngleDoubleRight = faAngleDoubleRight;
  faAngleDoubleLeft = faAngleDoubleLeft;
  linkItems: LinkItem[];
  expanded = false;
  constructor() {}

  ngOnInit() {
    this.linkItems = childRouteLinkItems;
  }
}
