import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared';
import { Util } from 'src/app/core';
import { PayrollDeductComponent } from './payroll-deduct/payroll-deduct.component';
import { PayrollDeductRoutingModule } from './payroll-deduct-routing.module';
import { PayrollService } from 'src/app/core/services/payroll.service';

@NgModule({
  declarations: [PayrollDeductComponent],
  imports: [CommonModule, SharedModule, PayrollDeductRoutingModule],
  providers: [Util, PayrollService],
})
export class PayrollDeductModule {}
