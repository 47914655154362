export class SubriderRequest {
  id: number = 0;
  vpoolid: number = 0;
  vpname: string = "";
  hours: string = "";
  univId: string = "";
  userId: number = 0;
  first_name: string = "";
  last_name: string = "";
  hawkid: string = "";
  status: string = "";
  change_date: Date = new Date(0);
  comment: string = "";
}