<section class="container mt-2">
  <ngb-alert type="warning" class="fade show" [dismissible]="false">
    <p class="alert-heading" style="font-weight: 500;">Warning</p>
    <p>Please logout or stop impersonation after you finish testing.</p>
  </ngb-alert>
  <div *ngIf="(userService.user$ | async) as user">
    <form *ngIf="!user.originalUser" class="form-inline" (ngSubmit)="impersonate()">
      <div class="form-group mb-2 mr-2">
        <label for="hawkid" class="sr-only">HawkID</label>
        <input type="text" class="form-control" id="hawkid" name="hawkId" placeholder="user HawkID" [(ngModel)]="hawkId">
      </div>
      <button type="submit" class="btn btn-primary mb-2">Impersonate</button>
    </form>
    <form *ngIf="user.originalUser" class="form-inline" (ngSubmit)="stopImpersonate()">
      <button type="submit" class="btn btn-primary mb-2">Stop Impersonate</button>
    </form>
  </div>
</section>
