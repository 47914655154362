<div class="container">
  <section class="my-4 bg-white shadow">
    <div class="bg-success py-2 px-3">
      <p class="h4 text-white m-0">
        <i class="fa fa-hand fa-lg"></i>
        Add Vanpool
      </p>
    </div>
    <div class="p-3">
      <div class="w-100">
        <div class="row pl-2">
          <div class="card-field">
            <span class="card-field-label">Name</span>
            <span class="card-field-value">
              <input type="text" [(ngModel)]="this.vanpool.vpname" class="form-control"
              placeholder="Please enter unique name"/>
            </span>
          </div>
          <div class="card-field">
            <span class="card-field-label">Vehicle:</span>
            <span class="card-field-value">
              <select [(ngModel)]="vanpool.eqno">
                <option *ngFor="let v of vehicles" value="{{v.eqno}}">
                  {{v.eqno}} {{v.model}} {{v.year}}
                </option>
              </select>
            </span>
          </div>
          <div class="card-field">
            <span class="card-field-label">Community</span>
            <span class="card-field-value">
              <select [(ngModel)]="vanpool.cmnty">
                <option *ngFor="let c of communities">
                  {{c}}
                </option>
              </select>
            </span>
          </div>
          <div class="card-field">
            <span class="card-field-label">Work Hours</span>
            <span class="card-field-value">
              <select [(ngModel)]="vanpool.hours">
                <option *ngFor="let h of hours">
                  {{h}}
                </option>
              </select>
            </span>
          </div>
          <div class="card-field">
            <span class="card-field-label">Work days</span>
            <span class="card-field-value">
              <select [(ngModel)]="vanpool.workdays">
                <option *ngFor="let d of days" >
                  {{d}}
                </option>
              </select>
            </span>
          </div>
          <div class="card-field">
            <span class="card-field-label">Desired capacity</span>
            <span class="card-field-value">
              <input type="number"  min="3" [(ngModel)]="vanpool.dcap">
            </span>
          </div>
          <div class="card-field">
            <span class="card-field-label">Allow Sub-rider</span>
            <span class="card-field-value">
              <select [(ngModel)]="vanpool.accept_subriders">
                <option [ngValue]="true">Yes</option>
                <option [ngValue]="false">No</option>
              </select>
            </span>
          </div>
          <div class="card-field">
            <span class="card-field-label">Total charge</span>
            <span class="card-field-value">
              <input type="number"  min="1" [(ngModel)]="vanpool.ttl_chrg">
            </span>
          </div>
          <div class="card-field">
            <span class="card-field-label">Start date</span>
            <span class="card-field-value">
                <input name="date" type="text" ngbDatepicker [container]="'body'" #date="ngbDatepicker" [(ngModel)]="dlg.date" class="col-6" (click)="date.toggle()" [minDate]="dlg.min_date">
            </span>
          </div>
          <div class="card-field-long">
            <span class="card-field-label">Comment:</span>
            <input type="text" [(ngModel)]="vanpool.comment" class="form-control" placeholder="comment" />
          </div>
        </div>
      </div>

      <div class="row text-center my-1">
        <div class="col-4">
          <button class="btn btn-danger btn-sm text-nowrap col-10" (click)="submit()" 
            [title]="submitProblem()"
            [disabled]="!!submitProblem()">
            Add
          </button>
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="working" class="d-flex justify-content-center my-5">
    <uiowa-ring></uiowa-ring>
  </section>
</div>