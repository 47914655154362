import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SortDirection, SortPropDir, TableColumn } from '@siemens/ngx-datatable';
import { of } from 'rxjs';
import { ConfigService, Util } from 'src/app/core';
import { DatasetCommand } from 'src/app/core/models/dataset';
import { Vanpool } from 'src/app/core/models/vanpool';
import { VanpoolRate } from 'src/app/core/models/vanpool-rate';
import { VanpoolService } from 'src/app/core/services/vanpool.service';
import { ModalService } from 'src/app/shared';
import { DatatableComponent, FilterChoices } from 'src/app/shared/components/datatable/datatable.component';

@Component({
  selector: 'rate-history',
  templateUrl: './rate-history.component.html',
  styleUrls: ['./rate-history.component.css']
})
export class RateHistoryComponent implements OnInit {
  @ViewChild('datatable') datatable: DatatableComponent;
  @ViewChild('subriderCell', { static: true }) subriderCell: TemplateRef<any>;

  columns: TableColumn[];
  sorts: SortPropDir[] = [{prop: 'start_date',dir: SortDirection.desc}];
  filter: { [key: string]: string } = {};
  filterChoices: FilterChoices;
  defaultRow = new VanpoolRate();

  vpid: number;
  vanpool: Vanpool;

  constructor(
    private activatedRoute: ActivatedRoute,
    private vanpoolSvc: VanpoolService,
    private modal: ModalService
  ) { }

  ngOnInit(): void {
    const em = Util.getEM();
    this.columns = [
      { name: "Rate Amount", prop: "rate", headerTemplate: "textSearch", pipe: new CurrencyPipe('en-US')},
      { name: "Rate Type", prop: "srdr", headerTemplate: "selectSearch", cellTemplate: this.subriderCell },
      { name: "Start Date", prop: "start_date", width: 10 * em, headerTemplate: "dateSearch", cellTemplate: "dateColumn" },
      { name: "End Date", prop: "end_date", width: 10 * em, headerTemplate: "dateSearch", cellTemplate: "dateColumn" },
      { name: "Rate Change Reason", prop: "change_reason", headerTemplate: "selectSearch" },
    ];
    this.filterChoices = {
      'srdr': [['true',"Sub-rider"],['false',"Member"]]
    }

    this.activatedRoute.params.subscribe(p => setTimeout(() => {
      this.vpid = parseInt(p["id"]);
      if (this.vpid == undefined)
        return;
      this.datatable.resetTable();
      this.vanpoolSvc.getVanpoolDetails(this.vpid).subscribe(
        vp => {
          this.vanpool = { ...vp };
        },
        e => this.modal.error(e).subscribe()
      );
    }));
  }

  getAllData = () => this.vpid?this.vanpoolSvc.getRateHistory(this.vpid):of([]);

  onSelect(event: { row: VanpoolRate, type: string }) {
    if (event.type != "click")
      return;
  }


}
