<div class="d-flex flex-column h-100">
  <header>
    <div class="d-flex justify-content-between align-items-end">
      <h2 class="my-1 text-primary">
        <a routerLink="/admin/" title="admin home">
          <i class="fa fa-home fa-lg"></i>
          <i class="fa fa-angle-right fa-lg"></i>
        </a>
        Rates History for {{vanpool?vanpool.vpname:"Vanpool"}}
      </h2>
    </div>
    <hr class="mt-0" />
  </header>
  <section class="d-flex flex-column flex-fill">
    <shared-datatable #datatable 
      [columns]="columns" 
      [serverSide]="false"
      [getAllData]="getAllData" 
      [defaultRow]="defaultRow"
      [sorts]="sorts" 
      [filter]="filter" 
      [filterChoices]="filterChoices"
      [computedChoices]="['change_reason']"
      (activate)="onSelect($event)" 
      class="bootstrap"
      style="height:100%">
    </shared-datatable>
  </section>
</div>

<ng-template #subriderCell let-row="row">
  {{row&&row.srdr?"Sub-rider":"Member"}}
</ng-template>
