import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MembDtlRoutingModule } from './memb-dtl-routing.module';
import { MembDtlComponent } from './memb-dtl/memb-dtl.component';
import { ModalService } from 'src/app/shared';
import { MembersService, ConfigService, Util } from 'src/app/core';
import { FormsModule } from '@angular/forms';
import { SpinnerModule } from '@uiowa/spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PayrollService } from '../../core/services/payroll.service';


@NgModule({
  declarations: [MembDtlComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    SpinnerModule,
    MembDtlRoutingModule
  ],
  providers:[
    ModalService,
    MembersService,
    ConfigService,
    PayrollService,
    Util
  ]
})
export class MembDtlModule { }
