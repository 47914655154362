<div class="h-100 w-100 bg-light" style="overflow-y: auto;">
  <div class="container">
    <section class="my-4 bg-white shadow">
      <div class="bg-success py-2 px-3">
        <p class="h4 text-white m-0">
          <i class="fa fa-hand fa-lg"></i>
          Sub-rider Request Details
        </p>
      </div>
      <div class="p-3" *ngIf="srdrRq">
        <div class="w-100">
          <div class="row pl-2">
            <div class="card-field">
              <span class="card-field-label">Vanpool</span>
              <span class="card-field-value">{{this.srdrRq.vpname}}</span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Work Hours</span>
              <span class="card-field-value">{{this.srdrRq.hours}}</span>
            </div>
            <div class="card-field">
              <span class="card-field-label">University ID</span>
              <span class="card-field-value">{{this.srdrRq.univId}}</span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Name</span>
              <span class="card-field-value"><a routerLink="/admin/people" [queryParams]="{uid:srdrRq.univId}">
                  {{this.srdrRq.last_name}}, {{this.srdrRq.first_name}}</a></span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Status</span>
              <span class="card-field-value">{{this.srdrRq.status}}</span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Change date</span>
              <span class="card-field-value">{{this.dateString(this.srdrRq.change_date)}}</span>
            </div>
            <div class="card-field-long">
              <span class="card-field-label">Comment:</span>
              <input type="text" [(ngModel)]="comment" class="form-control" placeholder="comment" />
            </div>
          </div>
        </div>

        <div class="row text-center my-1">
          <div class="col-4">
            <a class="btn btn-success btn-sm text-nowrap col-10" (click)="approve()"
              *ngIf="srdrRq.status == 'Initialized'">
              Approve
            </a>
          </div>
          <div class="col-4">
            <a class="btn btn-danger btn-sm text-nowrap col-10" (click)="reject()"
              *ngIf="srdrRq.status == 'Initialized'">
              Reject
            </a>
          </div>
          <div class="col-4">
            <button type="button" class="btn btn-warning btn-sm text-nowrap col-10" (click)="saveComment()"
              *ngIf="comment != srdrRq.comment">
              Save
            </button>
          </div>
        </div>
      </div>
    </section>
    <section *ngIf="loading" class="d-flex justify-content-center my-5">
      <uiowa-ring></uiowa-ring>
    </section>
  </div>
</div>

<ng-template #approveDialog>
  <label for="start_date" class="col-6">Start date:</label>
  <input name="start_date" type="text" ngbDatepicker #start_date="ngbDatepicker"
    [(ngModel)]="dlg.start_date" class="col-6" (click)="start_date.toggle()"
    [minDate]="dlg.min_date">
</ng-template>

<ng-template #rejectDialog>
  <label for="comment">Comment:</label>
  <input name="comment" type="text" placeholder="Comment is required" [(ngModel)]="dlg.comment">
</ng-template>
