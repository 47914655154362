import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApplicationConfig } from '../models/application-config';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private static _config: ApplicationConfig;
  get current(): ApplicationConfig {
    return { ...ConfigService._config };
  }
  public init(): Promise<ApplicationConfig> {
    return this.httpClient
      .get<ApplicationConfig>('api/config')
      .toPromise()
      .then((c) => {
        ConfigService._config = c;
        return { ...ConfigService._config };
      })
      .catch(() => {
        ConfigService._config = null;
        return { ...ConfigService._config };
      });
  }
  constructor(private readonly httpClient: HttpClient) {}
}
