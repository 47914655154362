<div class="d-flex flex-column h-100">
  <header>
    <div class="d-flex justify-content-between align-items-end">
      <h2 class="my-1 text-primary">
        <a routerLink="/admin/" title="admin home">
          <i class="fa fa-home fa-lg"></i>
          <i class="fa fa-angle-right fa-lg"></i>
        </a>
        Mileage Log
      </h2>
    </div>
    <hr class="mt-0" />
  </header>
  <section class="d-flex flex-column flex-fill">
    <div class="row text-center my-1">
      <div class="col-4">
        <button class="btn btn-warning btn-sm text-nowrap col-10" (click)="addMileage()">
          Add mileage
        </button>
      </div>
    </div>
    <hr class="mt-1 mb-0 w-100">
    <div *ngIf="working>1" class="d-flex justify-content-center my-5">
      <uiowa-ring></uiowa-ring>
    </div>
    <shared-datatable #datatable
      [columns]="columns" 
      [getData]="getData" 
      [defaultRow]="defaultRow" 
      [sorts]="sorts"
      [filter]="filter" 
      [selectionType]="null"
      (activate)="onSelect($event)"
      class="bootstrap"
      style="height:100%">
    </shared-datatable>
  </section>
</div>

<ng-template #monthColumn let-value="value">
  {{ formatMonth(value) }}
</ng-template>

<ng-template #addMileageDialog>
  <div>Add mileage for the month of {{dlg.month}}, {{dlg.year}}</div>

  <label for="new_payment_type">Vanpool:</label>
  <select name="new_payment_type" (change)="onCandidateSelect($event.target['value'])">
    <option *ngFor="let op of dlg.candidates">
      {{op.vpname}}
    </option>
  </select>
  <br>
  Equipment #: {{this.dlg.mileage.eqno}}
  <br>
  <label for="bgn_mlg">Beginning Mileage:</label>
  <input name="bgn_mlg" type="number" [(ngModel)]="dlg.mileage.begin_mlg">
  <br>
  <label for="end_mlg">End Mileage:</label>
  <input name="end_mlg" type="number" [(ngModel)]="dlg.mileage.end_mlg">
  <br>
  <label for="lnr_mlg">Loaner Mileage:</label>
  <input name="lnr_mlg" type="number" [(ngModel)]="dlg.mileage.lnr_mlg">
  <br>
  <label for="comment">Comment:</label>
  <input name="comment" type="text" [(ngModel)]="dlg.mileage.comment">
</ng-template>

<ng-template #updateMileageDialog>
  <div>Update mileage log of vanpool {{dlg.mileage.vpname}} (Equipment # {{this.dlg.mileage.eqno}}) for the month of {{dlg.month}}, {{dlg.year}}</div>
  <label for="bgn_mlg">Beginning Mileage:</label>
  <input name="bgn_mlg" type="number" [(ngModel)]="dlg.mileage.begin_mlg">
  <br>
  <label for="end_mlg">End Mileage:</label>
  <input name="end_mlg" type="number" [(ngModel)]="dlg.mileage.end_mlg">
  <br>
  <label for="lnr_mlg">Loaner Mileage:</label>
  <input name="lnr_mlg" type="number" [(ngModel)]="dlg.mileage.lnr_mlg">
  <br>
  <label for="comment">Comment:</label>
  <input name="comment" type="text" [(ngModel)]="dlg.mileage.comment">
</ng-template>