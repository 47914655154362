import { Component, OnInit, ViewChild } from '@angular/core';
import { TableColumn, SortPropDir } from '@siemens/ngx-datatable';
import { DatatableComponent } from 'src/app/shared/components/datatable/datatable.component';
import { Router } from '@angular/router';
import { WaitlistService, ConfigService, Util } from 'src/app/core';
import { DatasetCommand } from 'src/app/core/models/dataset';
import { SubriderRecord } from 'src/app/core/models/subrider-record';

@Component({
  selector: 'admin-subriders',
  templateUrl: './subriders.component.html',
  styleUrls: ['./subriders.component.css']
})
export class SubridersComponent implements OnInit {
  @ViewChild('datatable') datatable: DatatableComponent;

  columns: TableColumn[];
  sorts: SortPropDir[] = [];
  filter: { [key: string]: string } = {};
  filterChoices: { [key: string]: string[] };
  defaultRow = new SubriderRecord();

  constructor(
    private router: Router,
    private wlistSvc: WaitlistService,
    private cfgSvc: ConfigService
  ) { }

  ngOnInit(): void {
    const em = Util.getEM();
    this.columns = [  // $$Implements SW.ADM.WLIST.PG_ALL
      { name: "Vanpool", prop: "vpname", headerTemplate: "textSearch", width: 12*em },
      { name: "Work Hours", prop: "hours", headerTemplate: "selectSearch" },
      { name: "Univ ID", prop: "univId", width: 5 * em, headerTemplate: "textSearch" },
      { name: "First Name", prop: "first_name", canAutoResize: true, headerTemplate: "textSearch" },
      { name: "Last Name", prop: "last_name", headerTemplate: "textSearch" },
      { name: "Status", prop: "status", headerTemplate: "selectSearch", width: 5 * em },
      { name: "Pos.", prop: "position", sortable:false, width: 3 * em},
      { name: "Start Date", prop: "start_date", width: 10 * em, headerTemplate: "dateSearch", cellTemplate: "dateColumn" },
      { name: "End Date", prop: "end_date", width: 10 * em, headerTemplate: "dateSearch", cellTemplate: "dateColumn" },
      { name: "Comment", prop: "comment", width: 22 * em, headerTemplate: "textSearch" },
    ];
    this.filterChoices = {
      'hours': [...this.cfgSvc.current.work_hours],
      'status': ["Active", "Inactive"]
    }
  }

  getData = (cmd: DatasetCommand) => this.wlistSvc.getSubriders(cmd);

  onSelect(event: { row: SubriderRecord, type: string }) {
    if (event.type != "click")
      return;
    this.router.navigate(['admin', 'srd-dtl', event.row.id]);
  }
}
