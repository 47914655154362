<div class="container">
  <section class="my-4 bg-white shadow">
    <div class="bg-success py-2 px-3">
      <p class="h4 text-white m-0">
        <i class="fa fa-hand fa-lg"></i>
        Add Vehicle
      </p>
    </div>
    <div class="p-3">
      <div class="w-100">
        <div class="row pl-2">
          <div class="card-field">
            <span class="card-field-label">Equipment #</span>
            <span class="card-field-value">
              <input type="text" [(ngModel)]="vehicle.eqno" class="form-control"
                placeholder="Please enter unique equipment number" />
            </span>
          </div>
          <div class="card-field">
            <span class="card-field-label">License #:</span>
            <span class="card-field-value">
              <input type="text" [(ngModel)]="vehicle.license_plate" class="form-control"
                placeholder="Please enter unique license number" />
            </span>
          </div>
          <div class="card-field">
            <span class="card-field-label">Type</span>
            <span class="card-field-value">
              <select [(ngModel)]="vehicle.type">
                <option *ngFor="let c of vhclTypes">
                  {{c}}
                </option>
              </select>
            </span>
          </div>
          <div class="card-field">
            <span class="card-field-label">color</span>
            <span class="card-field-value">
              <input type="text" [(ngModel)]="vehicle.color" class="form-control" placeholder="Please enter color" />
            </span>
          </div>
          <div class="card-field">
            <span class="card-field-label">Make</span>
            <span class="card-field-value">
              <input type="text" [(ngModel)]="vehicle.make" class="form-control" placeholder="Please enter make" />
            </span>
          </div>
          <div class="card-field">
            <span class="card-field-label">Model</span>
            <span class="card-field-value">
              <input type="text" [(ngModel)]="vehicle.model" class="form-control" placeholder="Please enter model" />
            </span>
          </div>
          <div class="card-field">
            <span class="card-field-label">Year</span>
            <span class="card-field-value">
              <input type="number" min="1" [(ngModel)]="vehicle.year">
            </span>
          </div>
          <div class="card-field">
            <span class="card-field-label">Capacity</span>
            <span class="card-field-value">
              <input type="number" min="1" [(ngModel)]="vehicle.capacity">
            </span>
          </div>

          <div class="card-field-long">
            <span class="card-field-label">Comment:</span>
            <input type="text" [(ngModel)]="vehicle.comment" class="form-control" placeholder="comment" />
          </div>
        </div>
      </div>

      <div class="row text-center my-1">
        <div class="col-4">
          <button class="btn btn-danger btn-sm text-nowrap col-10" (click)="submit()" [title]="submitProblem()"
            [disabled]="!!submitProblem()">
            Add
          </button>
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="working" class="d-flex justify-content-center my-5">
    <uiowa-ring></uiowa-ring>
  </section>
</div>