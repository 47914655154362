import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, empty, from, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { MemberRecord } from '../models/member-record';
import { PendingDriverRequest } from '../models/pending-driver-request';
import { DatasetCommand, Dataset } from '../models/dataset';
import { Util } from './util';

@Injectable({ providedIn: 'root' })
export class MembersService {
  constructor(private http: HttpClient) { }

  static readonly memberRecordDates: string[] = Util.DatePropertiesKeys(new MemberRecord());

  getAll(cmd: DatasetCommand): Observable<Dataset<MemberRecord>> {
    let params = new HttpParams();
    Object.keys(cmd).forEach(k => {
      if (Array.isArray(cmd[k]))
        cmd[k].forEach(e => {
          params = params.append(k, e)
        });
      else
        params = params.append(k, cmd[k])
    });
    return this.http.get<Dataset<MemberRecord>>("api/memb/all", { params }).pipe(
      map(ds => {
        ds.data.forEach(d => Util.FixDates(d, MembersService.memberRecordDates));
        return ds;
      })
    );
  }

  getMemberDetails(id: number): Observable<MemberRecord> {
    return this.http.get<MemberRecord>(`api/memb/${id}`).pipe(
      map(r => Util.FixDates(r, MembersService.memberRecordDates))
    )
  }

  provideDriverForm(id: number): Observable<boolean> {
    return this.http.post<any>(`api/memb/dform/${id}`,null)
      .pipe(switchMap(res => of(true)));
  }

  provideBackupDriverForm(id: number): Observable<boolean> {
    return this.http.post<any>(`api/memb/bform/${id}`, null)
      .pipe(switchMap(res => of(true)));
  }

  saveComment(id: number, comment: string): Observable<boolean> {
    let formData = new FormData();
    formData.append('id', id.toString());
    formData.append('comment', comment);
    return this.http.post<any>('api/memb/cmnt', formData)
      .pipe(switchMap(res => of(true)));
  }

  getPendingDirvers(): Observable<PendingDriverRequest[]> {
    return this.http.get<PendingDriverRequest[]>('api/adm/pdrv');
  }

  changeType(id: number, type: string, pmnt: string, date: Date, cmnt: string):Observable<number> {
    let formData = new FormData();
    formData.append('id', id.toString());
    formData.append('type',type);
    formData.append('payment',pmnt);
    formData.append('date', Util.toISODateString(date));
    formData.append('comment', cmnt);
    return this.http.post<number>('api/memb/type',formData);
  }

  swapDrivers(id: number, date: Date, cmnt: string):Observable<number> {
    let formData = new FormData();
    formData.append('id', id.toString());
    formData.append('date', Util.toISODateString(date));
    formData.append('comment', cmnt);
    return this.http.post<number>('api/memb/swap',formData);
  }

  changePmntType(id: number, type: string, date: Date, cmnt: string): Observable<number> {
    let formData = new FormData();
    formData.append('id', id.toString());
    formData.append('type', type);
    formData.append('date', Util.toISODateString(date));
    formData.append('comment', cmnt);
    return this.http.post<number>('api/memb/pmnt', formData);
  }

  endMembership(id: number, date: Date, reason: string, comment: string): Observable<boolean> {
    let formData = new FormData();
    formData.append('id', id.toString());
    formData.append('date', Util.toISODateString(date));
    formData.append('reason', reason);
    formData.append('comment', comment);
    return this.http.post<any>('api/memb/end', formData)
      .pipe(switchMap(() => of(true)));
  }

  changeFutureDate(id:number, date:Date): Observable<boolean> {
    return this.http.post<any>('api/memb/future', Util.CommandToFormData({id,date}))
      .pipe(switchMap(() => of(true)));
  }

  removeEndDate(id:number): Observable<boolean> {
    return this.http.post<any>('api/memb/rmend', Util.CommandToFormData({id}))
      .pipe(switchMap(() => of(true)));
  }

}