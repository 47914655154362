import { Component, OnInit, ViewChild } from '@angular/core';
import { TableColumn, SortPropDir, SortDirection } from '@siemens/ngx-datatable';
import { DatasetCommand } from 'src/app/core/models/dataset';
import { WaitlistService, ConfigService, Util } from 'src/app/core';
import { WlRequest } from 'src/app/core/models/wl-request';
import { ActivatedRoute, Router } from '@angular/router';
import { DatatableComponent } from 'src/app/shared/components/datatable/datatable.component';

@Component({
  selector: 'admin-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit {
  @ViewChild('datatable') datatable: DatatableComponent;

  columns: TableColumn[];
  sorts: SortPropDir[] = [];
  filter: { [key: string]: string } = {};
  filterChoices:{[key:string]:string[]};
  defaultRow = new WlRequest();

  constructor(
    private wlistSvc: WaitlistService,
    private cfgSvc: ConfigService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    if (this.activatedRoute.snapshot.queryParamMap.get('new')) {
      this.filter={
        date: `>=${Util.dateString(Util.addDays(-7))}`,
        status: '=Active Waiting'
      };
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('recent_offers')) {
      this.filter = {
        offer_date: `>=${Util.dateString(Util.addDays(-30))}`
      };
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('recent_pending')) {
      this.filter = {
        offer_date: `>=${Util.dateString(Util.addDays(-30))}`,
        status:'=Offer Pending'
      };
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('recent_assigned')) {
      this.filter = {
        offer_date: `>=${Util.dateString(Util.addDays(-30))}`,
        status:'=Assigned to Vanpool'
      };
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('recent_declined')) {
      this.filter = {
        offer_date: `>=${Util.dateString(Util.addDays(-30))}`,
        status:'=Declined Offer'
      };
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('recent_expired')) {
      this.filter = {
        offer_date: `>=${Util.dateString(Util.addDays(-30))}`,
        status:'=Declined - No response'
      };
    }
    let vpool = this.activatedRoute.snapshot.queryParamMap.get('active_in')
    if (vpool) {
      this.filter = {
        vpname: `=${vpool}`,
        status: '=Active Waiting'
      };
      this.sorts = [{
        prop:'date',
        dir: SortDirection.asc
      }]
    }

    vpool = this.activatedRoute.snapshot.queryParamMap.get('offers_in')
    if (vpool) {
      this.filter = {
        vpname: `=${vpool}`,
        status: '=Offer Pending'
      };
      this.sorts = [{
        prop: 'date',
        dir: SortDirection.asc
      }]
    }

    vpool = this.activatedRoute.snapshot.queryParamMap.get('all_in')
    if (vpool) {
      this.filter = {
        vpname: `=${vpool}`
      };
      this.sorts = [{
        prop: 'status',
        dir: SortDirection.asc
      }]
    }

    if (this.activatedRoute.snapshot.queryParams.filter) {
      let filters = this.activatedRoute.snapshot.queryParams.filter;
      if (!Array.isArray(filters))
        filters = [filters];
      for (let i = 0; i < filters.length; i++) {
        let term = filters[i].split('~');
        this.filter[term[0]] = term[1];
      }
    }

  }

  ngOnInit(): void {
    const em = Util.getEM();
    this.columns = [  // $$Implements SW.ADM.WLIST.PG_ALL
      { name: "Vanpool", prop: "vpname", headerTemplate: "textSearch", width: 12*em },
      { name: "Work Hours", prop: "hours", headerTemplate: "selectSearch" },
      { name: "Univ ID", prop: "univId", width: 5 *em, headerTemplate: "textSearch" },
      { name: "First Name", prop: "first_name", canAutoResize:true, headerTemplate: "textSearch" },
      { name: "Last Name", prop: "last_name", headerTemplate: "textSearch" },
      { name: "Status", prop: "status", headerTemplate: "selectSearch" },
      { name: "Pos.", prop: "position", width:3*em, headerTemplate: "textSearch"},
      { name: "Request Date", prop: "date", width: 10*em, headerTemplate: "dateSearch", cellTemplate: "dateColumn" },
      { name: "Offer Date", prop: "offer_date", width: 10 * em, headerTemplate: "dateSearch", cellTemplate: "dateColumn" },
      { name: "Removed Date", prop: "removed_date", width: 10 * em, headerTemplate: "dateSearch", cellTemplate: "dateColumn" },
      { name: "Decline reason", prop: "decl_rsn", width: 10 * em, headerTemplate: "selectSearch"},
      { name: "All Waiting Lists", prop: "requests", width: 7 * em, headerTemplate: "textSearch" },
      { name: "Current Vanpool", prop: "current_vpool", width: 8 * em, headerTemplate: "textSearch" },
      { name: "Is Driver", prop: "isdriver", width: 5 * em, headerTemplate: "boolSearch", cellTemplate: "boolColumn" },
      { name: "Sub-rider", prop: "subrider", width: 5 * em, headerTemplate: "boolSearch", cellTemplate: "boolColumn" },
      { name: "Comment", prop: "comment", width: 22 * em, headerTemplate: "textSearch" },
    ];
    this.filterChoices={
      'hours': [...this.cfgSvc.current.work_hours],
      'status':[...this.cfgSvc.current.wlist_statuses],
      'decl_rsn': ['No data',...this.cfgSvc.current.rq_dcln_rsns]
    }
  }

  lastCommand:DatasetCommand;
  getData = (cmd:DatasetCommand) => {
    this.lastCommand = {...cmd};
    return this.wlistSvc.getAll(cmd);
  }

  onSelect(event: { row: WlRequest, type: string }) {
    if (event.type != "click")
      return;
    this.router.navigate(['admin','wl-rq-dtl',event.row.id]);
  }

  downloadCsv() {
    let cmd = {...this.lastCommand};
    this.wlistSvc.downloadFile(cmd)
  }

}
