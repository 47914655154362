<div class="d-flex flex-column h-100">
  <header>
    <div class="d-flex justify-content-between align-items-end">
      <h2 class="my-1 text-primary">
        <a routerLink="/admin/" title="admin home">
          <i class="fa fa-home fa-lg"></i>
          <i class="fa fa-angle-right fa-lg"></i>
        </a>
        Vehicles
      </h2>
    </div>
    <hr class="mt-0" />
  </header>
  <section class="d-flex flex-column flex-fill">
    <div class="row text-center my-1">
      <div class="col-4">
        <a class="btn btn-success btn-sm text-nowrap col-10" routerLink="/admin/vehicle-add">
          Add Vehicle
        </a>
      </div>
      <div class="col-4">
        <button type="button" class="btn btn-warning btn-sm text-nowrap col-10" title="Vehicles"
          (click)="downloadCsv()">
          Export to CSV
        </button>
      </div>
      <div class="col-4">
        <a type="button" class="btn btn-danger btn-sm text-nowrap col-10" title="vehicle history"
          routerLink="/admin/vehicle-history">
          History
        </a>
      </div>
    </div>
      <hr class="mt-1 mb-0 w-100">

    <shared-datatable #datatable [columns]="columns" [getData]="getData" [defaultRow]="defaultRow" [sorts]="sorts"
      [filter]="filter" [filterChoices]="filterChoices" (activate)="onSelect($event)" class="bootstrap"
      style="height:100%">
    </shared-datatable>
  </section>
</div>