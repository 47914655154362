<div class="d-flex flex-column h-100">
    <header>
        <div>
            <h2 class="my-1 text-primary">
                <a routerLink="/admin/" title="admin home">
                    <i class="fa fa-home fa-lg"></i>
                    <i class="fa fa-angle-right fa-lg"></i>
                </a>
                Payroll Deduct Preview
            </h2>
        </div>
        <hr class="mt-0" />
    </header>
    <div class="details text-primary">
            <p>
                Cutoff Date : {{cutOffdate| date}} 5:00 PM
            </p>
            <p>
                Automated Job Runs at 7:00 AM
            </p>
    </div>
  <section class="d-flex flex-column flex-fill">
    <div class="row text-center my-1">
      <div class="col-4">
        <button type="button" class="btn btn-warning btn-sm text-nowrap col-10" title="Payroll Deduct"
          (click)="downloadCsv()">
          Export to CSV
        </button>
      </div>
      <div class="col-4" *ngIf="canRunJob">
        <a class="btn btn-danger btn-sm text-nowrap col-10" routerLink="/admin/payroll-deduct-run">
          Run Job
        </a>
      </div>
    </div>
    <shared-datatable #datatable [serverSide]="false" [columns]="columns" [getAllData]="getAllData"
      [defaultRow]="defaultRow" [sorts]="sorts" [filter]="filter" [filterChoices]="filterChoices" class="bootstrap"
      style="height:100%">
    </shared-datatable>
  </section>
</div>