import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, empty, from, of } from 'rxjs';

import { AvailableWlist } from 'src/app/core/models/available-wlist';
import { DatasetCommand, Dataset } from '../models/dataset';
import { WlRequest } from '../models/wl-request';
import { WlStatus } from '../models/wl-status';
import { map, switchMap } from 'rxjs/operators';
import { Util } from './util';
import { SubriderRequest } from '../models/subrider-request';
import { SubriderRecord } from '../models/subrider-record';


@Injectable({providedIn: 'root'})
export class WaitlistService {
    constructor(private http: HttpClient) { }

    static readonly wlRqDates: string[] = Util.DatePropertiesKeys(new WlRequest());

    getAll(cmd: DatasetCommand): Observable<Dataset<WlRequest>> {
        let params = Util.CommandToHttpParams(cmd);
        return this.http.get<Dataset<WlRequest>>("api/wlist/all",{params}).pipe(
            map(ds=>{
                ds.data.forEach(d => Util.FixDates(d, WaitlistService.wlRqDates));
                return ds;
            })
        );
    }

    // $$Implements SW.ADM.WLIST.PG_ALL_CSV
    downloadFile(cmd: DatasetCommand) {
        let params = Util.CommandToHttpParams(cmd);
        this.http.get("api/wlist/csv", { params, responseType: 'blob' })
        .subscribe((result: any) => {
            if (result.type != 'text/plain') {
                var blob = new Blob([result]);
                let saveAs = require('file-saver');
                let file = 'waitlist.csv';
                saveAs(blob, file);
            } else {
                alert('Error downloading data');
            }
        });

    }

    getHistory(): Observable<WlRequest[]> {
        return this.http.get<WlRequest[]>(`api/wlist/hist`).pipe(
            map(ra => { ra.forEach(r => Util.FixDates(r, WaitlistService.wlRqDates)); return ra })
        );
    }

    getStatusHistory(rqid:number): Observable<WlStatus[]> {
        return this.http.get<WlStatus[]>(`api/wlist/hist/${rqid}`).pipe(
            map(sa => { sa.forEach(s => s.modified_date = s.modified_date&&(new Date(s.modified_date))); return sa })
        );
    }

    getActive(): Observable< WlRequest[] > {
        return this.http.get<WlRequest[]>(`api/wlist/act`).pipe(
            map(ra => { ra.forEach(r => Util.FixDates(r, WaitlistService.wlRqDates)); return ra})
        );
    }

    getOffers(): Observable< WlRequest[] > {
        return this.http.get<WlRequest[]>(`api/wlist/offers`).pipe(
            map(ra => { ra.forEach(r => Util.FixDates(r, WaitlistService.wlRqDates)); return ra })
        );
    }

    getOfferDetails(rqid: number): Observable< WlRequest > {
        return this.http.get<WlRequest>(`api/wlist/offer/${rqid}`).pipe(
            map(r => Util.FixDates(r, WaitlistService.wlRqDates))
        );
    }

    acceptOffer(rqid: number, date: Date):Observable<boolean> {
        let formData = new FormData();
        formData.append('rqid', rqid.toString());
        formData.append('date', Util.toISODateString(date));
        return this.http.post<any>('api/wlist/offer/acpt', formData)
            .pipe(switchMap(res => of(true)));
    }

    declineOffer(rqid: number, reason:string, expln:string): Observable<boolean> {
        let formData = new FormData();
        formData.append('reason', reason);
        formData.append('expln', expln);
        return this.http.post<any>(`api/wlist/offer/${rqid}/decl`, formData)
            .pipe(switchMap(res => of(true)));
    }

    getRequestDetails(rqid: number): Observable<WlRequest> {
        return this.http.get<WlRequest>(`api/wlist/rq/${rqid}`).pipe(
            map(r => Util.FixDates(r, WaitlistService.wlRqDates))
        )
    }

    saveComment(rqid: number, comment: string):Observable<boolean> {
        let formData = new FormData();
        formData.append('rqid',rqid.toString());
        formData.append('comment',comment);
        return this.http.post<any>('api/wlist/rq/cmnt',formData)
            .pipe(switchMap(res=>of(true)));
    }

    submitOffer(rqid: number, available: Date, expires: Date):Observable<boolean> {
        let formData = new FormData();
        formData.append('rqid',rqid.toString());
        formData.append('available', Util.toISODateString(available));
        formData.append('expires', Util.toISODateString(expires));
        return this.http.post<boolean>('api/wlist/offer',formData)
            .pipe(switchMap(res => of(true)));
    }

    removeOffer(rqid: number, reason: number): Observable<boolean>{
        let params = new HttpParams();
        params = params.append('reason',reason.toString());
        return this.http.delete<any>(`api/wlist/rq/${rqid}`,{params})
        .pipe(switchMap(res => of(true)));
    }

    extendOffer(rqid: number, newDate: Date): Observable<any> {
        let params = new HttpParams()
            .append('rqid',rqid.toString())
            .append('expires',Util.toISODateString(newDate));
        return this.http.post<any>('api/wlist/offer/xtnd',null,{params})
            .pipe(switchMap(res => of(true)));
    }

    changeAvailableDate(rqid: number, newDate: Date): Observable<any> {
        let params = new HttpParams()
            .append('rqid', rqid.toString())
            .append('expires', Util.toISODateString(newDate));
        return this.http.post<any>('api/wlist/offer/sdate', null, { params })
            .pipe(switchMap(res => of(true)));
    }


    getAvailable(): Observable<AvailableWlist[]> {
        return this.http.get<AvailableWlist[]>("api/wlist/avail/");
    }

    join(vpoolIds: number[], date: Date): Observable<boolean> {
        let formData = Util.CommandToFormData({ vpoolIds, date:Util.toISODateString(date) });
        return this.http.post<boolean>("api/wlist/join", formData);
    }

    getPotentialOffers():Observable<{name:string,count:number}[]> {
        return this.http.get<{ name: string, count: number }[]>("api/adm/poffers");
    }

    requestSeat(vpoolId:number):Observable<boolean> {
        return this.http.post<any>(`api/wlist/srider/${vpoolId}`, null)
            .pipe(switchMap(() => of(true)));
    }

    removeRequests(rqids: number[]): Observable<boolean> {
        return this.http.post<any>(`api/wlist/remove`, rqids)
            .pipe(switchMap(() => of(true)));
    }

    static readonly SrRqDates: string[] = Util.DatePropertiesKeys(new SubriderRequest());
    getSubriderRequests(cmd: DatasetCommand): Observable<Dataset<SubriderRequest>> {
        let params = Util.CommandToHttpParams(cmd);
        return this.http.get<Dataset<SubriderRequest>>("api/wlist/srdrq", { params }).pipe(
            map(ds => {
                ds.data.forEach(d => Util.FixDates(d, WaitlistService.SrRqDates));
                return ds;
            })
        );
    }

    getSubriderRequest(rqid: number): Observable<SubriderRequest> {
        return this.http.get<SubriderRequest>(`api/wlist/srdrq/${rqid}`).pipe(
            map(r => Util.FixDates(r, WaitlistService.SrRqDates))
        )
    }

    approveSubriderRequest(rqid: number, startDate: Date): Observable<boolean> {
        let params = Util.CommandToHttpParams({rqid, startDate:Util.toISODateString(startDate)});
        return this.http.post<any>(`api/wlist/srdrq/aprv`,null,{params})
            .pipe(switchMap(() => of(true)));
    } 

    rejectSubriderRequest(rqid: number, comment: String): Observable<boolean> {
        let formData = Util.CommandToFormData({rqid, comment});
        return this.http.post<any>(`api/wlist/srdrq/rjct`,formData)
            .pipe(switchMap(() => of(true)));
    } 

    saveSubriderRequestComment(rqid: number, comment: String): Observable<boolean> {
        let formData = Util.CommandToFormData({ rqid, comment });
        return this.http.post<any>(`api/wlist/srdrq/cmnt`, formData)
            .pipe(switchMap(() => of(true)));
    } 

    static readonly SubriderDates: string[] = Util.DatePropertiesKeys(new SubriderRecord());
    getSubriders(cmd: DatasetCommand): Observable<Dataset<SubriderRecord>> {
        let params = Util.CommandToHttpParams(cmd);
        return this.http.get<Dataset<SubriderRecord>>("api/wlist/srd", { params }).pipe(
            map(ds => {
                ds.data.forEach(d => Util.FixDates(d, WaitlistService.SubriderDates));
                return ds;
            })
        );
    }

    getSubriderRecord(id: number): Observable<SubriderRecord> {
        return this.http.get<SubriderRecord>(`api/wlist/srd/${id}`).pipe(
            map(r => Util.FixDates(r, WaitlistService.SubriderDates))
        )
    }

    saveSubriderRecordComment(id: number, comment: String): Observable<boolean> {
        let formData = Util.CommandToFormData({ id, comment });
        return this.http.post<any>(`api/wlist/srd/cmnt`, formData)
            .pipe(switchMap(() => of(true)));
    }

    endSubriderRecord(id: number, endDate: Date): Observable<boolean> {
        let params = Util.CommandToHttpParams({ id, endDate: Util.toISODateString(endDate) });
        return this.http.post<any>(`api/wlist/srd/end`, null, { params })
            .pipe(switchMap(() => of(true)));
    }

}