import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { PayrollDeduction } from '../models/payroll-deduction';
import { Util } from './util';
import { map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PayrollService {
  constructor(private http: HttpClient) {}

  getCutOffDate(): Observable<Date> {
    return this.http.get<Date>('api/payroll/getCutOffDate');
  }

  canRunJob(): Observable<boolean> {
    return this.http.get<boolean>('api/payroll/canRunPayrollJob');
  }
  getCutOffDates(year): Observable<Date[]> {
    return this.http.get<Date[]>('api/payroll/getCutOffDates/' + year);
  }

    // $$Implements SW.ADM.MEMB.PG_DTL_END
  getLastDate(): Observable<Date> {
    try {
      return this.http.get<Date>('api/payroll/getLastDate')
      .pipe(switchMap(d=>of(new Date(d))));
    } catch (e) {
      return of(null);
    }
  }

  RunPayrollDeduct(year, month): Observable<any> {
    return this.http.post<any>('api/payroll/RunPayrollDeductReport', { year: year, month: month });
  }
  static readonly PayrollDeductionDates = Util.DatePropertiesKeys(new PayrollDeduction());
  getPreview(): Observable<PayrollDeduction[]> {
    return this.http.get<PayrollDeduction[]>('api/payroll/getPreview').pipe(
      map((data) => {
        data.forEach((d) => Util.FixDates(d, PayrollService.PayrollDeductionDates));
        return data;
      })
    );
  }

  downloadFile() {
    return this.http
      .get('api/payroll/export', {
        responseType: 'blob',
      })
      .subscribe((result: any) => {
        if (result.type != 'text/plain') {
          var blob = new Blob([result]);
          let saveAs = require('file-saver');
          let file = 'PayrollDeduct.csv';
          saveAs(blob, file);
        } else {
          alert('File not found in Blob!');
        }
      });
  }
}
