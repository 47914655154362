import { Injectable } from '@angular/core';
import { DatasetCommand, Dataset } from '../models/dataset';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, empty, from, of } from 'rxjs';
import { flatMap, map, switchMap } from 'rxjs/operators';
import { Vehicle } from '../models/vehicle';
import { VehicleHistory } from '../models/vehicle-history';
import { LoanerHistory } from '../models/loaner-history';
import { Util } from './util';

@Injectable({ providedIn: 'root' })
export class VehicleService {
  constructor(private http: HttpClient) {}

  getAll(cmd: DatasetCommand): Observable<Dataset<Vehicle>> {
    let params = Util.CommandToHttpParams(cmd);
    return this.http.get<Dataset<Vehicle>>('api/vhcl/all', { params });
  }

  downloadFile() {
    return this.http
      .get('api/vhcl/export', {
        responseType: 'blob',
      })
      .subscribe((result: any) => {
        if (result.type != 'text/plain') {
          var blob = new Blob([result]);
          let saveAs = require('file-saver');
          let file = 'vehicles.csv';
          saveAs(blob, file);
        } else {
          alert('File not found in Blob!');
        }
      });
  }

  static readonly vehicleHistories: string[] = Util.DatePropertiesKeys(new VehicleHistory());
  getAllVehicleHistory(cmd: DatasetCommand): Observable<Dataset<VehicleHistory>> {
    let params = Util.CommandToHttpParams(cmd);
    return this.http
      .get<Dataset<VehicleHistory>>('api/vhcl/allVhclHistory', { params })
      .pipe(
        map((ds) => {
          ds.data.forEach((d) => Util.FixDates(d, VehicleService.vehicleHistories));
          return ds;
        })
      );
  }

  static readonly loanerHistories: string[] = Util.DatePropertiesKeys(new LoanerHistory());
  getAllLoanerHistory(cmd: DatasetCommand): Observable<Dataset<LoanerHistory>> {
    let params = Util.CommandToHttpParams(cmd);
    return this.http
      .get<Dataset<LoanerHistory>>('api/vhcl/allLonerHistory', { params })
      .pipe(
        map((ds) => {
          ds.data.forEach((d) => Util.FixDates(d, VehicleService.loanerHistories));
          return ds;
        })
      );
  }

  getActiveLoaners(cmd: DatasetCommand): Observable<Dataset<LoanerHistory>> {
    let params = Util.CommandToHttpParams(cmd);
    return this.http
      .get<Dataset<LoanerHistory>>('api/vhcl/loaners', { params })
      .pipe(
        map((ds) => {
          ds.data.forEach((d) => Util.FixDates(d, VehicleService.loanerHistories));
          return ds;
        })
      );
  }

  updateLoaner(lnr: LoanerHistory): Observable<boolean> {
    return this.http.put<any>('api/vhcl/loaner', lnr)
      .pipe(switchMap(() => of(true)));
  }

  addLoaner(id:number,startDate:Date,comment:string): Observable<boolean> {
    let formData = new FormData();
    formData.append("id", id.toString());
    formData.append("start_date", Util.toISODateString(startDate));
    formData.append("comment", comment);
    return this.http.post<any>('api/vhcl/loaner', formData)
      .pipe(switchMap(() => of(true)));
  }

  getAvailable(): Observable<Vehicle[]> {
    let cmd: DatasetCommand = {
      OrderBy: 'eqno.desc',
      Filter: ['assignement=~Available'],
      MaxNumber: 1000000,
    };
    return this.getAll(cmd).pipe(switchMap((ds) => of(ds.data)));
  }



  get(eqno: string): Observable<Vehicle> {
    let cmd: DatasetCommand = {
      Filter: [`eqno=~${eqno}`],
    };
    return this.getAll(cmd).pipe(switchMap((ds) => of(ds.data[0])));
  }
  add(vhcl: Vehicle): Observable<number> {
    return this.http.post<number>('api/vhcl/add', vhcl);
  }
  update(vhcl: Vehicle): Observable<number> {
    return this.http.post<number>('api/vhcl/updt', vhcl);
  }
  getVehicleDetails(vpid: number): Observable<Vehicle> {
    return this.http.get<Vehicle>(`api/vhcl/${vpid}`).pipe(switchMap((ds) => of(ds)));
  }
}
