import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent, FilterChoices } from 'src/app/shared/components/datatable/datatable.component';
import { TableColumn, SortPropDir, SortDirection } from '@siemens/ngx-datatable';
import { MemberRecord } from 'src/app/core/models/member-record';
import { MembersService, ConfigService, Util } from 'src/app/core';
import { DatasetCommand } from 'src/app/core/models/dataset';
import { Router, ActivatedRoute } from '@angular/router';
import { flatMap, map } from 'rxjs/operators';

@Component({
  selector: 'admin-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit {
  @ViewChild('datatable') datatable: DatatableComponent;

  columns: TableColumn[];
  sorts: SortPropDir[] = [];
  filter: { [key: string]: string } = {};
  filterChoices: FilterChoices;
  defaultRow = new MemberRecord();

  constructor(
    private membSvc: MembersService,
    private cfgSvc: ConfigService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    if (this.activatedRoute.snapshot.queryParams.filter) {
      let filters = this.activatedRoute.snapshot.queryParams.filter;
      if (!Array.isArray(filters))
        filters = [filters];
      for (let i=0;i<filters.length;i++) {
        let term = filters[i].split('~');
        this.filter[term[0]]=term[1];
      }
    }
    if (this.activatedRoute.snapshot.queryParams.sorts) {
      let sorts = this.activatedRoute.snapshot.queryParams.sorts;
      let sort:string = (Array.isArray(sorts))?sorts[0]:sorts;
      this.sorts=[{
        prop: sort.replace(/^~/,""),
        dir: sort.startsWith("~")?SortDirection.desc:SortDirection.asc
      }];
    }
  }

  ngOnInit(): void {
    const em = Util.getEM();
    this.columns = [  // $$Implements SW.ADM.MEMB.PG_ALL
      { name: "Vanpool", prop: "vpname", headerTemplate: "textSearch", width: 12*em },
      { name: "Work Hours", prop: "hours", headerTemplate: "selectSearch" },
      { name: "First Name", prop: "first_name", canAutoResize: true, headerTemplate: "textSearch" },
      { name: "Last Name", prop: "last_name", headerTemplate: "textSearch" },
      { name: "Member Type", prop: "type", headerTemplate: "selectSearch" },
      { name: "Status", prop: "status", headerTemplate: "selectSearch" },
      { name: "Start Date", prop: "start_date", width: 10*em, headerTemplate: "dateSearch", cellTemplate: "dateColumn" },
      { name: "End Date", prop: "end_date", width: 10*em, headerTemplate: "dateSearch", cellTemplate: "dateColumn" },
      { name: "Membership End Reason", prop: "end_reason", headerTemplate: "selectSearch" },
      { name: "Waiting List", prop: "requests", width: 7 * em, headerTemplate: "textSearch" },
      { name: "Payment Type", prop: "payment", headerTemplate: "selectSearch" },
      { name: "Comment", prop: "comment", width: 22 * em, headerTemplate: "textSearch" },
    ];
    this.filterChoices = {
      'hours': [...this.cfgSvc.current.work_hours],
      'type': [...this.cfgSvc.current.memb_types],
      'end_reason': [...this.cfgSvc.current.memb_end_reasons],
      'payment': [...this.cfgSvc.current.pmnt_type],
      'status': ['Active', 'Inactive', ['@AoF','Active+']]
    }
  }

  getData = (cmd: DatasetCommand) => this.membSvc.getAll(cmd)
    .pipe(map(res=>{
      if (this.filter.hasOwnProperty("vpname") && cmd.OrderBy && cmd.OrderBy.startsWith("type")) {
        res.data = res.data.sort((a,b)=>{
          if (a.type == b.type)
            return a.last_name.localeCompare(b.last_name);
          else if (a.type == "Rider")
            return 1;
          else if (b.type == "Rider")
            return -1;
          else
            return a.type.localeCompare(b.type);
        })
      }
      return res;
    }));

  onSelect(event: { row: MemberRecord, type: string }) {
    if (event.type != "click")
      return;
    this.router.navigate(['admin', 'memb-dtl', event.row.id]);
  }

  rowClass(row:MemberRecord) {
    if (!row)
      return '';
    let today = Util.today;
    return {
      'text-danger': row.status == 'Active' && !!row.end_date,
      'text-success': row.start_date > today
    }
  }
}
