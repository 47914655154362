import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PoffersRoutingModule } from './poffers-routing.module';
import { PoffersComponent } from './poffers/poffers.component';
import { WaitlistService } from 'src/app/core';
import { ModalService } from 'src/app/shared';
import { SpinnerModule } from '@uiowa/spinner';


@NgModule({
  declarations: [PoffersComponent],
  imports: [
    CommonModule,
    SpinnerModule,
    PoffersRoutingModule
  ],
  providers:[
    ModalService,
    WaitlistService
  ]
})
export class PoffersModule { }
