import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { SortDirection, SortPropDir, TableColumn } from '@siemens/ngx-datatable';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Util, VehicleService } from 'src/app/core';
import { DatasetCommand } from 'src/app/core/models/dataset';
import { LoanerHistory } from 'src/app/core/models/loaner-history';
import { Vehicle } from 'src/app/core/models/vehicle';
import { ModalService } from 'src/app/shared';
import { DatatableComponent } from 'src/app/shared/components/datatable/datatable.component';

@Component({
  selector: 'admin-loaners',
  templateUrl: './loaners.component.html',
  styleUrls: ['./loaners.component.css']
})
export class LoanersComponent implements OnInit {
  @ViewChild('datatable') datatable: DatatableComponent;
  @ViewChild("addLoanerDialog") addLoanerDialog: TemplateRef<any>;
  @ViewChild("updateLoanerDialog") updateLoanerDialog: TemplateRef<any>;

  columns: TableColumn[];
  sorts: SortPropDir[] = [{ prop: "eqno", dir: SortDirection.asc }];
  filter: { [key: string]: string } = {};
  defaultRow = new LoanerHistory();

  working = 0;

  constructor(
    private vhclSvc: VehicleService,
    private modal: ModalService
  ) { 
  }

  ngOnInit(): void {
    const em = Util.getEM();
    this.columns = [
      { name: 'Equipment #', prop: 'eqno', headerTemplate: 'textSearch' },
      { name: 'Vehicle Type', prop: 'type', headerTemplate: 'textSearch' },
      { name: 'Make', prop: 'make', headerTemplate: 'textSearch' },
      { name: 'Model', prop: 'model', headerTemplate: 'textSearch', width: 6 * em },
      { name: 'Year', prop: 'year', headerTemplate: 'textSearch', width: 8 * em },
      { name: 'Color', prop: 'color', headerTemplate: 'textSearch', width: 8 * em },
      { name: 'Capacity', prop: 'capacity', headerTemplate: 'textSearch', width: 8 * em },
      { name: 'Start Date', prop: 'startDate', width: 10 * em, headerTemplate: 'dateSearch', cellTemplate: 'dateColumn' },
      { name: 'End Date', prop: 'endDate', width: 10 * em, headerTemplate: 'dateSearch', cellTemplate: 'dateColumn' },
      { name: 'Comment', prop: 'comment', width: 22 * em, headerTemplate: 'textSearch' },
    ];
  }

  getData = (cmd: DatasetCommand) => this.vhclSvc.getActiveLoaners(cmd);

  dlg:{
    loaner?:LoanerHistory,
    start_date: NgbDateStruct,
    end_date?: NgbDateStruct,
    vehicleId?: number,
    comment?: string,
  };

  updateButtonHelp(original:LoanerHistory):string {
    this.dlg.loaner.startDate=Util.fromNgbDate(this.dlg.start_date);
    this.dlg.loaner.endDate=Util.fromNgbDate(this.dlg.end_date);
    if (Util.compareProperties(this.dlg.loaner, original))
      return "No changes";
    if (!this.dlg.loaner.startDate)
      return "Start date can not be empty";
    if (!!this.dlg.loaner.endDate && this.dlg.loaner.endDate <= this.dlg.loaner.startDate)
      return "Start date shall be before end date";
    return "";
  }

  vehicles:Vehicle[]=[];
  addLoaner() {
    this.working = 1;
    this.vhclSvc.getAvailable().pipe(
      switchMap(v => {
        this.working=0;
        this.vehicles = [ ...v];
        this.dlg = {
          vehicleId:this.vehicles[0].id,
          start_date: Util.toNgbDate(Util.today),
          comment:""
        };
        return this.modal.show({
          title: "New loaner assignement",
          cancelBtn: "Cancel",
          template: this.addLoanerDialog,
        })
      }),
      switchMap(
        res=>{
          if (!res)
            return of(0);
          return this.vhclSvc.addLoaner(this.dlg.vehicleId,Util.fromNgbDate(this.dlg.start_date),this.dlg.comment)
        }
      )
    ).subscribe(
      res => {
        this.working = 0;
        if (!res)
          return;
        this.datatable.resetTable(true);
      },
      e => { this.working = 0; this.modal.error(e).subscribe(); }
    )
  }

  onSelect(event: { row: LoanerHistory, type: string }) {
    if (event.type != "click")
      return;
    let original = event.row;
    original.endDate = event.row.endDate||null;
    this.dlg = {
      loaner: {...original},
      start_date: Util.toNgbDate(event.row.startDate),
      end_date: Util.toNgbDate(event.row.endDate),
    };
    this.modal.show({
      title: "Update loaner",
      cancelBtn: "Cancel",
      okBtn: "Update",
      template: this.updateLoanerDialog,
      okBtnDisabled: () => !!this.updateButtonHelp.apply(this,[original]),
      okBtnHelp: () => this.updateButtonHelp.apply(this, [original])
    }).pipe(
      switchMap(
        res => {
          this.working = 2;
          return res ?
            this.vhclSvc.updateLoaner(this.dlg.loaner)
            : of(0)
        })
    ).subscribe(
      res => {
        this.working = 0;
        if (!res)
          return;
        //this.datatable.resetTable(); // will be reoladed after shown again
      },
      e => { this.working = 0; this.modal.error(e).subscribe(); }
    )
    
  }

}
