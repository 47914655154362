import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbInputDatepicker } from "@ng-bootstrap/ng-bootstrap";

export interface DateRange {
  rangeKind: DateRangeKind,
  hoveredDate?: NgbDate,
  fromDate?: NgbDate,
  toDate?: NgbDate,
}

export enum DateRangeKind {
  after = 1,
  before,
  range
}

@Component({
  selector: 'shared-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent {  
  @Input() range: DateRange;
  @Input() name: string;
  @Output() onSelect = new EventEmitter<any>();
  @Output() onClear = new EventEmitter<any>();

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) {
  }

  dateRangeRestore(val: string, drp: NgbInputDatepicker) {
    if (!val)
      return;
    let lessidx = val.search('<');
    if (lessidx > 0) {
      this.range.fromDate = NgbDate.from(this.formatter.parse(val.substring(0,lessidx)));
      this.range.toDate = NgbDate.from(this.formatter.parse(val.substring(lessidx+1)));
      this.range.rangeKind = DateRangeKind.range;
    } else
    if (lessidx == 0) {
      this.range.fromDate = NgbDate.from(this.formatter.parse(val.substring(1)));
      this.range.rangeKind = DateRangeKind.before;
    } else
    if (val.startsWith('>')) {
      this.range.fromDate = NgbDate.from(this.formatter.parse(val.substring(1)));
      this.range.rangeKind = DateRangeKind.after;
    }
    if (this.range.fromDate)
      drp.navigateTo(this.range.fromDate);
  }

  onDateRangeSelect(date: NgbDate) {
    if (this.range.rangeKind != DateRangeKind.range) {
      this.range.toDate = null;
      this.range.fromDate = date;
    } else
      if (!this.range.fromDate && !this.range.toDate) {
        this.range.fromDate = date;
    } else 
    if (this.range.fromDate && !this.range.toDate && date && date.after(this.range.fromDate)) {
      this.range.toDate = date;
    } else {
      this.range.toDate = null;
      this.range.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.range.fromDate && !this.range.toDate && this.range.hoveredDate && date.after(this.range.fromDate) && date.before(this.range.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    switch (this.range.rangeKind) {
    case DateRangeKind.after:
        return this.range.fromDate && date.after(this.range.fromDate);
    case DateRangeKind.before:
        return this.range.fromDate && date.before(this.range.fromDate);
    case DateRangeKind.range:
        return this.range.toDate && date.after(this.range.fromDate) && date.before(this.range.toDate);
    }
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.range.fromDate) ||
      (this.range.toDate && date.equals(this.range.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  public rangeSelected(string):boolean {
    return this.range.rangeKind == DateRangeKind.range && !!this.range.toDate;
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  dateRangeApply() {
    let event = {
      target: {
        name:this.name,
        value: this.dateRangeExpression()
      }
    };
    this.onSelect.emit(event);
  }

  onDateRangeKeyDown(e:KeyboardEvent) {
    let tgt = <any>e.target;
    if (e.key == "Enter"){
      let event = {
        target: {
          name:this.name,
          value: tgt.value
        }
      };
      this.onSelect.emit(event);

      tgt.blur();
      //this.dateRangeApply();
    }
  }

  onDateRangeClear(event: MouseEvent) {
    this.range.fromDate = this.range.toDate = null;
    this.onClear.emit(event);
  }
  
  dateRangeExpression() {
    switch (this.range?.rangeKind) {
      case DateRangeKind.after:
        return this.range.fromDate ? ">" + this.formatter.format(this.range.fromDate):'';
      case DateRangeKind.before:
        return this.range.fromDate ? "<" + this.formatter.format(this.range.fromDate):'';
      case DateRangeKind.range:
        return (this.range.fromDate && this.range.toDate) ? this.formatter.format(this.range.fromDate) + "<" + this.formatter.format(this.range.toDate):'';
    }
  }

}