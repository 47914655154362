import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { Params } from '@angular/router';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TileComponent implements OnInit {
  @Input() type: 'primary' | 'success' | 'warning' | 'danger';
  @Input() icon: IconDefinition;
  @Input() title: string;
  @Input() n: number;
  @Input() path: string;
  @Input() query: Params;
  number = '';
  constructor() {}

  ngOnInit() {
    if (this.n >= 10000) {
      this.number = (+this.n / 1000).toFixed(1) + 'k';
    } else {
      this.number = this.n.toString();
    }
  }
}
