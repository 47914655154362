<div class="d-flex flex-column h-100">
  <header>
    <div class="d-flex justify-content-between align-items-end">
      <h2 class="my-1 text-primary">
        <a routerLink="/admin/" title="admin home">
          <i class="fa fa-home fa-lg"></i>
          <i class="fa fa-angle-right fa-lg"></i>
        </a>
        Waiting List Requests
      </h2>
          <div class="col-4">
            <!-- $$Implements SW.ADM.WLIST.PG_ALL_CSV -->
            <button type="button" class="btn btn-warning btn-sm text-nowrap col-10" title="Vehicles" (click)="downloadCsv()">
              Export to CSV
            </button>
          </div>
    </div>
    <hr class="mt-0" />
  </header>
  <section class="d-flex flex-column flex-fill">
  <!-- $$Implements SW.ADM.WLIST.PG_ALL -->
  <shared-datatable 
    #datatable
    [columns]="columns"
    [getData]="getData"
    [defaultRow]="defaultRow"
    [sorts]="sorts"
    [filter]="filter"
    [filterChoices]="filterChoices"
    (activate)="onSelect($event)"
    class="bootstrap"
    style="height:100%"
  >
  </shared-datatable>
  </section>
</div>