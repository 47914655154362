import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Vanpool } from 'src/app/core/models/vanpool';
import { Vehicle } from 'src/app/core/models/vehicle';
import { ConfigService, VehicleService, Util } from "src/app/core";
import { ModalService } from 'src/app/shared';
import { VanpoolService } from 'src/app/core/services/vanpool.service';
import { Router } from '@angular/router';

@Component({
  selector: 'vanpool-add',
  templateUrl: './vanpool-add.component.html',
  styleUrls: ['./vanpool-add.component.css']
})
export class VanpoolAddComponent implements OnInit {

  vanpool: Vanpool = new Vanpool;

  vehicles: Vehicle[]=[];
  communities: string[];
  hours: string[];
  days: string[];

  working = false;

  constructor(
    private router: Router,
    private vanpoolSvc: VanpoolService,
    private cfgSvc: ConfigService,
    private vhclSvc: VehicleService,
    private modal: ModalService
  ) { }

  ngOnInit(): void {
    this.communities = ["",...this.cfgSvc.current.communities];
    this.hours = ["", ...this.cfgSvc.current.work_hours];
    this.days = ["", ...this.cfgSvc.current.work_days];
    this.vanpool.cmnty = this.communities[0];
    this.vanpool.hours = this.hours[0];
    this.vanpool.workdays = this.days[0];
    this.vhclSvc.getAvailable().subscribe(
      v =>{
        v = v.sort((a, b) => parseInt(b.eqno) - parseInt(a.eqno));
        this.vehicles = [new Vehicle,...v];
        this.vanpool.eqno = this.vehicles[0].eqno;
      },
      e =>this.modal.error(e).subscribe()
    );
  }

  dlg: {
    date: NgbDateStruct;
    min_date: NgbDateStruct;
  } = {
    date: Util.toNgbDate(Util.today),
    min_date: Util.toNgbDate(Util.today),
  }

  submitProblem():string {
    if (!this.vanpool.vpname)
    return "Please enter unique name";
    if (!this.vanpool.eqno)
      return "Please select vehicle";
    if (!this.vanpool.cmnty)
      return "Please select community";
    if (!this.vanpool.hours)
      return "Please select working hours";
    if (!this.vanpool.workdays)
      return "Please select working days";
    if (!this.vanpool.dcap)
      return "Please enter desired capacity";
    if (!this.vanpool.ttl_chrg)
      return "Please enter total charge";
    return "";
  }
  submit() {
    this.vanpool.start_date = Util.fromNgbDate(this.dlg.date);
    this.working = true;
    this.vanpoolSvc.add(this.vanpool).subscribe(
      res => res && this.router.navigate(['admin', 'vanpool-dtl', res]),
      e => this.working = !this.modal.error(e).subscribe()
    );

  }
}
