import { Component, OnInit, ViewChild } from '@angular/core';
import { VehicleService } from 'src/app/core';
import { Util } from 'src/app/core';
import { SortPropDir, TableColumn } from '@siemens/ngx-datatable';
import { DatasetCommand } from 'src/app/core/models/dataset';
import { DatatableComponent } from 'src/app/shared/components/datatable/datatable.component';
import { Router } from '@angular/router';
import { VehicleHistory } from 'src/app/core/models/vehicle-history';
import { LoanerHistory } from 'src/app/core/models/loaner-history';

@Component({
  selector: 'vehicle-history',
  templateUrl: './vehicle-history.component.html',
  styleUrls: ['./vehicle-history.component.css'],
})
export class VehicleHistoryComponent implements OnInit {
  VehicleService: any;
  constructor(private VehicleSvc: VehicleService, private router: Router) {}
  vhclColumns: TableColumn[];
  vhclSorts: SortPropDir[] = [];
  vhclFilter: { [key: string]: string } = {};
  vhclFilterChoices: { [key: string]: string[] };
  vhclDefaultRow = new VehicleHistory();

  loanerColumns: TableColumn[];
  loanerSorts: SortPropDir[] = [];
  loanerFilter: { [key: string]: string } = {};
  loanerFilterChoices: { [key: string]: string[] };
  loanerDefaultRow = new LoanerHistory();

  ngOnInit(): void {
    const em = Util.getEM();
    this.vhclColumns = [
      { name: 'Equipment #', prop: 'eqno', headerTemplate: 'textSearch' },
      { name: 'Vehicle Type', prop: 'type', headerTemplate: 'textSearch' },
      { name: 'Make', prop: 'make', headerTemplate: 'textSearch' },
      { name: 'Model', prop: 'model', headerTemplate: 'textSearch', width: 6 * em },
      { name: 'Year', prop: 'year', headerTemplate: 'textSearch', width: 8 * em },
      { name: 'Color', prop: 'color', headerTemplate: 'textSearch', width: 8 * em },
      { name: 'Capacity', prop: 'capacity', headerTemplate: 'textSearch', width: 8 * em },
      { name: 'Vanpool', prop: 'vanpool', headerTemplate: 'textSearch', width: 8 * em },
      { name: 'Start Date', prop: 'startDate', width: 10 * em, headerTemplate: 'dateSearch', cellTemplate: 'dateColumn' },
      { name: 'End Date', prop: 'endDate', width: 10 * em, headerTemplate: 'dateSearch', cellTemplate: 'dateColumn' },
      { name: 'Comment', prop: 'comment', width: 22 * em, headerTemplate: 'textSearch' },
    ];
    this.vhclFilterChoices = {};

    this.loanerColumns = [
      { name: 'Equipment #', prop: 'eqno', headerTemplate: 'textSearch' },
      { name: 'Vehicle Type', prop: 'type', headerTemplate: 'textSearch' },
      { name: 'Make', prop: 'make', headerTemplate: 'textSearch' },
      { name: 'Model', prop: 'model', headerTemplate: 'textSearch', width: 6 * em },
      { name: 'Year', prop: 'year', headerTemplate: 'textSearch', width: 8 * em },
      { name: 'Color', prop: 'color', headerTemplate: 'textSearch', width: 8 * em },
      { name: 'Capacity', prop: 'capacity', headerTemplate: 'textSearch', width: 8 * em },
      { name: 'Start Date', prop: 'startDate', width: 10 * em, headerTemplate: 'dateSearch', cellTemplate: 'dateColumn' },
      { name: 'End Date', prop: 'endDate', width: 10 * em, headerTemplate: 'dateSearch', cellTemplate: 'dateColumn' },
      { name: 'Comment', prop: 'comment', width: 22 * em, headerTemplate: 'textSearch' },
    ];
    this.loanerFilterChoices = {};
  }
  getVhclData = (cmd: DatasetCommand) => this.VehicleSvc.getAllVehicleHistory(cmd);
  getLoanerData = (cmd: DatasetCommand) => this.VehicleSvc.getAllLoanerHistory(cmd);
}
