import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, pipe} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { VacantSeats } from '../models/vacant-seats';
import { Vanpool } from '../models/vanpool';
import { Dataset, DatasetCommand } from '../models/dataset';
import { Util } from './util';
import { TotalCharge } from '../models/total-charge';
import { DesiredCapacity } from '../models/desired-capacity';
import { VanpoolRate } from '../models/vanpool-rate';
import { Mileage } from '../models/mileage';

@Injectable({ providedIn: 'root' })
export class VanpoolService {
  constructor(private http: HttpClient) { } 

  static readonly vanpoolDates: string[] = Util.DatePropertiesKeys(new Vanpool());
  static readonly totalChargeDates: string[] = Util.DatePropertiesKeys(new TotalCharge());
  static readonly desiredCapacityDates: string[] = Util.DatePropertiesKeys(new DesiredCapacity());

  getAll(cmd: DatasetCommand): Observable<Dataset<Vanpool>> {
    let params = Util.CommandToHttpParams(cmd);
    return this.http.get<Dataset<Vanpool>>("api/vanpool/all",{params}).pipe(
      map(ds => {
        ds.data.forEach(d => Util.FixDates(d, VanpoolService.vanpoolDates));
        return ds;
      })
    );
  }

  add(vp:Vanpool): Observable<number> {
    return this.http.post<number>("api/vanpool/add",vp);
  }

  update(vp:Vanpool): Observable<any> {
    return this.http.post<number>("api/vanpool/updt",vp);
  }

  dissolve(id: number, date: Date, reason:string, comment: string): Observable<boolean> {
    let formData = new FormData();
    formData.append('id', id.toString());
    formData.append('reason', reason);
    formData.append('comment', comment);
    formData.append('date', Util.toISODateString(date));
    return this.http.post<any>('api/vanpool/dslv', formData)
      .pipe(switchMap(() => of(true)));
  }

  getVanpoolDetails(vpid: number): Observable<Vanpool> {
    return this.http.get<Vanpool>(`api/vanpool/${vpid}`).pipe(
      map(r => Util.FixDates(r, VanpoolService.vanpoolDates))
    );
  }

  getVacantSeats(vid:number):Observable<VacantSeats> {
    return this.http.get<VacantSeats>(`api/vanpool/vacant/${vid}`).pipe(
      map(v => {v.date = new Date(v.date); return v})
    );
  }

  getChargeHistory(vpid:number): Observable<TotalCharge[]> {
    return this.http.get<TotalCharge[]>(`api/vanpool/${vpid}/chrg`).pipe(
      map(data => {
        data.forEach(d => Util.FixDates(d, VanpoolService.totalChargeDates));
        return data;
      })
    );
  }

  updateTotalCharge(id: number, charge: number, date: Date): Observable<boolean> {
    let formData = new FormData();
    formData.append('id', id.toString());
    formData.append('charge', charge.toString());
    formData.append('date', Util.toISODateString(date));
    return this.http.post<any>('api/vanpool/chrg', formData)
      .pipe(switchMap(() => of(true)));
  }

  getCapacityHistory(vpid: number): Observable<DesiredCapacity[]> {
    return this.http.get<DesiredCapacity[]>(`api/vanpool/${vpid}/dcap`).pipe(
      map(data => {
        data.forEach(d => Util.FixDates(d, VanpoolService.desiredCapacityDates));
        return data;
      })
    );
  }

  updateCapacity(id: number, charge: number, date: Date, comment: string): Observable<boolean> {
    let formData = new FormData();
    formData.append('id', id.toString());
    formData.append('capacity', charge.toString());
    formData.append('date', Util.toISODateString(date));
    formData.append('comment', comment);
    return this.http.post<any>('api/vanpool/dcap', formData)
      .pipe(switchMap(() => of(true)));
  }

  updateCapacityComment(id: number, comment: string): Observable<boolean> {
    let formData = new FormData();
    formData.append('id', id.toString());
    formData.append('comment', comment);
    return this.http.post<any>('api/vanpool/dcap_cmnt', formData)
      .pipe(switchMap(() => of(true)));
  }

  changeVehicle(id: number, eqno: string, date: Date, prev_comment: string, comment: string): Observable<boolean> {
    let formData = new FormData();
    formData.append('id', id.toString());
    formData.append('eqno', eqno);
    formData.append('date', Util.toISODateString(date));
    formData.append('prev_comment', prev_comment);
    formData.append('comment', comment);
    return this.http.post<any>('api/vanpool/vhcl', formData)
      .pipe(switchMap(() => of(true)));
  }

  bulkUpdate(file: File): Observable<boolean> {
    let formData = new FormData();
    formData.append("file", file, file.name);
    return this.http.post<any>('api/vanpool/bulkchrg', formData)
      .pipe(switchMap(() => of(true)));
  }

  static readonly vanpoolRateDates: string[] = Util.DatePropertiesKeys(new VanpoolRate());
  getRateHistory(id:number): Observable<VanpoolRate[]> {
    return this.http.get<VanpoolRate[]>(`api/vanpool/${id}/rates`).pipe(
      map(data => {
        data.forEach(d => Util.FixDates(d, VanpoolService.vanpoolRateDates));
        return data;
      })
    );
  }

  static readonly melageDates: string[] = Util.DatePropertiesKeys(new Mileage());
  getMileage(cmd:DatasetCommand):Observable<Dataset<Mileage>> {
    let params = Util.CommandToHttpParams(cmd);
    return this.http.get<Dataset<Mileage>>("api/vanpool/mlg", { params }).pipe(
      map(ds => {
        ds.data.forEach(d => Util.FixDates(d, VanpoolService.melageDates));
        return ds;
      })
    );
  }

  getCandidatesForAddMileage(): Observable<Mileage[]> {
    return this.http.get<Mileage[]>("api/vanpool/addmlg").pipe(
      map(data => {
        data.forEach(d => Util.FixDates(d, VanpoolService.melageDates));
        return data;
      })
    );
  }

  addMileage(mlg:Mileage): Observable<boolean> {
    return this.http.post<any>('api/vanpool/mlg', mlg)
      .pipe(switchMap(() => of(true)));
  }

  updateMileage(mlg:Mileage): Observable<boolean> {
    return this.http.put<any>('api/vanpool/mlg', mlg)
      .pipe(switchMap(() => of(true)));
  }


}