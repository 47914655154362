import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { CompleterData, CompleterItem } from "@vldmr-bus/ng-completer";
import { PeopleService } from "../../../core/services/people.service";
import { PersonLookup } from "../../../core/models/person-lookup";

export class PersonData extends Subject<CompleterItem[]> implements CompleterData {
  constructor(private pplSvc: PeopleService) {
    super();
  }
  public search(term: string): void {
    this.pplSvc.search(term)
      .pipe(
        map((data: PersonLookup[]) => {
          const matches = (data)
            .map((card) => this.convertToItem(card)) as CompleterItem[];
          this.next(matches);
        })
      )
      .subscribe();
  }

  public cancel() {
    // Handle cancel
  }

  public convertToItem(person: PersonLookup): CompleterItem | null {
    if (!person) {
      return null;
    }
    // data will be string if an initial value is set
    return {
      title: person.name,
      originalObject: person,

    } as CompleterItem;
  }
}
