
<div class="input-group">
  <input 
    ngbDatepicker 
    name="{{name}}" 
    type="text" 
    #drp="ngbDatepicker" 
    [container]="'body'"
    [dayTemplate]="dateRangeTmplt"
    [footerTemplate]="dateRangeFooter"
    [autoClose]="'outside'"
    outsideDays="hidden"
    [displayMonths]="2"
    [startDate]="range?.fromDate"
    style="max-width: 8em;" 
    (click)="drp.toggle(); dateRangeRestore($any($event.target).value,drp);"
    (dateSelect)="onDateRangeSelect($event)"
    [value]="dateRangeExpression()"
    (keydown)="onDateRangeKeyDown($event)"
  >
  <button *ngIf="range.fromDate" class="btn bg-transparent p-0" style="margin-left: -16px; z-index: 100;"
    (click)="onDateRangeClear($event)">
    <i class="fa fa-times fa-small"></i>
  </button>
</div>

<ng-template #dateRangeTmplt let-date let-focused="focused">
  <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="range.hoveredDate = date"
    (mouseleave)="range.hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>

<ng-template #dateRangeFooter>
  <hr class="my-0" />
  <div class="d-flex flex-row">
    <div class="form-check mx-2">
      <label class="form-check-label">
        <input class="form-check-input" type="radio" [value]="1" [(ngModel)]="range.rangeKind" name="dateRangeRadio">
        After
      </label>
    </div>
    <div class="form-check mx-2">
      <label class="form-check-label">
        <input class="form-check-input" type="radio" [value]="2" [(ngModel)]="range.rangeKind" name="dateRangeRadio">
        Before
      </label>
    </div>
    <div class="form-check mx-2">
      <label class="form-check-label">
        <input class="form-check-input" type="radio" [value]="3" [(ngModel)]="range.rangeKind" name="dateRangeRadio">
        Range
      </label>
    </div>
    <button class="btn-xs btn-success ms-auto m-1" (click)="dateRangeApply();drp.close()">Apply</button>
  </div>
</ng-template>
