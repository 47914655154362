import { Component, OnInit } from '@angular/core';
import { SortPropDir, TableColumn, SortDirection } from '@siemens/ngx-datatable';
import { PendingDriverRequest } from 'src/app/core/models/pending-driver-request';
import { MembersService, Util } from 'src/app/core';
import { Router } from '@angular/router';

@Component({
  selector: 'admin-pdrivers',
  templateUrl: './pdrivers.component.html',
  styleUrls: ['./pdrivers.component.css']
})
export class PdriversComponent implements OnInit {
  columns: TableColumn[];
  sorts: SortPropDir[] = [];
  filter: { [key: string]: string } = {};
  filterChoices:{[key:string]:string[]};
  defaultRow = new PendingDriverRequest();

  constructor(
    private router: Router,
    private membSvc: MembersService
  ) { 
    this.sorts = [{
      prop: 'vanpool',
      dir: SortDirection.asc
    }]
  }

  ngOnInit(): void {
    const em = Util.getEM();
    this.columns = [  // $$Implements SW.ADM.WLIST.PG_ALL
      { name: "Vanpool", prop: "vanpool", headerTemplate: "textSearch", width: 12*em },
      { name: "Work Hours", prop: "hours" },
      { name: "First Name", prop: "first_name", canAutoResize: true, headerTemplate: "textSearch" },
      { name: "Last Name", prop: "last_name", headerTemplate: "textSearch" },
      { name: "Agreement Type", prop: "type", headerTemplate: "selectSearch" },
    ];
    this.filterChoices = {
      'type': ['Driver', 'Backup Driver']
    }
  }

  getAllData = () => this.membSvc.getPendingDirvers();

    onSelect(event: { row: PendingDriverRequest, type: string }) {
      if (event.type != "click")
        return;
      this.router.navigate(['admin', 'memb-dtl', event.row.memberid]);
  }


}
