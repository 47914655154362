import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VanpoolsComponent } from './vanpools/vanpools.component';


const routes: Routes = [{
  path: '',
  component: VanpoolsComponent,
  data: { title: null }
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VanpoolsRoutingModule { }
