  <div class="container">
    <section class="my-4 bg-white shadow">
      <div class="bg-success py-2 px-3">
        <p class="h4 text-white m-0">
          <i class="fa fa-hand fa-lg"></i>
          Vanpool Details
        </p>
      </div>
      <div class="p-3" *ngIf="vanpool">
        <div class="w-100">
          <div class="row pl-2">
            <div class="card-field">
              <span class="card-field-label">Vanpool</span>
              <input type="text" [(ngModel)]="this.vanpool.vpname" class="card-field-value form-control" placeholder="comment" *ngIf="!vanpool.end_date"/>
              <span class="card-field-value" *ngIf="!!vanpool.end_date">{{vanpool.vpname}}</span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Community</span>
              <span class="card-field-value" *ngIf="!vanpool.end_date">
                <select [(ngModel)]="vanpool.cmnty">
                  <option *ngFor="let c of communities" [selected]="c==vanpool.cmnty">
                    {{c}}
                  </option>
                </select>
              </span>
              <span class="card-field-value" *ngIf="vanpool.end_date">{{vanpool.cmnty}}</span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Work Hours</span>
              <span class="card-field-value" *ngIf="!vanpool.end_date">
                <select [(ngModel)]="vanpool.hours">
                  <option *ngFor="let h of hours" [selected]="h==vanpool.hours">
                    {{h}}
                  </option>
                </select>
              </span>
              <span class="card-field-value" *ngIf="vanpool.end_date">{{vanpool.hours}}</span>
            </div>
            <div class="card-field" *ngIf="vehicle">
              <span class="card-field-label">Vehicle plate #</span>
              <span class="card-field-value">{{vehicle.license_plate}}</span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Work days</span>
              <span class="card-field-value" *ngIf="!vanpool.end_date">
                <select [(ngModel)]="vanpool.workdays">
                  <option *ngFor="let d of days" [selected]="d==vanpool.workdays">
                    {{d}}
                  </option>
                </select>
              </span>
              <span class="card-field-value" *ngIf="vanpool.end_date">{{vanpool.workdays}}</span>
            </div>
            <div class="card-field" *ngIf="vehicle">
              <span class="card-field-label">Vehicle equipment #</span>
              <span class="card-field-value">
                {{vehicle.eqno}}
                <button type="button" class="btn btn-warning btn-xs text-nowrap" (click)="changeVehicle()" *ngIf="vanpool.status=='Active'">
                  Change
                </button>
              </span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Desired capacity</span>
              <span class="card-field-value">{{vanpool.dcap}}
                <button type="button" class="btn btn-warning btn-xs text-nowrap" (click)="updateCap()"
                  *ngIf="vanpool.status=='Active'">
                  Update
                </button>
              </span>
            </div>
            <div class="card-field" *ngIf="vehicle">
              <span class="card-field-label">Vehicle make</span>
              <span class="card-field-value">{{vehicle.make}}</span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Current Members</span>
              <span class="card-field-value">{{vanpool.mmbr_count}}
                <a class="btn btn-success btn-xs text-nowrap" routerLink='/admin/members'
                  [queryParams]='membersQry()'>
                  View
                </a>
              </span>
            </div>
            <div class="card-field" *ngIf="vehicle">
              <span class="card-field-label">Vehicle model</span>
              <span class="card-field-value">{{vehicle.model}}</span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Current Offers</span>
              <span class="card-field-value">{{vanpool.offer_count}}
                <a class="btn btn-success btn-xs text-nowrap" *ngIf="vanpool.offer_count" routerLink='/admin/requests'
                  [queryParams]="{offers_in:vanpool.vpname}">
                  View
                </a>
              </span>
            </div>
            <div class="card-field" *ngIf="vehicle">
              <span class="card-field-label">Vehicle color</span>
              <span class="card-field-value">{{vehicle.color}}</span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Waiting Lists</span>
              <span class="card-field-value">{{vanpool.wlist_count}}
                <a class="btn btn-success btn-xs text-nowrap" routerLink='/admin/requests'
                  [queryParams]="{all_in:vanpool.vpname}">
                  View
                </a>
              </span>
            </div>
            <div class="card-field" *ngIf="vehicle">
              <span class="card-field-label">Vehicle year</span>
              <span class="card-field-value">{{vehicle.year}}</span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Vacant Seats</span>
              <span class="card-field-value">{{vanpool.vcnt_count}}</span>
            </div>
            <div class="card-field" *ngIf="vehicle">
              <span class="card-field-label">Vehicle type</span>
              <span class="card-field-value">{{vehicle.type}}</span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Current Member Rate</span>
              <span class="card-field-value">{{currencyString(vanpool.mmbr_rate)}}</span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Allow Sub-rider</span>
              <span class="card-field-value">
                <select [(ngModel)]="vanpool.accept_subriders">
                  <option [ngValue]="true">Yes</option>
                  <option [ngValue]="false">No</option>
                </select>
              </span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Total charge</span>
              <span class="card-field-value">{{currencyString(vanpool.ttl_chrg)}}
                <button class="btn btn-warning btn-xs text-nowrap" (click)="updateCharge()" *ngIf="vanpool.status=='Active'">
                  Update
                </button>
              </span>
            </div>
            <div class="card-field" *ngIf="vanpool.accept_subriders">
              <span class="card-field-label">Sub-rider rate</span>
              <span class="card-field-value">{{currencyString(vanpool.srdr_rate)}}</span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Start date</span>
              <span class="card-field-value">{{dateString(vanpool.start_date)}}</span>
            </div>
            <div class="card-field" *ngIf="vanpool.end_date">
              <span class="card-field-label">End date</span>
              <span class="card-field-value">{{dateString(vanpool.end_date)}}</span>
            </div>
            <div class="card-field" *ngIf="vanpool.end_reason">
              <span class="card-field-label">End reason</span>
              <span class="card-field-value">
                <select [(ngModel)]="vanpool.end_reason">
                  <option *ngFor="let r of vanpool_end_reasons" [selected]="r==vanpool.end_reason">
                    {{r}}
                  </option>
                </select>
              </span>
            </div>
            <div class="card-field-long">
              <span class="card-field-label">Comment:</span>
              <input type="text" [(ngModel)]="vanpool.comment" class="form-control" placeholder="comment" />
            </div>
          </div>
        </div>

        <div class="row text-center my-1">
          <div class="col-4">
            <button class="btn btn-danger btn-sm text-nowrap col-10" (click)="dissolve()" *ngIf="!vanpool.end_date"
               [title]="vanpool.mmbr_count?'Vanpool still has active members':''" [disabled]="vanpool.end_date || vanpool.mmbr_count">
              Dissolve
            </button>
          </div>
          <div class="col-4">
            <a class="btn btn-success btn-sm text-nowrap col-10" [routerLink]="['../../rate-history',vanpool.vpoolid]">
              Rates history
            </a>
          </div>
          <div class="col-4">
            <button type="button" class="btn btn-warning btn-sm text-nowrap col-10" (click)="update()"
              *ngIf="dirty()">
              Update
            </button>
          </div>
        </div>
      </div>
    </section>
    <section *ngIf="loading" class="d-flex justify-content-center my-5">
      <uiowa-ring></uiowa-ring>
    </section>
  </div>

    <div class="container w-100">
      <div class="row">
        <div class="col-12 col-md-6 col-xl-6">
          <h4>Total Charge History</h4>
          <shared-datatable #chargeTable 
            [serverSide]="false" 
            [columns]="chargeColumns" 
            [sorts]="chargeSorts" 
            [footerHeight]="0"
            [scrollbarV]="false"
            [getAllData]="getChargeHistory" 
            [defaultRow]="defaultChargeRow" 
            class="bootstrap" 
            style="height:100%">
          </shared-datatable>
        </div>
        <div class="col-12 col-md-6 col-xl-6">
          <h4>Desired Capacity History</h4>
          <shared-datatable #capacityTable
            [serverSide]="false" 
            [columns]="capacityColumns" 
            [sorts]="capacitySorts" 
            [footerHeight]="0"
            [scrollbarV]="false"
            [getAllData]="getCapacityHistory" 
            [defaultRow]="defaultCapacityRow" 
            [selectionType]="null"
            class="bootstrap" 
            style="height:100%">
          </shared-datatable>
        </div>
      </div>
    </div>

<ng-template #dissolveDialog>
  <label for="effect_date" class="col-6">End date:</label>
  <input name="date" type="text" ngbDatepicker #date="ngbDatepicker" [(ngModel)]="dlg.date" class="col-6"
    (click)="date.toggle()">
  <label for="new_payment_type">Reason:</label>
  <select name="new_payment_type" [(ngModel)]="dlg.select">
    <option *ngFor="let op of vanpool_end_reasons">
      {{op}}
    </option>
  </select>
  <br>
  <label for="comment">Comment:</label>
  <input name="comment" type="text" [(ngModel)]="dlg.comment">
</ng-template>


<ng-template #chargeUpdateDialog>
  <label for="charge">New Total Charge</label>
  <input type="number"  min="1" name="charge" [(ngModel)]="dlg.value">
  <label for="effect_date" class="col-6">Effective date:</label>
  <input name="date" type="text" ngbDatepicker #date="ngbDatepicker"
    [(ngModel)]="dlg.date" class="col-6" (click)="date.toggle()"
    [minDate]="dlg.min_date">
</ng-template>

<ng-template #capacityUpdateDialog>
  <label for="charge">New Desired Capacity</label>
  <input type="number" [min]="dlg.min_value" [max]="dlg.max_value" name="charge" [(ngModel)]="dlg.value"> <br/>
  <label for="effect_date" class="col-4">Effective date:</label>
  <input name="date" type="text" ngbDatepicker #date="ngbDatepicker" [(ngModel)]="dlg.date" class="col-12"
    (click)="date.toggle()" [minDate]="dlg.min_date"> <br/>
  <label for="comment" class="col-4">Comment:</label>
  <input name="comment" type="text"  [(ngModel)]="dlg.comment">
</ng-template>


<ng-template #vhclInfo let-v="v" let-cls="c">
  <div [class]="cls" >{{v.license_plate}}</div>
  <div [class]="cls" >{{v.make}}</div>
  <div [class]="cls" >{{v.model}}</div>
  <div [class]="cls" >{{v.year}}</div>
  <div [class]="cls" >{{v.color}}</div>
  <div [class]="cls" >{{v.type}}</div>
  <div [class]="cls" >{{v.capacity}}</div>
</ng-template>

<ng-template #swapVehiclesDialog>
  <div class="container">
    <div class="row">
      <div class="col col-3">
        <br>
        <div class='font-weight-bold text-nowrap'>Equipment #</div>
        <ng-container #one *ngTemplateOutlet="vhclInfo; context: {v:{license_plate:'Plate',make:'Make',model:'Model',year:'Year',color:'Color',type:'Type',capacity:'Capacity'},c:'font-weight-bold text-nowrap' }"></ng-container>
      </div>
      <div class="col col-3">
        <div class='font-weight-bold'>From:</div>
        <div>{{this.vehicle.eqno}}</div>
        <ng-container #two *ngTemplateOutlet="vhclInfo; context: {v:this.vehicle, c:'text-nowrap'}"></ng-container>
      </div>
      <div class="col col-6">
        <div class='font-weight-bold'>To:</div>
        <div>
          <select [(ngModel)]="dlg.eqno">
            <option *ngFor="let v of dlg.eqnos" value="{{v}}">
              {{v}} {{dlg.vehicles[v].model}} {{dlg.vehicles[v].year}}
            </option>
          </select>
        </div>
        <span *ngIf="dlg.eqno">
          <ng-container *ngTemplateOutlet="vhclInfo; context: {v:dlg.vehicles[dlg.eqno], c:'text-nowrap'}"></ng-container>
        </span>
      </div>
    </div>
  </div>
  <div>
    <label for="effect_date">Effective date:</label>
    <input name="date" type="text" ngbDatepicker #date="ngbDatepicker" [(ngModel)]="dlg.date" 
      (click)="date.toggle()">
  </div>
  <div class="font-weight-bold">Comments for assignments:</div>
  <label for="comment">Previous:</label>
  <input name="comment" type="text"  [(ngModel)]="dlg.prev_comment">
  <label for="comment">New:</label>
  <input name="comment" type="text"  [(ngModel)]="dlg.comment">
</ng-template>

<ng-template #textInputColumn let-row="row">
  <input type="text" [(ngModel)]="row.comment" (keyup.enter)="blur($event)" (blur)="onCapacityComment($event,row)" class="input-xs w-100">
</ng-template>