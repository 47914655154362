import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from "../../shared";
import { RequestsRoutingModule } from './requests-routing.module';
import { RequestsComponent } from './requests/requests.component';
import { WaitlistService, ConfigService, Util } from 'src/app/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [RequestsComponent],
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    RequestsRoutingModule
  ],
  providers:[
    WaitlistService,
    ConfigService,
    Util
  ]
})
export class RequestsModule { }
