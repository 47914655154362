<div>
  <div class="modal-mask">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">{{title}}</h4>
            <button type="button" class="close" (click)="close(false)" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body">
            <p [innerHTML]="message" *ngIf="message"></p>
            <ng-container *ngTemplateOutlet="template"></ng-container>
          </div>
          <div class="modal-footer">
            <button *ngIf="cancelBtn" type="button" class="btn btn-default"
              (click)="close(false)">{{cancelBtn}}</button>
            <button 
              id="modalComponentOkButton"
              type="button" 
              class="btn btn-danger" 
              [title]="getOkBtnHelp()"
              [disabled]="getOkBtnDisabled()"
              (click)="close(true)"
            >
              {{okBtn||"OK"}}
            </button>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
  </div>
</div>