import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SortDirection, SortPropDir, TableColumn } from '@siemens/ngx-datatable';
import { ConfigService, Util } from 'src/app/core';
import { DatasetCommand } from 'src/app/core/models/dataset';
import { Vanpool } from 'src/app/core/models/vanpool';
import { VanpoolService } from 'src/app/core/services/vanpool.service';
import { ModalService } from 'src/app/shared';
import { DatatableComponent } from 'src/app/shared/components/datatable/datatable.component';

@Component({
  selector: 'admin-vanpools',
  templateUrl: './vanpools.component.html',
  styleUrls: ['./vanpools.component.css']
})
export class VanpoolsComponent implements OnInit {
  @ViewChild('datatable') datatable: DatatableComponent;

  columns: TableColumn[];
  sorts: SortPropDir[] = [];
  filter: { [key: string]: string } = {};
  filterChoices: { [key: string]: string[] };
  defaultRow = new Vanpool();
  
  working=0;

  constructor(
    private vanpoolSvc: VanpoolService,
    private cfgSvc: ConfigService,
    private router: Router,
    private modal: ModalService
  ) {
    this.filter = {
      status: '=Active'
    }
    this.sorts = [{ prop: 'vpname', dir:  SortDirection.asc}];
  }

  ngOnInit(): void {
    const em = Util.getEM();
    this.columns = [  // $$Implements SW.ADM.VPOOL.PG_ALL
      { name: "Vanpool", prop: "vpname", headerTemplate: "textSearch", width: 12*em },
      { name: "Status", prop: "status", headerTemplate: "selectSearch" },
      { name: "Plate #", prop: "plateno", headerTemplate: "textSearch" },
      { name: "Work Hours", prop: "hours", headerTemplate: "selectSearch" },
      { name: "Work Days", prop: "workdays", headerTemplate: "selectSearch", width: 6*em },
      { name: "Desired Capacity", prop: "dcap", headerTemplate: "textSearch", width: 8*em },
      { name: "Members", prop: "mmbr_count", headerTemplate: "textSearch", width: 8*em },
      { name: "Vacant Seats", prop: "vcnt_count", headerTemplate: "textSearch", width: 8*em },
      { name: "Offers", prop: "offer_count", headerTemplate: "textSearch", width: 6*em },
      { name: "Waiting List", prop: "wlist_count", headerTemplate: "textSearch", width: 6*em },
      { name: "Rate", prop: "mmbr_rate", headerTemplate: "textSearch", width: 6 * em, pipe: new CurrencyPipe('en-US') },
      { name: "Allow sub-rider", prop: "accept_subriders", headerTemplate: "boolSearch", cellTemplate: "boolColumn", width: 8*em },
      { name: "Sub-rider rate", prop: "srdr_rate", headerTemplate: "textSearch", width: 8 * em, pipe: new CurrencyPipe('en-US') },
      { name: "Total Charge", prop: "ttl_chrg", headerTemplate: "textSearch", width: 8 * em, pipe: new CurrencyPipe('en-US') },
      { name: "Comment", prop: "comment", width: 22 * em, headerTemplate: "textSearch" },
    ];
    this.filterChoices = {
      'hours': [...this.cfgSvc.current.work_hours],
      'status': ['Active','Dissolved'],
      'workdays': [...this.cfgSvc.current.work_days]
    }
  }

  getData = (cmd: DatasetCommand) => this.vanpoolSvc.getAll(cmd);

  onSelect(event: { row: Vanpool, type: string }) {
    if (event.type != "click")
      return;
    this.router.navigate(['admin', 'vanpool-dtl', event.row.vpoolid]);
  }

  toggleEqNumber() {
    let name = "Equipment #";
    if (this.columns[2].name != name)
      this.columns.splice(2, 0, { name, prop: "eqno", headerTemplate: "textSearch" });
    else
      this.columns.splice(2, 1);
    this.columns = [...this.columns];
  }

  bulkUpload(event) {
    let fileinput: HTMLInputElement = event.target;
    let file:File = event.target.files[0];
    if (!file)
      return;
    fileinput.value = null;
    this.working=2;
    this.vanpoolSvc.bulkUpdate(file).subscribe(
      ()=>{
        this.working=1;
        this.modal.notify("Success","Successfully updated total charges").subscribe(
          () => {
            this.working=0;
            setTimeout(()=>this.datatable.resetTable());
          }
        );
      },
      (e)=>{
        this.working=1;
        this.modal.error(e).subscribe(()=>this.working=0);
      }
    )
  }
}
