<div class="d-flex flex-column h-100">
  <header>
    <div class="d-flex justify-content-between align-items-end">
      <h2 class="my-1 text-primary">
        <a routerLink="/admin/" title="admin home">
          <i class="fa fa-home fa-lg"></i>
          <i class="fa fa-angle-right fa-lg"></i>
        </a>
        Loaners
      </h2>
    </div>
    <hr class="mt-0" />
  </header>
  <section class="d-flex flex-column flex-fill">
    <div class="row text-center my-1">
      <div class="col-4">
        <button type="button" class="btn btn-danger btn-sm text-nowrap col-10" (click)="addLoaner()">
          New Loaner
        </button>
      </div>
    </div>
    <hr class="mt-1 mb-0 w-100">
    <div *ngIf="working>1" class="d-flex justify-content-center my-5">
      <uiowa-ring></uiowa-ring>
    </div>
    <shared-datatable #datatable *ngIf="working<2"
      [columns]="columns" 
      [getData]="getData" 
      [defaultRow]="defaultRow" 
      [sorts]="sorts"
      [filter]="filter" 
      [selectionType]="null"
      (activate)="onSelect($event)"
      class="bootstrap"
      style="height:100%">
    </shared-datatable>
  </section>
</div>

<ng-template #addLoanerDialog>
  <label for="start_date">Vehicle:</label>
  <select [(ngModel)]="dlg.vehicleId">
    <option *ngFor="let v of vehicles" value="{{v.id}}">
      {{v.eqno}} {{v.model}} {{v.year}}
    </option>
  </select>
  <label for="start_date">Start date:</label>
  <input name="start_date" type="text" ngbDatepicker #start_date="ngbDatepicker" 
    [(ngModel)]="dlg.start_date"
    (click)="start_date.toggle()">
  <br>
  <label for="comment">Comment:</label>
  <input name="comment" type="text" [(ngModel)]="dlg.comment">
</ng-template>

<ng-template #updateLoanerDialog>
  <div>Update loaner with equipment # {{dlg.loaner.eqno}}</div>
  <label for="start_date">Start date:</label>
  <input name="start_date" type="text" ngbDatepicker #start_date="ngbDatepicker" 
    [(ngModel)]="dlg.start_date"
    (click)="start_date.toggle()">
  <br>
  <label for="end_date">End date:</label>
  <input name="end_date" ngbDatepicker type="text" #end_date="ngbDatepicker" 
    [(ngModel)]="dlg.end_date" 
    (click)="end_date.toggle()">
  <br>
  <label for="comment">Comment:</label>
  <input name="comment" type="text" [(ngModel)]="dlg.loaner.comment">
</ng-template>
