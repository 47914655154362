export class Vehicle {
  id: number = 0;
  eqno: string = '';
  license_plate: string = '';
  make: string = '';
  model: string = '';
  year: number = 0;
  color: string = '';
  type: string = '';
  capacity: number = 0;
  is_loaner: boolean;
  assignement: string = '';
  comment: string = '';
}
