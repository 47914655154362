<!-- $$Implements SW.ADM.PG_HOME -->
<div class="d-flex flex-column h-100 w-100">
    <header>
        <h2 class="my-1 text-primary">
            Admin Dashboard
            <div ngbDropdown class="d-inline-block d-lg-none">
                <button class="btn btn-link" id="admin-menu" ngbDropdownToggle>
                    More
                </button>
                <div ngbDropdownMenu aria-labelledby="admin-menu">
                    <ng-container *ngFor="let item of linkItems">
                        <a class="dropdown-item" routerLink="/admin/{{ item.path }}">
                            <fa-icon [icon]="($any(item.icon))" [fixedWidth]="true" aria-hidden="true"></fa-icon>
                            {{ item.text }}
                        </a>
                    </ng-container>
                </div>
            </div>
        </h2>
        <hr class="mt-0" />
    </header>
    <section class="d-flex flex-wrap flex-shrink-0" *ngIf="!loading">
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 p-1 d-flex">
            <app-tile type="warning" [icon]="newRqIcon" title="New Wait List Requests" [n]="newRequests"
                path="../requests" [query]="{'new':1}"></app-tile>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 p-1 d-flex">
            <app-tile type="success" [icon]="ptnlOfferIcon" title="Potential Offers" [n]="potentialOffers" path="../poffers">
            </app-tile>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 p-1 d-flex">
            <app-tile type="primary" [icon]="rcntOffersIcon" title="Offers in last 30 days" [n]="pendingOffers+assignedOffers+declinedOffers+expiredOffers"
                path="../requests" [query]="{recent_offers:1}">
            </app-tile>
        </div>

        <!-- $$Implements SW.ADM.TL_DRV_LVNG -->
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 p-1 d-flex" *ngIf="driverLeaving.length" title="Click to order by {{driverLeavingOrderByName?'date':'name'}}" (click)="reorderDriverLeaving()">
            <div class="d-flex flex-fill text-white p-3 shadow" style='background-color: #f44336;'>
                <div class="d-flex justify-content-between w-100">
                    <div class="">
                        <p class="m-0">
                            <fa-icon [icon]="driverIcon" size="3x" aria-hidden="true"></fa-icon>
                        </p>
                        <p class="title-text m-0">Driver leaving</p>
                    </div>
                    <div>
                        <ul>
                            <li *ngFor='let v of driverLeaving'>
                                <a routerLink='/admin/members' [queryParams]='driversLeavingQry(v.name)' title="{{v.date.toLocaleDateString('en-US')}}" class="text-white">{{v.name}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 col-xl-3 p-1 d-flex">
          <app-tile type="warning" [icon]="pendDrvrIcon" title="Pending driver requests"
            [n]="pendingDrivers" path="../pdrivers">
          </app-tile>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 p-1 d-flex">
          <app-tile type="warning" [icon]="srdrRqIcon" title="Sub-rider requests" [n]="subriderRequests" path="../subrider-rqs" [query]="{pending:1}">
          </app-tile>
        </div>

        <div class="col-12 col-md-6 col-lg-4 col-xl-3 p-1 d-flex" *ngIf="smry && smry.biweekly && smry.biweekly.length">
            <div class="d-flex flex-fill text-white p-3 shadow" style='background-color: #ff9800;'>
                <div class="d-flex justify-content-between w-100">
                    <div class=""><p class="m-0">
                        <fa-icon [icon]="biweeklyIcon" size="3x" aria-hidden="true"></fa-icon>
                    </p>
                        <p class="title-text m-0">Biweekly payments</p>
                    </div>
                    <div>
                        <ul>
                            <li *ngFor='let p of smry.biweekly'>
                                <a routerLink='/admin/people' [queryParams]='{uid:p.univId}'
                                    title="Paygroup:{{p.payGroup}}, Payment type:{{p.paymentType}}" class="text-white text-nowrap">{{p.name}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 p-1 d-flex" *ngIf="smry && smry.bad_payments && smry.bad_payments.length">
            <div class="d-flex flex-fill text-white p-3 shadow" style='background-color: #f44336;'>
                <div class="d-flex justify-content-between w-100">
                    <div class=""><p class="m-0">
                        <fa-icon [icon]="biweeklyIcon" size="3x" aria-hidden="true"></fa-icon>
                    </p>
                        <p class="title-text m-0">Mismatched payments</p>
                    </div>
                    <div>
                        <ul>
                            <li *ngFor='let p of smry.bad_payments'>
                                <a routerLink='/admin/people' [queryParams]='{uid:p.univId}'
                                    title="Paygroup:{{p.payGroup}}, Payment type:{{p.paymentType}}" class="text-white text-nowrap">{{p.name}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!-- $$Implements SW.ADM.PNL_RCNT_OFFERS -->
    <section id="charts" class="row my-4" *ngIf="!loading" >
        <div class="text-center h5" style="width: 100%;" *ngIf="!showChart()">No offers in last 30 days</div>
        <div class="col-12 my-4 text-center" *ngIf="showChart()" style="max-width: 40em;">
            <div class="h5">
                <a routerLink="/admin/recent-offers">Offers Issued in Last 30 Days</a>
                <canvas baseChart width="400" height="250" [data]="pieChartData" 
                    [options]="pieChartOptions" [type]="'pie'"
                    (chartClick)="getSegment($event)"
                ></canvas>
            </div>
        </div>
    </section>
</div>