import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SortDirection, SortPropDir, TableColumn } from '@siemens/ngx-datatable';
import { Util } from 'src/app/core';
import { DatasetCommand } from 'src/app/core/models/dataset';
import { VanpoolService } from 'src/app/core/services/vanpool.service';
import { ModalService } from 'src/app/shared';
import { DatatableComponent } from 'src/app/shared/components/datatable/datatable.component';
import { Mileage } from 'src/app/core/models/mileage';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'admin-mileage',
  templateUrl: './mileage.component.html',
  styleUrls: ['./mileage.component.css']
})
export class MileageComponent implements OnInit {
  @ViewChild('datatable') datatable: DatatableComponent;
  @ViewChild('monthColumn', { static: true }) monthColumn: TemplateRef<any>;
  @ViewChild("addMileageDialog") addMileageDialog: TemplateRef<any>;
  @ViewChild("updateMileageDialog") updateMileageDialog: TemplateRef<any>;

  columns: TableColumn[];
  sorts: SortPropDir[] = [{ prop: "start_date", dir: SortDirection.desc }];
  filter: { [key: string]: string } = {};
  defaultRow = new Mileage();

  working = 0;

  constructor(
    private vanpoolSvc: VanpoolService,
    private modal: ModalService
  ) { }

  ngOnInit(): void {
    const em = Util.getEM();
    this.columns = [  // $$Implements SW.ADM.VPOOL.PG_ALL
      { name: "Vanpool", prop: "vpname", headerTemplate: "textSearch", width: 12*em },
      { name: "Equipment #", prop: "eqno", headerTemplate: "textSearch" },
      { name: "Month", prop: "start_date", headerTemplate: "dateSearch", cellTemplate: this.monthColumn},
      { name: "Beginning Mileage", prop: "begin_mlg", headerTemplate: "textSearch" },
      { name: "End Mileage", prop: "end_mlg", headerTemplate: "textSearch" },
      { name: "Total Mileage", prop: "ttl_mlg", headerTemplate: "textSearch" },
      { name: "Loaner Mileage", prop: "lnr_mlg", headerTemplate: "textSearch" },
      { name: "Grand Total", prop: "grnd_ttl", headerTemplate: "textSearch" },
      { name: "12 Month Average", prop: "yr_avg", headerTemplate: "textSearch" },
      { name: "Comment", prop: "comment", width: 22 * em, headerTemplate: "textSearch" },
    ];
  }

  getData = (cmd: DatasetCommand) => this.vanpoolSvc.getMileage(cmd);

  formatMonth(d:Date):string {
    if (!d)
      return "";
    let month = d.getMonth()+1;
    return `${month>9?"":"0"}${month}/${d.getFullYear()}`;
  }

  dlg = {
    month:Util.monthName(Util.addMonths(-1).getMonth()),
    year: new Date().getFullYear(),
    mileage: <Mileage>{},
    candidates: <Mileage[]>[]
  };

  addMileageMessage():string {
    if (!this.dlg.mileage.end_mlg)
      return "Please enter end mileage";
    if (!this.dlg.mileage.begin_mlg)
      return "Please enter beginning mileage";
    if (this.dlg.mileage.end_mlg <= this.dlg.mileage.begin_mlg)
      return "End mileage is less than beginning mileage"
    return "";
  }

  addMileage() {
    this.dlg.month = Util.monthName(Util.addMonths(-1).getMonth());
    this.dlg.year= new Date().getFullYear();
    this.working = 1;
    this.vanpoolSvc.getCandidatesForAddMileage().pipe(
      switchMap(
        v => {
          this.working = 0;
          if (!v.length)
            return this.modal.notify("Can not change vehicle", "No available vehicles found").pipe(switchMap(() => of(0)));
          this.dlg.candidates=[...v];
          this.onCandidateSelect(v[0].vpname);
          return this.modal.show({
            title: "Add mileage",
            cancelBtn: "Cancel",
            template: this.addMileageDialog,
            okBtnDisabled: ()=>!!this.addMileageMessage.apply(this),
            okBtnHelp: ()=>this.addMileageMessage.apply(this),
          });
        }
      ),
      switchMap(res => {
        return res ?
          this.vanpoolSvc.addMileage(this.dlg.mileage)
          : of(0)
      })
    ).subscribe(
      res => { 
        if (!res)
          return;
        this.working = 0
        this.dlg.mileage.end_mlg = undefined;
        this.dlg.mileage.lnr_mlg = undefined;
        this.dlg.mileage.comment = undefined;
        this.datatable.resetTable();
      },
      e => {this.working = 0; this.modal.error(e).subscribe(); }
    )
  }

  onCandidateSelect(value:string) {
    let c = this.dlg.candidates.find(m=>m.vpname == value);
    this.dlg.mileage.vpname = c.vpname;
    this.dlg.mileage.vpoolid = c.vpoolid;
    this.dlg.mileage.eqno = c.eqno;
    this.dlg.mileage.begin_mlg = c.begin_mlg;
  }

  onSelect(event: { row: Mileage, type: string }) {
    if (event.type != "click")
      return;
    let original = event.row;
    this.dlg.mileage = {...original};
    this.dlg.month = Util.monthName(original.start_date.getMonth());
    this.dlg.year = original.start_date.getFullYear();
    this.modal.show({
      title: "Update mileage",
      cancelBtn: "Cancel",
      okBtn: "Update",
      template: this.updateMileageDialog,
      okBtnDisabled: () => Util.compareProperties(this.dlg.mileage,original)
    }).pipe(
      switchMap(
        res => {
          this.working = 1;
          return res?
            this.vanpoolSvc.updateMileage(this.dlg.mileage)
            : of(0)
        })
    ).subscribe(
      res => {
        if (!res)
          return;
        this.working = 0;
        this.datatable.resetTable();
      },
      e => { this.working = 0; this.modal.error(e).subscribe(); }
    )
  }
}
