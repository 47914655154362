<div class="d-flex flex-column h-100">
  <header>
    <div class="d-flex justify-content-between align-items-end">
      <h2 class="my-1 text-primary">
        <a routerLink="/admin/" title="admin home">
          <i class="fa fa-home fa-lg"></i>
          <i class="fa fa-angle-right fa-lg"></i>
        </a>
        Vanpools
      </h2>
    </div>
    <hr class="mt-0" />
  </header>
  <section class="d-flex flex-column flex-fill">
    <div class="row text-center my-1">
      <div class="col-4">
        <a class="btn btn-success btn-sm text-nowrap col-10" (click)="toggleEqNumber()">
          Equipment #
        </a>
      </div>
      <div class="col-4">
        <a class="btn btn-warning btn-sm text-nowrap col-10" routerLink="/admin/vanpool-add">
          Add vanpool
        </a>
      </div>
      <div class="col-4">
        <button type="button" class="btn btn-danger btn-sm text-nowrap col-10" onclick="document.getElementsByName('fileinput')[0].click()">
          Bulk update charges
        </button>
        <input type="file" name="fileinput" accept=".xls,.xlsx" (change)="bulkUpload($event)" style="display: none;"/>
      </div>
    </div>
    <hr class="mt-1 mb-0 w-100">
    <div *ngIf="working>1" class="d-flex justify-content-center my-5">
      <uiowa-ring></uiowa-ring>
    </div>
    <shared-datatable #datatable *ngIf="!working"
      [columns]="columns" 
      [getData]="getData" 
      [defaultRow]="defaultRow" 
      [sorts]="sorts"
      [filter]="filter" 
      [filterChoices]="filterChoices" 
      (activate)="onSelect($event)" 
      class="bootstrap"
      style="height:100%">
    </shared-datatable>
  </section>
</div>