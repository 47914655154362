import { Component, OnInit, ViewChild } from '@angular/core';
import { TableColumn, SortPropDir } from '@siemens/ngx-datatable';
import { DatatableComponent } from 'src/app/shared/components/datatable/datatable.component';
import { Router } from '@angular/router';
import { DatasetCommand } from 'src/app/core/models/dataset';
import { PayrollService } from 'src/app/core/services/payroll.service';
import { ModalService } from 'src/app/shared';
import { PayrollDeduction } from 'src/app/core/models/payroll-deduction';
import { CurrencyPipe } from '@angular/common';
@Component({
  selector: 'admin-payrollDeduct',
  templateUrl: './payroll-deduct.component.html',
  styleUrls: ['./payroll-deduct.component.css'],
})
export class PayrollDeductComponent implements OnInit {
  constructor(private payrollSvc: PayrollService, private modal: ModalService, private router: Router) {}
  cutOffdate: Date;
  columns: TableColumn[];
  defaultRow = new PayrollDeduction();
  sorts: SortPropDir[] = [];
  filter: { [key: string]: string } = {};
  filterChoices: { [key: string]: string[] };
  canRunJob = false;
  ngOnInit(): void {
    this.payrollSvc.getCutOffDate().subscribe(
      (d) => (this.cutOffdate = d),
      (e) => this.modal.error(e)
    );

    this.columns = [
      { name: 'Univ Id', prop: 'univId', headerTemplate: 'textSearch' },
      { name: 'Deduction Code', prop: 'deductionCode', headerTemplate: 'textSearch' },
      { name: 'Ded Description', prop: 'dedDescription', canAutoResize: true, headerTemplate: 'textSearch' },
      { name: 'Effective Date', prop: 'effectiveDate', headerTemplate: 'dateSearch', cellTemplate: 'dateColumn' },
      { name: 'End Date', prop: 'endDate', headerTemplate: 'dateSearch', cellTemplate: 'dateColumn' },
      { name: 'Rate', prop: 'rate', headerTemplate: 'textSearch', pipe: new CurrencyPipe('en-US') },
      { name: 'DeductAmount', prop: 'deductAmount', headerTemplate: 'textSearch', pipe: new CurrencyPipe('en-US') },
      { name: 'VanpoolName', prop: 'vanpoolName', headerTemplate: 'textSearch' },
      { name: 'MemberStartDate', prop: 'memberStartDate', headerTemplate: 'dateSearch', cellTemplate: 'dateColumn' },
      { name: 'MemberEndDate', prop: 'memberEndDate', headerTemplate: 'dateSearch', cellTemplate: 'dateColumn' },
      { name: 'FirstName', prop: 'firstName', headerTemplate: 'textSearch' },
      { name: 'LastName', prop: 'lastName', headerTemplate: 'textSearch' },
      { name: 'DeductionReason', prop: 'deductionReason', headerTemplate: 'textSearch' },
      { name: 'Code', prop: 'code', headerTemplate: 'textSearch' },
    ];
    this.filterChoices = {};
    this.payrollSvc.canRunJob().subscribe((can) => {
      this.canRunJob = can;
    });
  }

  getAllData = () => this.payrollSvc.getPreview();
  downloadCsv() {
    this.payrollSvc.downloadFile();
  }
}
