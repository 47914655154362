<div class="h-100 w-100 bg-light" style="overflow-y: auto;">
  <div class="container">
    <section class="my-4 bg-white shadow">
      <div class="bg-success py-2 px-3">
        <p class="h4 text-white m-0">
          <i class="fa fa-hand fa-lg"></i>
          Sub-rider Details
        </p>
      </div>
      <div class="p-3" *ngIf="srdr">
        <div class="w-100">
          <div class="row pl-2">
            <div class="card-field">
              <span class="card-field-label">Vanpool</span>
              <span class="card-field-value">{{this.srdr.vpname}}</span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Work Hours</span>
              <span class="card-field-value">{{this.srdr.hours}}</span>
            </div>
            <div class="card-field">
              <span class="card-field-label">University ID</span>
              <span class="card-field-value">{{this.srdr.univId}}</span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Name</span>
              <span class="card-field-value"><a routerLink="/admin/people" [queryParams]="{uid:srdr.univId}">
                  {{this.srdr.last_name}}, {{this.srdr.first_name}}</a></span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Status</span>
              <span class="card-field-value">{{this.srdr.status}}</span>
            </div>
            <div class="card-field" *ngIf="srdr.position">
              <span class="card-field-label">Position</span>
              <span class="card-field-value">{{this.srdr.position}}</span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Start date</span>
              <span class="card-field-value">{{this.dateString(this.srdr.start_date)}}</span>
            </div>
            <div class="card-field" *ngIf="this.srdr.end_date">
              <span class="card-field-label">End date</span>
              <span class="card-field-value">{{this.dateString(this.srdr.end_date)}}</span>
            </div>
            <div class="card-field-long">
              <span class="card-field-label">Comment:</span>
              <input type="text" [(ngModel)]="comment" class="form-control" placeholder="comment" />
            </div>
          </div>
        </div>

        <div class="row text-center my-1">
          <div class="col-4">
            <a class="btn btn-danger btn-sm text-nowrap col-10" (click)="end()"
              *ngIf="!this.srdr.end_date">
              End
            </a>
          </div>
          <div class="col-4">
          </div>
          <div class="col-4">
            <button type="button" class="btn btn-warning btn-sm text-nowrap col-10" (click)="saveComment()"
              *ngIf="comment != srdr.comment">
              Save
            </button>
          </div>
        </div>
      </div>
    </section>
    <section *ngIf="loading" class="d-flex justify-content-center my-5">
      <uiowa-ring></uiowa-ring>
    </section>
  </div>
</div>

<ng-template #endDialog>
  <label for="end_date" class="col-6">End date:</label>
  <input name="end_date" type="text" ngbDatepicker #end_date="ngbDatepicker"
    [(ngModel)]="dlg.end_date" class="col-6" (click)="end_date.toggle()"
    [minDate]="dlg.min_date">
</ng-template>

