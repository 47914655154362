import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PdriversRoutingModule } from './pdrivers-routing.module';
import { PdriversComponent } from './pdrivers/pdrivers.component';
import { SharedModule } from 'src/app/shared';
import { MembersService, Util } from 'src/app/core';


@NgModule({
  declarations: [PdriversComponent],
  imports: [
    CommonModule,
    SharedModule,
    PdriversRoutingModule
  ],
  providers:[
    MembersService,
    Util
  ]
})
export class PdriversModule { }
