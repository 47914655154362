import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubriderRqDtlRoutingModule } from './subrider-rq-dtl-routing.module';
import { SubriderRqDtlComponent } from './subrider-rq-dtl/subrider-rq-dtl.component';
import { FormsModule } from '@angular/forms';
import { SpinnerModule } from '@uiowa/spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/shared';
import { WaitlistService } from 'src/app/core';


@NgModule({
  declarations: [SubriderRqDtlComponent],
  imports: [
    CommonModule,
    FormsModule,
    SpinnerModule,
    NgbModule,
    SubriderRqDtlRoutingModule
  ],
  providers:[
    ModalService,
    WaitlistService
  ]
})
export class SubriderRqDtlModule { }
