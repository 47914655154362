<div class="d-flex flex-fill text-white p-3 shadow" [ngClass]="type">
  <div class="d-flex justify-content-between w-100">
    <div class="">
      <p class="m-0">
        <fa-icon [icon]="icon" size="3x" aria-hidden="true"></fa-icon>
      </p>
      <p class="title-text m-0">{{ title }}</p>
    </div>
    <div class="text-right">
      <p class="significant">{{ number }}</p>
      <a [routerLink]="path" [queryParams]="query"  queryParamsHandling="merge" class="text-white text-nowrap">View Details</a>
    </div>
  </div>
</div>
