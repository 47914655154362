import { Component, OnInit, OnDestroy, isDevMode, AfterViewInit } from '@angular/core';
import { InternalRoute, ExternalLink, HeaderUser } from '@uiowa/uiowa-header';
import { ApplicationUser, UserService, ImpersonationService, ConfigService } from './core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  applicationName = 'My Vanpool';
  externalLinks = [
    new ExternalLink('Employee Self Service', 'https://hris.uiowa.edu/portal18')
  ];
  internalRoutes: InternalRoute[];
  user: HeaderUser;

  startTimer: boolean;
  private userSubscription: Subscription;

  constructor(
    private readonly ngbDatepickerConfig: NgbDatepickerConfig,
    private readonly userService: UserService,
    private readonly impersonationService: ImpersonationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cfgSvc: ConfigService,
    private titleService: Title
  ) {
  }

  ngOnInit(): void {
    this.ngbDatepickerConfig.firstDayOfWeek = 7;
    this.ngbDatepickerConfig.outsideDays = 'collapsed';
    this.userSubscription = this.userService.user$.subscribe(user => {
      this.user = <HeaderUser>{
        userName: user.hawkId,
        originalUserName: user.originalUser
      };
      this.setupNavMenus(user);
      this.startTimer = !isDevMode() &&  user.isAuthenticated();
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      const title = this.getChildTitle("My Vanpool", this.activatedRoute);
      this.titleService.setTitle(title);
    });  

  }

  getChildTitle(parentTitle:string, activatedRoute: ActivatedRoute) {
    let title = activatedRoute.snapshot.data.title;
    if (title === undefined) {
      let segments = activatedRoute.snapshot.url.reverse();
      if (segments&&segments.length)
        title = segments[0].path;
    } else if (typeof title == "function")
      title = title(activatedRoute);
    if (title)
      parentTitle+=":"+title;
    if (activatedRoute.firstChild) {
      return this.getChildTitle(parentTitle,activatedRoute.firstChild);
    } else {
      return parentTitle;
    }

  }  
  // $$Implements SW.EMP.MAIN_MENU
  setupNavMenus(user: ApplicationUser) {
    this.internalRoutes = [];
    this.internalRoutes.push(new InternalRoute('Home', 'home'));
    if (user.isEmployee()) {
      this.internalRoutes = [
        ...this.internalRoutes,
        new InternalRoute('History', 'mywlhistory'),
        new InternalRoute('Join', 'joinwl'),
        new InternalRoute('FAQ', '/faq')//this.cfgSvc.current.faq_url)
      ];
    }
    if (user.isAdmin()) {
      this.internalRoutes = [
        ...this.internalRoutes,
        new InternalRoute('Employee Home', 'employee'),
        new InternalRoute('Impersonation', 'impersonation')
      ];
    }
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  stopImpersonate() {
    this.impersonationService.stopImpersonate();
  }
}
