<div class="d-flex flex-row align-items-stretch h-100 justify-content-center">
<div class="d-flex flex-column align-items-stretch w-100">
<ngx-datatable
  #datatable
  [rows]="rows" 
  [columns]="_columns"
  [count]="total"
  [offset]="idx"
  [sorts]="sorts"
  [columnMode]="columnMode"
  [loadingIndicator]="isLoading>0"
  [externalPaging]="serverSide"
  [externalSorting]="serverSide"
  [limit]="limit"
  [virtualization]="!limit"
  [headerHeight]="headerHeight"
  [footerHeight]="footerHeight"
  [scrollbarV]="scrollbarV"
  [scrollbarH]="scrollbarH"
  [selectionType]="selectionType"
  [selected]="selected"
  [displayCheck]="displayCheck"
  [rowClass]="rowClass"
  [messages]="{emptyMessage:'No data',totalMessage:'total'}"
  (activate)="onActivate($event)"
  (select)="onSelect($event)"
  (page)="setPage($event)"
  (sort)="onSort($event)"
  class="bootstrap shared-datatable"
  style="flex-grow:1"
  row-height="50"
>
  <ngx-datatable-row-detail [template]="rowDetailTemplate" [rowHeight]="rowDetailHeight">
  </ngx-datatable-row-detail>
</ngx-datatable>
</div>
</div>

<ng-template #textSearch let-column="column">
  <span  class="datatable-header-cell-wrapper" (click)="onHeaderClick($event)">
    <div class="input-group">
      <input name="{{column.prop}}"type="text" (change)="onSearch($event)" style="width: 95%;">
      <button *ngIf="filter[column.prop]" class="btn bg-transparent p-0" style="margin-left: -16px; z-index: 100;" (click)="onClearFilter($event)">
        <i class="fa fa-times fa-small"></i>
      </button>
    </div>
  {{column.name}}
  </span>
</ng-template>

<ng-template #selectSearch let-column="column">
  <div class="datatable-header-cell-wrapper" style="display: inline-block;" (click)="onHeaderClick($event)">
    <select name="{{column.prop}}" (change)="onSearch($event)"
      style="width: 95%;">
      <option value=""></option>
      <option *ngFor="let o of filterChoices[column.prop]; let i=index;" value="={{o[0]}}">
          {{o[1]}}
      </option>
    </select>
    <br>
    {{column.name}}
  </div>
</ng-template>

<ng-template #dateSearch let-column="column">
  <div class="datatable-header-cell-wrapper" style="display: inline-block;" (click)="onHeaderClick($event)">
    <div class="input-group">
      <input 
        name="{{column.prop}}" 
        type="text" ngbDatepicker 
        #d="ngbDatepicker" 
        [container]="'body'" 
        (change)="onSearch($event)" 
        style="max-width: 8em;" 
        (click)="d.toggle()" 
        (dateSelect)="onDateSelect(column.prop,$event)"
      >
      <button 
        *ngIf="filter[column.prop]" 
        class="btn bg-transparent p-0" 
        style="margin-left: -16px; z-index: 100;" 
        (click)="onClearFilter($event)">
        <i class="fa fa-times fa-small"></i>
      </button>
    </div>
    {{column.name}}
  </div>
</ng-template>

<ng-template #dateRangeSearch let-column="column">
  <div class="datatable-header-cell-wrapper" style="display: inline-block;" (click)="onHeaderClick($event)">
    <shared-date-range [range]="ranges[column.prop]" [name]="column.prop" (onSelect)="onSearch($event)" (onClear)="onClearFilter($event)"></shared-date-range>
    {{column.name}}
  </div>
</ng-template>

<ng-template #boolSearch let-column="column">
  <div class="datatable-header-cell-wrapper" style="display: inline-block;" (click)="onHeaderClick($event)">
    <select name="{{column.prop}}" (change)="onSearch($event)" style="width: 95%;">
      <option value=""></option>
      <option value="true">Yes</option>
      <option value="false">No</option>
    </select>
    <br>
    {{column.name}}
  </div>
</ng-template>

<ng-template #dateColumn let-value="value" >
  {{ value&&dateFormat.format(value) }}
</ng-template>

<ng-template #dateTimeColumn let-value="value" >
  {{ value&&dateTimeFormat.format(value) }}
</ng-template>

<ng-template #boolColumn let-value="value" >
  {{ value?"Yes":"No" }}
</ng-template>

<ng-template #arrayLengthColumn let-value="value" >
  {{ value?.length??0||"-" }}
</ng-template>

<ng-template #filterCell let-value="value">
  <i class="fa fa-filter fa-lg hover"></i>{{value}}
</ng-template>

<ng-template #viewCellTemplate>
  <i class="fa fa-solid fa-magnifying-glass fa-lg cursor-pointer"></i>
</ng-template>