<div class="container">
  <section class="my-4 bg-white shadow">
    <div class="bg-success py-2 px-3">
      <p class="h4 text-white m-0">
        <i class="fa fa-hand fa-lg"></i>
        Payroll deduct job
      </p>
    </div>
    <div class="p-3">
      <div class="w-100">
        <div class="row pl-2">
          <div class="card-field">
            <span class="card-field-label"> year </span>
            <input type="number" class="card-field-value form-control" placeholder="year" [(ngModel)]="year" />
          </div>
          <div class="card-field">
            <span class="card-field-label"> month</span>
            <span class="card-field-value mt-1">
              <input type="number" min="1" max="12" class="card-field-value form-control" placeholder="month"
                [(ngModel)]="month" />
            </span>
          </div>
        </div>
      </div>
      <br />
      <div class="row text-center my-1">
        <div class="col-4">
          <button class="btn btn-danger btn-sm text-nowrap col-10" (click)="Run()" title="Run">
            Run Payroll Deduct
          </button>
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="loading" class="d-flex justify-content-center my-5">
    <uiowa-ring></uiowa-ring>
  </section>
</div>