import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { SortDirection, SortPropDir, TableColumn } from '@siemens/ngx-datatable';
import { of } from 'rxjs';
import { flatMap, switchMap } from 'rxjs/operators';
import { CurrencyPipe } from '@angular/common';
import { PayrollService } from 'src/app/core/services/payroll.service';
import { ModalService } from 'src/app/shared';

@Component({
  selector: 'payroll-deduct-run',
  templateUrl: './payroll-deduct-run.component.html',
  styleUrls: ['./payroll-deduct-run.component.scss'],
})
export class PayrollDeductRunComponent implements OnInit {
  loading = false;
  year = new Date().getFullYear();
  month = 1;
  cutoffDate: Date;
  cutoffDates: Date[] = [];

  constructor(private payrollSvc: PayrollService, private modal: ModalService, private router: Router) {}

  ngOnInit(): void {}

  Run() {
    this.payrollSvc.RunPayrollDeduct(this.year, this.month).subscribe(
      (d) => this.modal.notify('', 'The job ran successfully').subscribe(),
      (e) => this.modal.error(e).subscribe()
    );
  }
}
