import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WlRqDtlRoutingModule } from './wl-rq-dtl-routing.module';
import { WlRqDtlComponent } from './wl-rq-dtl/wl-rq-dtl.component';
import { SpinnerModule } from '@uiowa/spinner';
import { ModalService } from 'src/app/shared';
import { FormsModule } from '@angular/forms';
import { WaitlistService, vanpoolService } from 'src/app/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [WlRqDtlComponent],
  imports: [
    CommonModule,
    FormsModule,
    SpinnerModule,
    NgbModule,
    WlRqDtlRoutingModule
  ],
  providers:[
    ModalService,
    WaitlistService,
    vanpoolService
  ]
})
export class WlRqDtlModule { }
