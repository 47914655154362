export class Vanpool {
  vpoolid: number = 0;
  vpname: string = "";
  cmnty: string ="";
  status: string = "";
  eqno: string = "";
  plateno: string="";
  hours: string = "";
  workdays: string = "";
  dcap: number = 0;
  mmbr_count: number = 0;
  vcnt_count: number = 0;
  offer_count: number = 0;
  wlist_count: number = 0;
  mmbr_rate: number = 0;
  accept_subriders: boolean = false;
  srdr_rate?: number = 0;
  ttl_chrg: number = 0;
  start_date?: Date = new Date(0);
  end_date: Date = new Date(0);
  end_reason?: string = "";
  vhcl_asgn_cmnt?: string="";
  comment?: string = "";
}