import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehicleHistoryRoutingModule } from './vehicle-history-routing.module';
import { VehicleHistoryComponent } from './vehicle-history/vehicle-history.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerModule } from '@uiowa/spinner';
import { VehicleService } from 'src/app/core';
import { SharedModule } from 'src/app/shared';

@NgModule({
  declarations: [VehicleHistoryComponent],
  imports: [CommonModule, FormsModule, SpinnerModule, NgbModule, VehicleHistoryRoutingModule, SharedModule],
  providers: [VehicleService],
})
export class VehicleHistoryModule {}
