<div class="h-100 w-100 bg-light" style="overflow-y: auto;">
  <header>
    <div class="d-flex justify-content-between align-items-end">
      <h2 class="my-1 text-primary">
        <a routerLink="/admin/" title="admin home">
          <i class="fa fa-home fa-lg"></i>
          <i class="fa fa-angle-right fa-lg"></i>
        </a>
        Member
      </h2>
    </div>
    <hr class="mt-0" />
  </header>
  <!-- $$Implements SW.ADM.MEMB.PG_DTL -->
  <div class="container">
    <section class="my-4 bg-white shadow">
      <div class="bg-success py-2 px-3">
        <p class="h4 text-white m-0">
          <i class="fa fa-hand fa-lg"></i>
          Membership Details
        </p>
      </div>
      <div class="p-3" *ngIf="member">
        <div class="w-100">
          <div class="row pl-2">
            <div class="card-field">
              <span class="card-field-label">Name</span>
              <span class="card-field-value">
                <a routerLink="/admin/people" [queryParams]="{uid:this.member.univId}">
                  {{this.member.last_name}}, {{this.member.first_name}}
                </a>
              </span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Vanpool</span>
              <a class="card-field-value" routerLink="/admin/vanpool-dtl/{{this.member.vpoolid}}">{{this.member.vpname}} </a>
            </div>
            <div class="card-field">
              <span class="card-field-label">Member type</span>
              <span class="card-field-value mt-1">
                <select [disabled]="!!member.end_date" [(ngModel)]="type">
                  <option *ngFor="let type of memb_types" [selected]="type==member.type" [disabled]="isTypeDisabled(type)">
                    {{type}}
                  </option>
                </select>
              </span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Work Hours</span>
              <span class="card-field-value">{{this.member.hours}}</span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Start Date</span>
              <span class="card-field-value">{{this.dateTimeFormat.format(this.member.start_date)}}
                <button type="button" class="btn btn-warning btn-xs text-nowrap" (click)="changeFutureDate()"
                  *ngIf="isFutureMember()">
                  Change
                </button>

              </span>
            </div>
            <div class="card-field" *ngIf="member.end_date">
              <span class="card-field-label">End Date</span>
              <span class="card-field-value">{{this.dateTimeFormat.format(this.member.end_date)}}
                <a *ngIf="!member.has_future_members && isCurrrentMember()" class="btn btn-danger btn-xs" (click)="removeEndDate()">Remove</a>
              </span>
            </div>
            <div class="card-field">
              <span class="card-field-label">Payment type</span>
              <span class="card-field-value mt-1">
                <select [disabled]="!!member.end_date" [(ngModel)]="pmnt_type">
                  <option *ngFor="let type of pmnt_types" value="{{type}}">
                    {{type}}
                  </option>
                </select>
              </span>
            </div>
            <div class="card-field" *ngIf="member.end_reason">
              <span class="card-field-label">Membership end reason</span>
              <span class="card-field-value">{{this.member.end_reason}}</span>
            </div>
            <div class="card-field-long">
              <span class="card-field-label" *ngIf="member.drq_status == 'Pending'">Driver agreement accepted on {{this.dateTimeFormat.format(this.member.drq_date)}}</span>
              <span class="card-field-label" *ngIf="member.bdrq_status == 'Pending'">Backup driver agreement accepted on {{this.dateTimeFormat.format(this.member.bdrq_date)}}</span>
            </div>
            <div class="card-field-long">
              <span class="card-field-label">Comment:</span>
              <input type="text" [(ngModel)]="comment" class="form-control" placeholder="comment" />
            </div>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-6 col-lg-3 mt-1" *ngIf="member.swap_start_date">
            <a class="btn btn-success btn-sm text-nowrap col-10" (click)="swapDrivers()">
              Swap with <span *ngIf="member.type=='Driver'">primary backup</span><span *ngIf="member.type!='Driver'">driver</span>
            </a>
          </div>
          <div class="col-6 col-lg-3 mt-1" *ngIf="!member.end_date && member.type != 'Driver' && !(member.drq_status && member.drq_status!='Processed')">
            <a class="btn btn-success btn-sm text-nowrap col-10" (click)="provideDriverForm()">
              Provide driver form
            </a>
          </div>
          <div class="col-6 col-lg-3 mt-1" *ngIf="!member.end_date && !member.type.endsWith('Backup Driver') && !(member.bdrq_status && member.bdrq_status!='Processed')">
            <a class="btn btn-success btn-sm text-nowrap col-10" (click)="provideBackupForm()">
              Provide backup driver form
            </a>
          </div>
          <div class="col-6 col-lg-3 mt-1" *ngIf="!member.end_date">
            <a class="btn btn-danger btn-sm text-nowrap col-10" (click)="endMembership()">
              End membership
            </a>
          </div>
          <div class="col-6 col-lg-3 mt-1" *ngIf="comment != member.comment || type != member.type || member.payment != pmnt_type">
            <button type="button" class="btn btn-warning btn-sm text-nowrap col-10" (click)="saveChanges()">
              Update
            </button>
          </div>
        </div>
      </div>
    </section>
    <section *ngIf="loading" class="d-flex justify-content-center my-5">
      <uiowa-ring></uiowa-ring>
    </section>
  </div>
</div>

<ng-template #changePmntDialog>
  <div>Change payment type from '{{this.member.payment}}' to '{{this.pmnt_type}}'?</div>
  <label for="effictive_date">Effective date:</label>
  <input name="effictive_date" type="text" ngbDatepicker #effictive_date="ngbDatepicker" [(ngModel)]="dlg.date"
    (click)="effictive_date.toggle()" [minDate]="dlg.min_date">
  <br>
  <label for="comment">Comment:</label>
  <input name="comment" type="text" [(ngModel)]="dlg.comment">
</ng-template>

<ng-template #changeTypeDialog>
  <div>Change membership type of {{this.member.first_name}} {{this.member.last_name}} to {{this.type}}?</div>

  <label for="new_payment_type">Payment type:</label>
  <select name="new_payment_type" [(ngModel)]="dlg.select">
    <option *ngFor="let op of pmnt_types">
      {{op}}
    </option>
  </select>
  <br>
  <label for="effictive_date">Effective date:</label>
  <input name="effictive_date" type="text" ngbDatepicker #effictive_date="ngbDatepicker" [(ngModel)]="dlg.date" (click)="effictive_date.toggle()" [minDate]="dlg.min_date" [maxDate]="dlg.max_date">
  <br>
  <label for="comment">Comment:</label>
  <input name="comment" type="text" [(ngModel)] = "dlg.comment">
</ng-template>

<ng-template #swapDialog>
  <div>Swap driver with primary backup driver?</div>

  <label for="effictive_date">Effective date:</label>
  <input name="effictive_date" type="text" ngbDatepicker #effictive_date="ngbDatepicker" [(ngModel)]="dlg.date" (click)="effictive_date.toggle()" [minDate]="dlg.min_date" [maxDate]="dlg.max_date">
  <br>
  <label for="comment">Comment:</label>
  <input name="comment" type="text" [(ngModel)] = "dlg.comment">
</ng-template>

<ng-template #endMemberDialog>
  <div>End membership for {{this.member.first_name}} {{this.member.last_name}}?</div>

  <label for="end_date">End date:</label>
  <input name="end_date" type="text" ngbDatepicker #end_date="ngbDatepicker"
    [(ngModel)]="dlg.date" (click)="end_date.toggle()"
    [minDate]="dlg.min_date" [markDisabled]="isDisabledDate">
  <br>

  <label for="new_payment_type">Reason:</label>
  <select name="new_payment_type" [(ngModel)]="dlg.select">
    <option *ngFor="let op of reasons">
      {{op}}
    </option>
  </select>
  <br>
  <label for="comment">Comment:</label>
  <input name="comment" type="text" [(ngModel)]="dlg.comment">
</ng-template>

<!-- $$Implements SW.ADM.MEMB.PG_DTL_DAT_CHG -->
<ng-template #changeDateDialog>
  <div>Change start date of future memebership?</div>

  <label for="start_date">Start date:</label>
  <input name="start_date" type="text" ngbDatepicker #start_date="ngbDatepicker" [(ngModel)]="dlg.date"
    (click)="start_date.toggle()" [minDate]="dlg.min_date" [markDisabled]="isDisabledDate">
</ng-template>