import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MemberRecord } from 'src/app/core/models/member-record';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MembersService, ConfigService, Util } from 'src/app/core';
import { ModalService } from 'src/app/shared';
import { flatMap, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { PayrollService } from 'src/app/core/services/payroll.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'admin-memb-dtl',
  templateUrl: './memb-dtl.component.html',
  styleUrls: ['./memb-dtl.component.css']
})
export class MembDtlComponent implements OnInit {
  @ViewChild("changeTypeDialog") changeTypeDialog: TemplateRef<any>;
  @ViewChild("swapDialog") swapDialog: TemplateRef<any>;
  @ViewChild("changePmntDialog") changePmntDialog: TemplateRef<any>;
  @ViewChild("endMemberDialog") endMemberDialog: TemplateRef<any>;
  @ViewChild("changeDateDialog") changeDateDialog: TemplateRef<any>;
  
  dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'numeric', day: '2-digit' })
  loading = false;
  member: MemberRecord;
  type: string;
  pmnt_type: string;
  comment: string;

  memb_types:string[];
  pmnt_types: string[];
  reasons: string[];

  constructor(
    private membSvc: MembersService,
    private cfgSvc: ConfigService,
    private payrollSvc: PayrollService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private toastrService:ToastrService,
    private modal: ModalService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.memb_types = this.cfgSvc.current.memb_types;
    this.pmnt_types = this.cfgSvc.current.pmnt_type;
    this.reasons = this.cfgSvc.current.memb_end_reasons.filter(r => 
        r != "Driver to Rider" && 
        r != "Rider to Driver" && 
        r != "Driver to Backup Driver" && 
        r != "Backup Driver to Driver" && 
        r != "Backup Driver to Rider" && 
        r != "Rider to Backup Driver" && 
        r != "Changed backup driver type"
      );
    this.activatedRoute.params.subscribe(p => this.loadData(p["id"]));
  }

  loadData(id?:number) {
    id = id||this.member.id;
    this.membSvc.getMemberDetails(id).subscribe(
      memb=>{
        this.member = memb;
        this.comment = this.member.comment = memb.comment||"";
        this.type = memb.type;
        this.pmnt_type = memb.payment;
        this.loading = false;
        let title = this.titleService.getTitle().split(":").reverse();
        title[0]=`${memb.first_name} ${memb.last_name}`;
        this.titleService.setTitle(title.reverse().join(":"));
      },
      e => this.modal.error(e).subscribe(() => this.loading = false)
    )
  }

  isTypeDisabled(type:string):boolean {
    let result = false;
    if (type == "Driver")
      result= !(this.member.type == "Driver" || this.member.drq_status && this.member.drq_status != "Initialized" && this.member.driver_end_date);
    else if (type == "Primary Backup Driver" || type == "Secondary Backup Driver") {
      result = !(
        this.member.type.endsWith("Backup Driver") && this.member.type != type
        || this.member.bdrq_status && this.member.bdrq_status != "Initialized");
      if (type == "Primary Backup Driver")
        result = result || !this.member.bdriver_end_date;
    }
    return result;
  }

  provideDriverForm() {
    this.modal.show({
      title: "Confirm Access",
      message: `Provide ${this.member.first_name} ${this.member.last_name} with access to the Driver Agreement Form?`,
      cancelBtn: "Cancel"
    }).pipe(flatMap(res => {
        this.loading = res;
        return res ?
          this.membSvc.provideDriverForm(this.member.userId)
          : of(false)
      }
    )).subscribe(
      res=>res&&this.loadData(),
      e => this.modal.error(e).subscribe(() => this.loading = false)
    );
  }

  provideBackupForm() {
    this.modal.show({
      title: "Confirm Access",
      message: `Provide ${this.member.first_name} ${this.member.last_name} with access to the Backup Driver Agreement Form?`,
      cancelBtn: "Cancel"
    }).pipe(flatMap(res => {
      this.loading = res;
      return res ?
        this.membSvc.provideBackupDriverForm(this.member.id)
        : of(false)
    }
    )).subscribe(
      res => res && this.loadData(),
      e => this.modal.error(e).subscribe(() => this.loading = false)
    );
  }

  
  saveChanges() {
    if (this.type != this.member.type)
      this.changeType();
    else if (this.pmnt_type != this.member.payment)
      this.changePmntType();
    else if (this.comment != this.member.comment)
      this.saveComment();
  }

  dlg: {
    select?: string,
    date: NgbDateStruct,
    min_date: NgbDateStruct,
    max_date?: NgbDateStruct,
    comment: string
  }

  endMembership() {
    let today = Util.today;
    // $$Implements  SW.ADM.MEMB.PG_DTL_END
    this.payrollSvc.getLastDate()
    .pipe(
      switchMap(date=>{
        if (!date) {
          this.toastrService.error(
            "Can not find last payroll deduct date, the end date will be limited to future", 
            "Payroll date unknown", 
            {
              closeButton: true,
              timeOut: 5000,
              onActivateTick: true
            }
          );
          date = today;
        }
        this.dlg = {
          select: this.reasons[0],
          date: Util.toNgbDate(Util.today),
          min_date: Util.toNgbDate(date),
          comment: this.comment
        };
        return this.modal.show({
            title: "Confirm change",
            cancelBtn: "Cancel",
            template: this.endMemberDialog
          })
      }),
      flatMap(res => {
        this.loading = res;
        // $$Implements SW.ADM.MEMB.PG_DTL_END_SBMT
        return res ?
          this.membSvc.endMembership(
            this.member.id,
            Util.fromNgbDate(this.dlg.date),
            this.dlg.select,
            this.dlg.comment
          )
          : of(false)
      })
    ).subscribe(
      res => res && this.loadData(),
      e => this.modal.error(e).subscribe(() => this.loading = false)
    )
  }
  
  changeType() {
    let min_date = Util.today;
    if (this.type == "Driver" && this.member.driver_end_date)
      min_date = Util.addBDays(1,this.member.driver_end_date);
    else if (this.type == "Primary Backup Driver")
      min_date = Util.addBDays(1, this.member.bdriver_end_date);
    min_date = new Date(Math.max(+min_date, +Util.addBDays(0), +this.member.start_date));
    this.dlg = {
      select: (this.type == 'Driver') ? 'Waive' : (this.pmnt_type=='Waive')? this.pmnt_types.filter(t=>t.toLocaleLowerCase().startsWith("payrol"))[0]:this.pmnt_type,
      date: Util.toNgbDate(min_date),
      min_date: Util.toNgbDate(min_date),
      comment:(this.comment != this.member.comment)?this.comment:""
    };
    if (this.type.endsWith('Driver') && this.member.member_till_date)
      this.dlg.max_date = Util.toNgbDate(this.member.member_till_date);
    this.modal.show({
      title:"Change Memberhip Type",
      cancelBtn:"Cancel",
      template: this.changeTypeDialog
    }).pipe(
      flatMap(res => {
        this.loading = res;
        return res?
          this.membSvc.changeType(
            this.member.userId,
            this.type,
            this.dlg.select,
            Util.fromNgbDate(this.dlg.date),
            this.dlg.comment
          )
          :of(0)
      })
    ).subscribe(
      res => res && this.router.navigate(['admin', 'memb-dtl', res]),
      e => this.loading = !this.modal.error(e).subscribe()
    )
  }

  swapDrivers() {
    let min_date = new Date(Math.max(+Util.addBDays(0), +this.member.swap_start_date));
    this.dlg = {
      select: (this.type == 'Driver') ? 'Waive' : (this.pmnt_type=='Waive')? this.pmnt_types.filter(t=>t.toLocaleLowerCase().startsWith("payrol"))[0]:this.pmnt_type,
      date: Util.toNgbDate(min_date),
      min_date: Util.toNgbDate(min_date),
      comment:(this.comment != this.member.comment)?this.comment:""
    };
    if (this.member.swap_end_date)
      this.dlg.max_date = Util.toNgbDate(this.member.swap_end_date);
    this.modal.show({
      title:"Swap drivers",
      cancelBtn:"Cancel",
      template: this.swapDialog
    }).pipe(
      flatMap(res => {
        this.loading = res;
        return res?
          this.membSvc.swapDrivers(
            this.member.id,
            Util.fromNgbDate(this.dlg.date),
            this.dlg.comment
          )
          :of(0)
      })
    ).subscribe(
      res => res && this.router.navigate(['admin', 'memb-dtl', res]),
      e => this.loading = !this.modal.error(e).subscribe()
    )
  }

  changePmntType() {
    let min_date = new Date(Math.max(+Util.addBDays(0), +Util.addBDays(1,this.member.start_date)));
    this.dlg = {
      date: Util.toNgbDate(min_date),
      min_date: Util.toNgbDate(min_date),
      comment: (this.comment != this.member.comment)?this.comment:""
    };
    this.modal.show({
      title: "Change Memberhip Type",
      cancelBtn: "Cancel",
      template: this.changePmntDialog
    }).pipe(
      flatMap(res => {
        this.loading = res;
        return res ?
          this.membSvc.changePmntType(
            this.member.id,
            this.pmnt_type,
            Util.fromNgbDate(this.dlg.date),
            this.dlg.comment
          )
          : of(0)
      })
    ).subscribe(
      res => res && this.router.navigate(['admin', 'memb-dtl', res]),
      e => this.loading = !this.modal.error(e).subscribe()
    )
  }

  saveComment() {
    this.membSvc.saveComment(this.member.id, this.comment).subscribe(
      res => res && this.loadData(),
      e => this.loading = !this.modal.error(e).subscribe()
    )
  }

  isCurrrentMember():boolean {
    let today = +Util.today;
    return this.member && (+this.member.start_date <= today) && (!this.member.end_date || +this.member.end_date > today)
  }

  isFutureMember():boolean {
    return this.member&&(+this.member.start_date >= +Util.addDays(1));
  }

  changeFutureDate() {
    let min_date = Util.addBDays(1,this.member.start_date);
    this.dlg = {
      date: Util.toNgbDate(min_date),
      min_date: Util.toNgbDate(min_date),
      comment: ""
    };
    // $$Implements SW.ADM.MEMB.PG_DTL_DAT_CHG
    this.modal.show({
      title: "Change Start Date",
      cancelBtn: "Cancel",
      template: this.changeDateDialog
    }).pipe(
      flatMap(res => {
        this.loading = res;
        return res ?
          // $$Implements SW.ADM.MEMB.PG_DTL_DAT_CHG_SBMT
          this.membSvc.changeFutureDate(
            this.member.id,
            Util.fromNgbDate(this.dlg.date)
          )
          : of(0)
      })
    ).subscribe(
      res => res && this.loadData(),
      e => this.loading = !this.modal.error(e).subscribe()
    )
  }

  removeEndDate() {
    // $$Implements SW.ADM.MEMB.PG_DTL_RM_END_DATE
    this.modal.show({
      title: "Remove End Date",
      cancelBtn: "Cancel",
      message: "Remove End Date of this membership"
    }).pipe(
      flatMap(res => {
        this.loading = res;
        // $$Implements SW.ADM.MEMB.PG_DTL_RM_END_DATE_SBMT
        return res ?
          this.membSvc.removeEndDate(
            this.member.id
          )
          : of(0)
      })
    ).subscribe(
      res => res && this.loadData(),
      e => this.loading = !this.modal.error(e).subscribe()
    )
  }

  isDisabledDate = (date: NgbDate) => !Util.IsBDay(Util.fromNgbDate(date));
}
