import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehiclesRoutingModule } from './vehicles-routing.module';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { SharedModule } from 'src/app/shared';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService, Util, VehicleService } from 'src/app/core';

@NgModule({
  declarations: [VehiclesComponent],
  imports: [CommonModule, SharedModule, NgbModule, VehiclesRoutingModule],
  providers: [VehicleService, ConfigService, Util],
})
export class VehiclesModule {}
