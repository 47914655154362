export class WlRequest {
  id: number = 0;
  vpoolid: number = 0;
  vpname: string = "";
  hours: string = "";
  univId: string = "";
  userId: number = 0;
  hawkid: string;
  first_name: string = "";
  last_name: string = "";
  status: string = "";
  position: number = 0;
  date: Date = new Date(0);
  offer_date?: Date = new Date(0);
  xpr_date?: Date = new Date(0);
  seat_date?: Date = new Date(0);
  removed_date?: Date = new Date(0);
  decl_rsn: string = "";
  decl_expl: string = "";
  requests: number = 0;
  current_vpool: string = "";
  current_vpool_date?: Date = new Date(0);
  isdriver: boolean = false;
  subrider: boolean = false;
  hasSubriderRq?: boolean;
  accept_subriders?:boolean = false;
  comment: string = "";
  driver?:{
    name:string;
    phone:string;
    email:string;
  }
  rate?:number;
  current_rate?:number;
  modified_by?: string = "";
  modified_date?: Date = new Date(0);
}
