import { Component, OnInit, ViewChild } from '@angular/core';
import { VehicleService } from 'src/app/core';
import { Util } from 'src/app/core';
import { SortDirection, SortPropDir, TableColumn } from '@siemens/ngx-datatable';
import { DatasetCommand } from 'src/app/core/models/dataset';
import { DatatableComponent } from 'src/app/shared/components/datatable/datatable.component';
import { Vehicle } from 'src/app/core/models/vehicle';
import { Router } from '@angular/router';

@Component({
  selector: 'admin-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.css'],
})
export class VehiclesComponent implements OnInit {
  @ViewChild('datatable') datatable: DatatableComponent;

  VehicleService: any;
  constructor(private VehicleSvc: VehicleService, private router: Router) {}
  columns: TableColumn[];
  sorts: SortPropDir[] = [];
  filter: { [key: string]: string } = {};
  filterChoices: { [key: string]: string[] };
  defaultRow = new Vehicle();

  ngOnInit(): void {
    const em = Util.getEM();
    this.columns = [
      // $$Implements SW.ADM.VHCL.PG_ALL
      { name: 'Equipment #', prop: 'eqno', headerTemplate: 'textSearch' },
      { name: 'Type', prop: 'type', headerTemplate: 'textSearch' },
      { name: 'Plate #', prop: 'license_plate', headerTemplate: 'textSearch' },
      { name: 'Make', prop: 'make', headerTemplate: 'textSearch' },
      { name: 'Model', prop: 'model', headerTemplate: 'textSearch', width: 6 * em },
      { name: 'Year', prop: 'year', headerTemplate: 'textSearch', width: 8 * em },
      { name: 'Color', prop: 'color', headerTemplate: 'textSearch', width: 8 * em },
      { name: 'Capacity', prop: 'capacity', headerTemplate: 'textSearch', width: 8 * em },
      { name: 'Assignment', prop: 'assignement', headerTemplate: 'textSearch', width: 6 * em },
      { name: 'Comment', prop: 'comment', headerTemplate: 'textSearch', width: 10 * em },
    ];
    this.filterChoices = {};
  }
  getData = (cmd: DatasetCommand) => this.VehicleSvc.getAll(cmd);
  onSelect(event: { row: Vehicle; type: string }) {
    if (event.type != 'click') return;
    this.router.navigate(['admin', 'vehicle-dtl', event.row.id]);
  }
  downloadCsv() {
    this.VehicleSvc.downloadFile();
  }
}
