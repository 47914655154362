<div class="h-100 w-100 bg-light" style="overflow-y: auto;">
  <div class="container">
    <section class="my-4 bg-white shadow">
      <div class="bg-success py-2 px-3">
        <p class="h4 text-white m-0">
          <i class="fa fa-hand fa-lg"></i>
          Potential offers
        </p>
      </div>
      <div class="p-3">
        <div class="w-100">
            <div class="row" *ngFor="let item of poffers">
                <a class="nav-link col-6" [routerLink]="'../requests'" [queryParams]="{active_in:item.name}">{{item.name}}</a>
                <span class="col-6">{{item.count}}</span>
            </div>
        </div>
      </div>
    </section>
    <section *ngIf="loading" class="d-flex justify-content-center my-5">
      <uiowa-ring></uiowa-ring>
    </section>
  </div>
</div>