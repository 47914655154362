import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, empty, from, of, throwError } from 'rxjs';

import { PersonLookup } from "../models/person-lookup";
import { PersonInfo } from "../models/person-info";
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PeopleService {
  constructor(private http: HttpClient) { }

  search(qry: string): Observable<PersonLookup[]> {
    let params = new HttpParams()
      .append("qry",qry);
    return this.http.get<PersonLookup[]>("api/adm/ppl",{params});
  }

  getPersonInfo(uid:string):Observable<PersonInfo>{
    return this.http.get<PersonInfo>(`api/adm/prsn/${uid}`);
  }

  updatePersonInfo(data:any):Observable<boolean>{
    return this.http.post<any>("api/adm/prsn",{...data})
      .pipe(switchMap(res => of(true)));
  }
}