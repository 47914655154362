import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MileageRoutingModule } from './mileage-routing.module';
import { MileageComponent } from './mileage/mileage.component';
import { VanpoolService } from 'src/app/core/services/vanpool.service';
import { Util } from 'src/app/core';
import { SharedModule } from 'src/app/shared';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [MileageComponent],
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    MileageRoutingModule
  ],
  providers: [
    VanpoolService,
    Util
  ]
})
export class MileageModule { }
