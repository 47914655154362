<div class="h-100 w-100 bg-light" style="overflow-y: auto;">
  <!-- $$Implements SW.ADM.WLIST.PG_DTL 
  -->
    <div class="container">
        <section class="my-4 bg-white shadow" >
            <div class="bg-success py-2 px-3">
                <p class="h4 text-white m-0">
                    <i class="fa fa-hand fa-lg"></i>
                    Waiting List Request Details
                </p>
            </div>
            <div class="p-3" *ngIf="wlrq">
              <div class="w-100">
                <div class="row pl-2">
                  <div class="card-field">
                    <span class="card-field-label">Vanpool</span>
                    <a class="card-field-value" routerLink="/admin/vanpool-dtl/{{this.wlrq.vpoolid}}">{{this.wlrq.vpname}} </a>
                  </div>
                  <div class="card-field">
                    <span class="card-field-label">Name</span>
                    <span class="card-field-value"><a routerLink="/admin/people" [queryParams]="{uid:wlrq.univId}">
                        {{this.wlrq.last_name}}, {{this.wlrq.first_name}}</a></span>
                  </div>
                  <div class="card-field">
                    <span class="card-field-label">Work Hours</span>
                    <span class="card-field-value">{{this.wlrq.hours}}</span>
                  </div>
                  <div class="card-field">
                    <span class="card-field-label">University ID</span>
                    <span class="card-field-value">{{this.wlrq.univId}}</span>
                  </div>
                  <div class="card-field">
                    <span class="card-field-label">Request date</span>
                    <span class="card-field-value">{{this.dateString(this.wlrq.date)}}</span>
                  </div>
                  <div class="card-field">
                    <span class="card-field-label">Status</span>
                    <span class="card-field-value">{{this.wlrq.status}}</span>
                  </div>
                  <div class="card-field" *ngIf="wlrq.position">
                    <span class="card-field-label">Position</span>
                    <span class="card-field-value">{{this.wlrq.position}}</span>
                  </div>
                  <div class="card-field">
                    <span class="card-field-label">All requests</span>
                    <span class="card-field-value">{{this.wlrq.requests}}</span>
                  </div>
                  <div class="card-field" *ngIf="wlrq.offer_date">
                    <span class="card-field-label">Offer date</span>
                    <span class="card-field-value">{{this.dateString(this.wlrq.offer_date)}}</span>
                  </div>
                  <div class="card-field" *ngIf="wlrq.xpr_date">
                    <span class="card-field-label">Offer Expiration Date</span>
                    <span class="card-field-value">{{this.dateString(this.wlrq.xpr_date)}}
                      <a class="col-6 btn btn-success btn-xs text-nowrap" style="margin:2px;" *ngIf="wlrq.status == 'Offer Pending'" (click)="extendOffer()">
                      Extend Offer
                    </a>
                  </span>
                  </div>
                  <div class="card-field" *ngIf="wlrq.seat_date">
                    <span class="card-field-label">Seat Available Date</span>
                    <span class="card-field-value">{{this.dateString(this.wlrq.seat_date)}}
                      <a class="col-6 btn btn-success btn-xs text-nowrap" style="margin:2px;" 
                        *ngIf="wlrq.status == 'Offer Pending'" (click)="changeAvailableDate()">
                        Change
                      </a>
                  </span>
                  </div>
                  <div class="card-field" *ngIf="wlrq.removed_date">
                    <span class="card-field-label">Removed date</span>
                    <span class="card-field-value">{{this.dateString(this.wlrq.removed_date)}}</span>
                  </div>
                  <div class="card-field">
                    <span class="card-field-label">Current vanpool</span>
                    <span class="card-field-value">{{this.wlrq.current_vpool}}</span>
                  </div>
                  <div class="card-field-long" *ngIf="wlrq.isdriver">
                    <span class="card-field-label">The requestor is a driver</span>
                  </div>
                  <div class="card-field-long" *ngIf="wlrq.subrider">
                    <span class="card-field-label">The requestor is a sub-rider</span>
                  </div>
                  <div class="card-field-long" *ngIf="wlrq.status == 'Declined Offer'">
                    <span class="card-field-label">Decline reason</span>
                    <span class="card-field-value">{{this.wlrq.decl_rsn}}</span>
                  </div>
                  <div class="card-field-long" *ngIf="wlrq.status == 'Declined Offer'">
                    <span class="card-field-label">Explanation</span>
                    <span class="card-field-value" style="white-space: normal;">{{this.wlrq.decl_expl}}</span>
                  </div>
                  <div class="card-field-long">
                    <span class="card-field-label">Comment:</span>
                    <input type="text" [(ngModel)]="comment" class="card-field-value form-control" placeholder="comment" />
                  </div>
                </div>
              </div>
              
              <div class="row text-center my-1">
                <div class="col-4">
                  <a class="btn btn-danger btn-sm text-nowrap col-10" (click)="removeRequest()"
                    *ngIf="wlrq.status == 'Active Waiting' || wlrq.status == 'Offer Pending'">
                    Remove request
                  </a>
                </div>
                <div class="col-4">
                  <a class="btn btn-success btn-sm text-nowrap col-10" (click)="offerSeat()" *ngIf="vacant.count && wlrq.status == 'Active Waiting'">
                    Offer seat
                  </a>
                </div>
                <div class="col-4">
                  <button type="button" class="btn btn-warning btn-sm text-nowrap col-10" (click)="saveComment()"  *ngIf="comment != wlrq.comment">
                    Save
                  </button>
                </div>
              </div>
            </div>
        </section>
        <section *ngIf="loading" class="d-flex justify-content-center my-5">
            <uiowa-ring></uiowa-ring>
        </section>
    </div>
</div>

<ng-template #removeRequestDialog>
  <div>Please select the reason for request removal</div>
  <div class="form-group">
    <select class="form-control" name="reasonActiveSelect" *ngIf="wlrq.status == 'Active Waiting'" [(ngModel)]="removeReason">
      <option value="1">Administration Removed</option>
      <option value="2">Requested Removal</option>
      <option value="3">Van Ended</option>
      <option value="4">Employment Ended</option>
    </select>
    <select class="form-control" name="reasonActiveSelect" *ngIf="wlrq.status == 'Offer Pending'" [(ngModel)]="removeReason">
      <option value="3">Van Ended</option>
      <option value="4">Employment Ended</option>
      <option value="5">Declined Offer</option>
    </select>
  </div>
</ng-template>

<ng-template #offerDialog>
  <label for="available_date" class="col-6">Available date:</label>
  <input name="available_date" type="text" ngbDatepicker #available_date="ngbDatepicker" [(ngModel)]="offer.available_date" class="col-6" (click)="available_date.toggle()" [minDate]="offer.min_date">
  <label for="expiration_date" class="col-6">Expiration date:</label>
  <input name="expiration_date" type="text" ngbDatepicker #expiration_Date="ngbDatepicker"
    [(ngModel)]="offer.expiration_date" class="col-6" style="max-width: 8em;"[minDate]="offer.today" (click)="expiration_Date.toggle()">
</ng-template>

<ng-template #extendOfferDialog>
    <label for="expiration_date" class="col-6">New expiration date:</label>
    <input name="expiration_date" type="text" ngbDatepicker #expiration_Date="ngbDatepicker"
      [(ngModel)]="offer.expiration_date" class="col-6" [minDate]="offer.min_date"
      (click)="expiration_Date.toggle()">
</ng-template>

<ng-template #changeAvailableDialog>
  <label for="available_date" class="col-6">New date:</label>
  <input name="available_date" type="text" ngbDatepicker #available_date="ngbDatepicker"
    [(ngModel)]="offer.available_date" class="col-6" (click)="available_date.toggle()"
    [minDate]="offer.min_date">
</ng-template>