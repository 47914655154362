<div class="h-100 w-100" >
  <header>
    <div class="d-flex justify-content-between align-items-end">
      <h2 class="my-1 text-primary">
        <a routerLink="/admin/" title="admin home">
          <i class="fa fa-home fa-lg"></i>
          <i class="fa fa-angle-right fa-lg"></i>
        </a>
        People
      </h2>
    </div>
    <hr class="mt-0" />
  </header>

  <div class="container">
    <section class="my-4 bg-white shadow">
      <div class="bg-success py-2 px-3">
        <p class="h4 text-white m-0">
          <ng-completer
            [(ngModel)]="selectedPerson"
            (selected)="onSelected($event)"
            [datasource]="personsData"
            [minSearchLength]="4"
            [inputClass]="'search-input'"
            [placeholder]="'Find person by name or HawkID'"
            [textSearching]="'Please wait...'"
            style="min-width: 100%;"
            autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
          >
          </ng-completer>
        </p>
      </div>

      <div class="p-3">
        <div class="w-100" *ngIf="info">
          <div class="pl-2">
            <div class="col-12 col-lg-6 d-inline-flex flex-wrap">
              <span class="field-label">Univ ID</span>
              <span class="field-value">{{ info.univId }}</span>
            </div>
            <div class="col-12 col-lg-6 d-inline-flex flex-wrap">
              <span class="field-label">Emp ID</span>
              <span class="field-value">{{ info.employeeId }}</span>
            </div>
            <div class="col-12 col-lg-6 d-inline-flex flex-wrap">
              <span class="field-label">First name</span>
              <span class="field-value">{{ info.firstName }}</span>
            </div>
            <div class="col-12 col-lg-6 d-inline-flex flex-wrap">
              <span class="field-label">UI start date</span>
              <span class="field-value">{{ info.uistartDate }}</span>
            </div>
            <div class="col-12 col-lg-6 d-inline-flex flex-wrap">
              <span class="field-label">Middle name</span>
              <span class="field-value">{{ info.middleName }}</span>
            </div>
            <div class="col-12 col-lg-6 d-inline-flex flex-wrap">
              <span class="field-label">Work phone</span>
              <span class="field-value">{{ info.workPhone }}</span>
            </div>
            <div class="col-12 col-lg-6 d-inline-flex flex-wrap">
              <span class="field-label">Last name</span>
              <span class="field-value">{{ info.lastName }}</span>
            </div>
            <div class="col-12 col-lg-6 d-inline-flex flex-wrap">
              <span class="field-label">Home phone</span>
              <input type="tel" [(ngModel)]="homePhone" class="form-control-sm" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                placeholder="123-456-7890" />
            </div>
            <div class="col-12 col-lg-6 d-inline-flex flex-wrap">
              <span class="field-label">Work Email</span>
              <span class="field-value">{{ info.email }}</span>
            </div>
            <div class="col-12 col-lg-6 d-inline-flex flex-wrap">
              <span class="field-label">Alternate phone</span>
              <input type="tel" [(ngModel)]="cellPhone" class="form-control-sm" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder="123-456-7890" />
            </div>
            <br><br>
            <div class="col-12 col-lg-6 d-inline-flex flex-wrap">
              <span class="field-label">PayGroup</span>
              <span class="field-value">{{ info.payGroup }}</span>
            </div>
            <div class="col-12 col-lg-6 d-inline-flex flex-wrap">
              <span class="field-label">Dept</span>
              <span class="field-value">{{ info.departmentDescription }}</span>
            </div>
            <div class="col-12 col-lg-6 d-inline-flex flex-wrap">
              <span class="field-label">pctoftime</span>
              <span class="field-value">{{ info.pctoftime }}</span>
            </div>
            <div class="col-12 col-lg-6 d-inline-flex flex-wrap">
              <span class="field-label">Room</span>
              <span class="field-value">{{ info.room }}</span>
            </div>
            <div class="col-12 col-lg-6 d-inline-flex flex-wrap">
              <span class="field-label">Job Code/Title</span>
              <span class="field-value">{{ info.jobCode }} {{info.title||""}}</span>
            </div>
            <div class="col-12 col-lg-6 d-inline-flex flex-wrap">
              <span class="field-label">Building</span>
              <span class="field-value">{{ info.building }}</span>
            </div>
          </div>

          <div class="d-flex justify-content-center my-1">
              <button class="btn btn-success btn-sm text-nowrap col-4 mx-1" (click)="addToWaitlists()">
                Add to wait lists
              </button>
              <button type="button" class="btn btn-warning btn-sm text-nowrap col-4  mx-1" (click)="savePhones()"
                *ngIf="cellPhone != info.cellPhone || homePhone != info.homePhone">
                Save
              </button>
          </div>

        </div>
      </div>
    </section>
  </div>

  <div *ngIf="membHistoryData" class="container w-100">
    <h4>
      Vanpool History
    </h4>
  <ngx-datatable 
    #membHistoryTable
    [rows]="membHistoryData"
    [columns]="membHistoryColumns"
    [scrollbarH]="true"
    (activate)="onMembSelect($event)"
    class="bootstrap m-1"
  >
  </ngx-datatable>
  </div>

  <div *ngIf="wlHistoryData" class="container w-100">
    <h4>
      Wait list history
    </h4>
  <ngx-datatable 
    #wlHistoryTable
    [rows]="wlHistoryData"
    [columns]="wlHistoryColumns"
    [scrollbarH]="true"
    (activate)="onWlSelect($event)"
    class="bootstrap m-1"
  >
  </ngx-datatable>
  </div>

  <section *ngIf="loading" class="d-flex justify-content-center my-5">
    <uiowa-ring></uiowa-ring>
  </section>
</div>