import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VehicleDtlRoutingModule } from './vehicle-dtl-routing.module';
import { VehicleDtlComponent } from './vehicle-dtl/vehicle-dtl.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerModule } from '@uiowa/spinner';
import { ModalService, SharedModule } from 'src/app/shared';
import { ConfigService, MembersService, Util } from 'src/app/core';

@NgModule({
  declarations: [VehicleDtlComponent],
  imports: [CommonModule, SharedModule, FormsModule, SpinnerModule, NgbModule, VehicleDtlRoutingModule],
  providers: [ModalService, MembersService, ConfigService, Util],
})
export class VehicleDtlModule {}
