import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService, VehicleService, Util } from 'src/app/core';
import { Vehicle } from 'src/app/core/models/vehicle';
import { ModalService } from 'src/app/shared';

@Component({
  selector: 'vehicle-add',
  templateUrl: './vehicle-add.component.html',
  styleUrls: ['./vehicle-add.component.css'],
})
export class VehicleAddComponent implements OnInit {
  vehicle: Vehicle = new Vehicle();
  vhclTypes: string[];
  working = false;

  constructor(private router: Router, private cfgSvc: ConfigService, private vhclSvc: VehicleService, private modal: ModalService) {}

  ngOnInit(): void {
    this.vhclTypes = this.cfgSvc.current.vhcl_types;
  }

  canSubmit(): boolean {
    // TODO: implement
    return false;
  }
  submitProblem(): string {
    if (!this.vehicle.eqno) return 'Please enter unique euipment number';
    if (!this.vehicle.license_plate) return 'Please enter unique license plate number';
    if (!this.vehicle.color) return 'Please enter color of vehicle';
    if (!this.vehicle.make) return 'Please enter the make of vehicle';
    if (!this.vehicle.model) return 'Please emter the model of vehicle';
    if (!this.vehicle.capacity) return 'Please enter desired capacity';
    if (!this.vehicle.type) return 'Please enter type of vehicle';
    if (!this.vehicle.year) return 'Please enter year of vehicle';
    return '';
  }
  submit() {
    this.working = true;
    this.vhclSvc.add(this.vehicle).subscribe(
      (res) => res && this.router.navigate(['admin', 'vehicle-dtl', res]),
      (e) => (this.working = !this.modal.error(e).subscribe())
    );
  }
}
