import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { SortDirection, SortPropDir, TableColumn } from '@siemens/ngx-datatable';
import { of } from 'rxjs';
import { flatMap, switchMap } from 'rxjs/operators';
import { ConfigService, Util, VehicleService } from 'src/app/core';
import { TotalCharge } from 'src/app/core/models/total-charge';
import { DesiredCapacity } from 'src/app/core/models/desired-capacity';
import { Vanpool } from 'src/app/core/models/vanpool';
import { VanpoolService } from 'src/app/core/services/vanpool.service';
import { ModalService } from 'src/app/shared';
import { DatatableComponent } from 'src/app/shared/components/datatable/datatable.component';
import { Vehicle } from 'src/app/core/models/vehicle';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'vanpool-dtl',
  templateUrl: './vanpool-dtl.component.html',
  styleUrls: ['./vanpool-dtl.component.scss']
})
export class VanpoolDtlComponent implements OnInit, AfterViewInit {
  @ViewChild('chargeTable') chargeTable: DatatableComponent;
  @ViewChild('capacityTable') capacityTable: DatatableComponent;
  @ViewChild("dissolveDialog") dissolveDialog: TemplateRef<any>;
  @ViewChild("chargeUpdateDialog") chargeUpdateDialog: TemplateRef<any>;
  @ViewChild("capacityUpdateDialog") capacityUpdateDialog: TemplateRef<any>;
  @ViewChild("swapVehiclesDialog") swapVehiclesDialog: TemplateRef<any>;
  @ViewChild("textInputColumn") textInputColumn: TemplateRef<any>;
  
  dateString = Util.dateString;
  currencyString = Util.currencyString;
  loading = false;
  
  vpid: number;
  original: Vanpool;
  vanpool: Vanpool;
  vehicle: Vehicle;

  chargeColumns: TableColumn[];
  chargeSorts: SortPropDir[] = [{ prop:"effect_date", dir:SortDirection.desc}];
  defaultChargeRow = new TotalCharge();
  capacityColumns: TableColumn[];
  capacitySorts: SortPropDir[] = [{ prop:"effect_date", dir:SortDirection.desc}];
  defaultCapacityRow = new DesiredCapacity();
  
  name: string;
  communities: string[];
  hours: string[];
  days: string[];
  vanpool_end_reasons: string[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private vanpoolSvc: VanpoolService,
    private vhclSvc: VehicleService,
    private cfgSvc: ConfigService,
    private modal: ModalService
  ) { }

  ngOnInit(): void {
    this.communities = this.cfgSvc.current.communities;
    this.hours = this.cfgSvc.current.work_hours;
    this.days = this.cfgSvc.current.work_days;
    this.vanpool_end_reasons = this.cfgSvc.current.vanpool_end_reasons;

    const em = Util.getEM();
    this.chargeColumns = [
      { name: "Total Charge", prop: "charge", width: 6*em, pipe: new CurrencyPipe('en-US') },
      { name: "Effective Date", prop: "effect_date", width: 7*em,  cellTemplate: "dateColumn" },
      { name: "End Date", prop: "end_date", width: 7*em, cellTemplate: "dateColumn" },
    ];
    this.capacityColumns = [
      { name: "Capacity", prop: "capacity", width: 4*em },
      { name: "Effective Date", prop: "effect_date", width: 7*em,  cellTemplate: "dateColumn" },
      { name: "End Date", prop: "end_date", width: 7*em, cellTemplate: "dateColumn" },
      
    ];
  }

  ngAfterViewInit(): void {
    const em = Util.getEM();
    this.capacityColumns.push({ name: "Comment", prop: "comment", width: 15 * em, cellTemplate: this.textInputColumn });
    this.capacityColumns = [...this.capacityColumns];
    this.activatedRoute.params.subscribe(p => setTimeout(() => {
      this.vpid = parseInt(p["id"]);
      if (this.vpid == undefined)
        return;
      this.loadData();
    }));
  }

  loadData() {
    this.loading = true;
    this.vanpoolSvc.getVanpoolDetails(this.vpid).pipe(
      flatMap(
        vp => {
          vp.comment = vp.comment || "";
          this.original = vp;
          this.vanpool = { ...vp };
          return this.vhclSvc.get(vp.eqno);
        },
      )
    )
    .subscribe(
      v => {
        this.vehicle = {...v};
        this.loading = false;
      },
      e => this.modal.error(e).subscribe(() => this.loading = false)
    );
    this.chargeTable.loadAllData();
    this.capacityTable.loadAllData();
  }

  getChargeHistory = () => (this.vpid == undefined) ? of([]) : this.vanpoolSvc.getChargeHistory(this.vpid);

  getCapacityHistory = () => (this.vpid == undefined) ? of([]) : this.vanpoolSvc.getCapacityHistory(this.vpid);

  blur(event:Event) {
    (<HTMLTextAreaElement>event.target).blur();
  }
  onCapacityComment(event:FocusEvent, row:DesiredCapacity) {
    let cmnt = (<any>event.currentTarget).value;
    this.vanpoolSvc.updateCapacityComment(row.id,cmnt).subscribe(
      res => res && this.loadData(),
      e => this.loading = !this.modal.error(e).subscribe()
    )
  }

  membersQry(){
    let res = { filter: [`vpname~=${this.vanpool.vpname}`, "status~=@AoF"], sorts:"type"}
    return res;
  }
  
  update() {
    this.loading = true;
    this.vanpoolSvc.update(this.vanpool).subscribe(
      () => this.loadData(),
      e => this.loading = !this.modal.error(e).subscribe()
    )

  }

  dirty():boolean {
    return !Util.compareProperties(this.original,this.vanpool);
  }

  setSubriders(event:InputEvent) {
    this.vanpool.accept_subriders = (<any>event.currentTarget).value=="true";
  }

  dlg: {
    value?: number;
	min_value?:number;
	max_value?:number;
    date: NgbDateStruct;
    min_date: NgbDateStruct;
    prev_comment?: string;
    comment?: string,
    eqno?:string;
    eqnos?:string[],
    vehicles?: { [id: string]: Vehicle },
    select?: string;
  }

  dissolve() {
    this.dlg = {
      select: this.vanpool_end_reasons[0],
      date: Util.toNgbDate(Util.today),
      min_date: Util.toNgbDate(Util.today),
      comment: ""
    };
    this.modal.show({
      title: `Dissolve vanpool ${this.vanpool.vpname}?`,
      cancelBtn: "Cancel",
      template: this.dissolveDialog
    }).pipe(
      flatMap(res => {
        this.loading = res;
        return res ?
          this.vanpoolSvc.dissolve(
            this.vanpool.vpoolid,
            Util.fromNgbDate(this.dlg.date),
            this.dlg.select,
            this.dlg.comment
          )
          : of(false)
      })
    ).subscribe(
      res => res && this.loadData(),
      e => this.modal.error(e).subscribe(() => this.loading = false)
    )
  }

  updateCharge() {
    this.dlg = {
      value: this.vanpool.ttl_chrg,
      date: Util.toNgbDate(Util.today),
      min_date: Util.toNgbDate(Util.today)
    };
    this.modal.show({
      title: "Update total charge",
      cancelBtn: "Cancel",
      template: this.chargeUpdateDialog
    }).pipe(
      flatMap(res => {
        this.loading = res;
        return res ?
          this.vanpoolSvc.updateTotalCharge(
            this.vpid,
            this.dlg.value,
            Util.fromNgbDate(this.dlg.date)
          )
          : of(0)
      })
    ).subscribe(
      res => res && this.loadData(),
      e => this.loading = !this.modal.error(e).subscribe()
    )
  }

  updateCap() {
    this.dlg = {
      value: this.vanpool.dcap,
	    max_value: this.vehicle.capacity,
      min_value: this.vanpool.mmbr_count,
      date: Util.toNgbDate(Util.today),
      min_date: Util.toNgbDate(Util.today),
      comment: ""
    };
    this.modal.show({
      title: "Desired capacity",
      cancelBtn: "Cancel",
      template: this.capacityUpdateDialog
    }).pipe(
      flatMap(res => {
        this.loading = res;
         if (res) {
            if (this.dlg.value > this.vehicle.capacity) {
              return this.modal.error(`Desired Capacity cannot be more than capacity of vehicle`);
            }
			if (this.dlg.value < this.vanpool.mmbr_count) {
              return this.modal.error(`Desired Capacity cannot be less than member count`);
            }
            return this.vanpoolSvc.updateCapacity(
				this.vpid, 
				this.dlg.value, 
				Util.fromNgbDate(this.dlg.date), this.dlg.comment);
          } else {
            return of(0);
          }
      })
    ).subscribe(
      res => res && this.loadData(),
      e => this.loading = !this.modal.error(e).subscribe()
    )
  }

  changeVehicle() {
    this.dlg = {
      date: Util.toNgbDate(Util.today),
      min_date: Util.toNgbDate(Util.today),
      prev_comment: this.vanpool.vhcl_asgn_cmnt,
      comment: "",
    };
    this.loading=true;
    this.vhclSvc.getAvailable().pipe(
      switchMap(
        v=> {
          this.loading=false;
          if (!v.length)
            return this.modal.notify("Can not change vehicle","No available vehicles found").pipe(switchMap(()=>of(0)));
          v = v.sort((a,b) => parseInt(b.eqno)-parseInt(a.eqno));
          this.dlg.vehicles = {};
          v.forEach(v=>this.dlg.vehicles[v.eqno]=v);
          this.dlg.eqnos=v.map(v=>v.eqno);
          this.dlg.eqno = v.length?v[0].eqno:null;
          return this.modal.show({
            title: "Change vehicle",
            cancelBtn: "Cancel",
            template: this.swapVehiclesDialog
          });
        }
      ),
      switchMap(res => {
        return res ?
          this.vanpoolSvc.changeVehicle(
            this.vpid,
            this.dlg.eqno,
            Util.fromNgbDate(this.dlg.date),
            this.dlg.prev_comment,
            this.dlg.comment
          )
          : of(0)
      })
    ).subscribe(
      res => res && this.loadData(),
      e => this.loading = !this.modal.error(e).subscribe()
    )
  }
}
