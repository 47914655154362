import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RateHistoryRoutingModule } from './rate-history-routing.module';
import { RateHistoryComponent } from './rate-history/rate-history.component';
import { SharedModule } from 'src/app/shared';
import { VanpoolService } from 'src/app/core/services/vanpool.service';
import { Util } from 'src/app/core';


@NgModule({
  declarations: [RateHistoryComponent],
  imports: [
    CommonModule,
    SharedModule,
    RateHistoryRoutingModule
  ],
  providers: [
    VanpoolService,
    Util
  ]
})
export class RateHistoryModule { }
