import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PeopleRoutingModule } from './people-routing.module';
import { PeopleComponent } from './people/people.component';
import { SpinnerModule } from '@uiowa/spinner';
import { NgCompleterModule } from '@vldmr-bus/ng-completer';
import { FormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@siemens/ngx-datatable';

import { SharedModule } from 'src/app/shared';
import { ModalService } from 'src/app/shared';

import { PeopleService } from 'src/app/core/services/people.service';
import { MembersService, WaitlistService } from 'src/app/core';

@NgModule({
  declarations: [PeopleComponent],
  imports: [
    CommonModule,
    PeopleRoutingModule,
    SpinnerModule,
    FormsModule,
    NgCompleterModule,
    SharedModule,
    NgxDatatableModule
  ],
  providers:[
    ModalService,
    PeopleService,
    MembersService,
    WaitlistService
  ]
})
export class PeopleModule { }
