import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRoute } from '@angular/router';

import { childRoutes } from './admin-child-routes';
import { LayoutComponent } from './layout/layout.component';
import { HomeComponent } from './home/home.component';
import { RequestsComponent as RequestsHomeComponent } from './requests/requests/requests.component';
import { RoleGuard } from '../core';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { WlRqDtlComponent } from './wl-rq-dtl/wl-rq-dtl/wl-rq-dtl.component';
import { PoffersComponent } from './poffers/poffers/poffers.component';
import { MembDtlComponent } from './memb-dtl/memb-dtl/memb-dtl.component';
import { VanpoolDtlComponent } from './vanpool-dtl/vanpool-dtl/vanpool-dtl.component';
import { PdriversComponent } from './pdrivers/pdrivers/pdrivers.component';
import { SubriderRqsComponent } from './subrider-rqs/subrider-rqs/subrider-rqs.component';
import { SubriderRqDtlComponent } from './subrider-rq-dtl/subrider-rq-dtl/subrider-rq-dtl.component';
import { SubriderDtlComponent } from './subrider-dtl/subrider-dtl/subrider-dtl.component';
import { RateHistoryComponent } from './rate-history/rate-history/rate-history.component';
import { VanpoolAddComponent } from './vanpool-add/vanpool-add/vanpool-add.component';
import { VehicleAddComponent } from './vehicle-add/vehicle-add/vehicle-add.component';
import { VehicleDtlComponent } from './vehicle-dtl/vehicle-dtl/vehicle-dtl.component';
import { VehicleHistoryComponent } from './vehicle-history/vehicle-history/vehicle-history.component';
import { PayrollDeductRunComponent } from './payroll-deduct-run/payroll-deduct-run/payroll-deduct-run.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [RoleGuard],
    data: { roles: ['Admin'] },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dash-board',
      },
      {
        path: 'dash-board',
        component: HomeComponent,
        data: { linkText: 'Admin Home', icon: faHome },
      },
      {
        path: 'requests',
        component: RequestsHomeComponent,
        data: { linkText: 'Waiting List Requests' },
      },
      {
        path: 'poffers',
        component: PoffersComponent,
        data: { linkText: 'Waiting List Requests' },
      },
      {
        path: 'wl-rq-dtl/:rqid',
        pathMatch: 'full',
        component: WlRqDtlComponent,
        data: {
          linkText: 'Waiting List Request Detail',
          title: (r: ActivatedRoute) => `WL Request ${r.snapshot.url.reverse()[0].path}`,
        },
      },
      {
        path: 'pdrivers',
        pathMatch: 'full',
        component: PdriversComponent,
        data: { linkText: 'Pending Driver Requests' },
      },
      {
        path: 'subrider-rqs',
        pathMatch: 'full',
        component: SubriderRqsComponent,
        data: { linkText: 'Pending Sub-rider Requests' },
      },
      {
        path: 'srdr-rq-dtl/:rqid',
        pathMatch: 'full',
        component: SubriderRqDtlComponent,
        data: {
          linkText: 'Sub-rider Request Detail',
          title: (r: ActivatedRoute) => `Sub-rider Request ${r.snapshot.url.reverse()[0].path}`,
        },
      },
      {
        path: 'srd-dtl/:rqid',
        pathMatch: 'full',
        component: SubriderDtlComponent,
        data: {
          linkText: 'Sub-rider Record Detail',
          title: (r: ActivatedRoute) => `Sub-rider Record ${r.snapshot.url.reverse()[0].path}`,
        },
      },
      {
        path: 'memb-dtl/:id',
        pathMatch: 'full',
        component: MembDtlComponent,
        data: {
          linkText: 'Member Detail',
          title: (r: ActivatedRoute) => `Member:${r.snapshot.url.reverse()[0].path}`,
        },
      },
      {
        path: 'vanpool-dtl/:id',
        pathMatch: 'full',
        component: VanpoolDtlComponent,
        data: {
          linkText: 'Vanpool Detail',
          title: (r: ActivatedRoute) => `Vanpool:${r.snapshot.url.reverse()[0].path}`,
        },
      },
      {
        path: 'rate-history/:id',
        pathMatch: 'full',
        component: RateHistoryComponent,
        data: {
          linkText: 'Vanpool Rate History',
          title: (r: ActivatedRoute) => `Rate History:${r.snapshot.url.reverse()[0].path}`,
        },
      },
      {
        path: 'vanpool-add',
        pathMatch: 'full',
        component: VanpoolAddComponent,
        data: {
          linkText: 'Add Vanpool',
        },
      },
      {
        path: 'vehicle-dtl/:id',
        pathMatch: 'full',
        component: VehicleDtlComponent,
        data: {
          linkText: 'Vehicle Detail',
          title: (r: ActivatedRoute) => `Vehicle:${r.snapshot.url.reverse()[0].path}`,
        },
      },
      {
        path: 'vehicle-history',
        pathMatch: 'full',
        component: VehicleHistoryComponent,
        data: {
          linkText: 'Vehicle History',
          title: (r: ActivatedRoute) => `Vehicle History:${r.snapshot.url.reverse()[0].path}`,
        },
      },
      {
        path: 'vehicle-add',
        pathMatch: 'full',
        component: VehicleAddComponent,
        data: {
          linkText: 'Add Vehicle',
        },
      },
      {
        path: 'payroll-deduct-run',
        pathMatch: 'full',
        component: PayrollDeductRunComponent,
        data: {
          linkText: 'Payroll Deduct job run',
        },
      },
      ...childRoutes,
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
