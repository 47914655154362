<header role="banner">
  <uiowa-header
    [applicationName]="applicationName"
    [externalLinks]="externalLinks"
    [internalRoutes]="internalRoutes"
    [user]="user"
    (stopImpersonation)="stopImpersonate()"
  >
  </uiowa-header>
</header>
<main role="main" class="w-100 h-100 flex-fill p-0 overflow-auto">
  <router-outlet></router-outlet>
</main>
<footer role="contentinfo">
  <app-footer></app-footer>
</footer>
<session-expiration-alert [startTimer]="startTimer"></session-expiration-alert>
