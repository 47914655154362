import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UiowaHeaderModule } from '@uiowa/uiowa-header';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  SessionExpirationAlert,
  SessionInterruptService
} from 'session-expiration-alert';

import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { ImpersonationComponent } from './components/impersonation/impersonation.component';
import { AppErrorHandler } from './services/app-errror-handler';
import { SessionInterruptorService } from './services/session-interruptor.service';
import { HttpCacheControlService } from './services/http-cache-control.service';

@NgModule({
  imports: [
    CommonModule,
    UiowaHeaderModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    NgbModule,
    SessionExpirationAlert
  ],
  declarations: [
    AppFooterComponent,
    AccessDeniedComponent,
    ImpersonationComponent
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler
    },
    { provide: SessionInterruptService, useClass: SessionInterruptorService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCacheControlService,
      multi: true
    }
  ],
  exports: [
    UiowaHeaderModule,
    AppFooterComponent,
    AccessDeniedComponent,
    ImpersonationComponent
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    core: CoreModule
  ) {
    if (core) {
      throw new Error('Core Module can only be imported in AppModule.');
    }
  }
}
