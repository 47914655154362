import { Component, OnInit, EventEmitter, Output, Input, TemplateRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscriber } from 'rxjs';

@Component({
  selector: 'lib-modal',
  templateUrl: "./modal.component.html",
  host: {
    '[@state]': 'state',
  },
  animations: [
    trigger('state', [
      state('opened', style({ transform: 'translateY(0%)' })),
      state('void, closed', style({ transform: 'translateY(100%)', opacity: 0 })),
      transition('* => *', animate('100ms ease-in')),
    ])
  ],
  styleUrls:['./modal.component.scss'],
})
export class ModalComponent {
  state: 'opened' | 'closed' = 'closed';
  shown: boolean;
  @Input()
  title: string;
  @Input()
  message?: string;
  @Input()
  cancelBtn?: string;
  @Input()
  okBtn?: string;
  @Input()
  result: boolean;

  @Input()
  template?: TemplateRef<any>;
  @Input()
  okBtnHelp: (()=>string)|string = "";
  @Input()
  okBtnDisabled: (()=>boolean)|boolean = false;

  @Output()
  closed = new EventEmitter();

  subscriber: Subscriber<any>;

  getOkBtnHelp():string {
    if (typeof this.okBtnHelp == 'function')
      return this.okBtnHelp();
    else
      return this.okBtnHelp;
  }
  getOkBtnDisabled():boolean {
    if (typeof this.okBtnDisabled == 'function')
      return this.okBtnDisabled();
    else
      return this.okBtnDisabled;
  }
  close(result) {
    this.result = result; 
    this.closed.next(0);
  }
}
