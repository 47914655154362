import { Routes, ActivatedRoute } from '@angular/router';
import {
  faShuttleVan,
  faWeightHanging,
  faBus,
  faGift,
  faTachometerAlt,
  faUsers,
  faAddressBook,
  faUserClock,
  faFlag
} from '@fortawesome/free-solid-svg-icons';

export const childRoutes: Routes = [
  {
    path: 'vanpools',
    loadChildren: () =>
      import('./vanpools/vanpools.module').then(
        m => m.VanpoolsModule
      ),
    data: { linkText: 'Vanpools', icon: faShuttleVan }
  },
  {
    path: 'vehicles',
    loadChildren: () =>
      import('./vehicles/vehicles.module').then(
        m => m.VehiclesModule
      ),
    data: { linkText: 'Vehicles', icon: faBus }
  },
  {
    path: 'loaners',
    loadChildren: () =>
      import('./loaners/loaners.module').then(m => m.LoanersModule),
    data: { linkText: 'Loaners', icon: faGift }
  },
  {
    path: 'mileage',
    loadChildren: () =>
      import('./mileage/mileage.module').then(
        m => m.MileageModule
      ),
    data: { linkText: 'Mileage', icon: faTachometerAlt }
  },
  {
    path: 'people',
    loadChildren: () =>
      import('./people/people.module').then(m => m.PeopleModule),
    data: { 
      linkText: 'People', 
      icon: faUsers,
      title: (r: ActivatedRoute) => {
        let title = "People";
        let uid = r.snapshot.queryParamMap.get('uid');
        if (uid)
          title += ":"+uid;
        return title;
      }
     }
  },
  {
    path: 'members',
    loadChildren: () => import('./members/members.module').then(m => m.MembersModule),
    data: { linkText: 'Members', icon: faAddressBook }
  },
  {
    path: 'subriders',
    loadChildren: () =>
      import('./subriders/subriders.module').then(m => m.SubridersModule),
    data: { linkText: 'Sub-riders', icon: faUserClock }
  },
  {
    path: 'payroll-deduct',
    loadChildren: () => import('./payroll-deduct/payroll-deduct.module').then((m) => m.PayrollDeductModule),
    data: { linkText: 'Reports', icon: faFlag },
  }
];

export interface LinkItem {
  icon: string;
  text: string;
  path: string;
}

export const childRouteLinkItems: LinkItem[] = childRoutes.map(x => {
  return {
    icon: x.data.icon,
    text: x.data.linkText,
    path: x.path
  } as LinkItem;
});
