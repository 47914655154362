import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubrdrrqsRoutingModule as SubriderRqsRoutingModule } from './subrider-rqs-routing.module';
import { SubriderRqsComponent } from './subrider-rqs/subrider-rqs.component';
import { Util } from 'src/app/core';
import { SharedModule } from 'src/app/shared';


@NgModule({
  declarations: [SubriderRqsComponent],
  imports: [
    CommonModule,
    SharedModule,
    SubriderRqsRoutingModule
  ],
  providers:[
    Util
  ]
})
export class SubriderRqsModule { }
