import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoanersRoutingModule } from './loaners-routing.module';
import { LoanersComponent } from './loaners/loaners.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared';
import { Util, VehicleService } from 'src/app/core';


@NgModule({
  declarations: [LoanersComponent],
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    LoanersRoutingModule
  ],
  providers: [
    VehicleService,
    Util
  ]
})
export class LoanersModule { }
