import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule, UserService, ConfigService } from './core';
import { ToastrModule } from 'ngx-toastr';
import { SessionExpirationAlert } from 'session-expiration-alert';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AdminModule } from './admin/admin.module';

export function appUserServiceFactory(userService: UserService) {
  return () => userService.getUser();
}

export function configServiceFactory(configService: ConfigService) {
  return () => configService.init();
}

@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    ToastrModule.forRoot(),
    SessionExpirationAlert.forRoot(),
    CoreModule,
    AppRoutingModule,
    AdminModule
  ],
  providers: [
    Title,
    {
      provide: APP_INITIALIZER,
      useFactory: appUserServiceFactory,
      deps: [UserService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configServiceFactory,
      deps: [ConfigService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
