import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SpinnerModule } from '@uiowa/spinner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from "./modal/modal.module"
import { DatatableComponent } from './components/datatable/datatable.component'
import { TileComponent } from './components/tile/tile.component'
import { NgxDatatableModule } from '@siemens/ngx-datatable';
import { DateRangeComponent } from './components/date-range/date-range.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    RouterModule,
    SpinnerModule,
    FontAwesomeModule,
    ModalModule,
    NgxDatatableModule
  ],
  declarations: [
    DatatableComponent, 
    DateRangeComponent,
    TileComponent],
  exports: [
    CommonModule,
    NgbModule,
    FormsModule,
    SpinnerModule,
    FontAwesomeModule,
    ModalModule,
    DatatableComponent,
    DateRangeComponent,
    TileComponent
  ]
})
export class SharedModule {}
