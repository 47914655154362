import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; // common ng directives like *ngFor
import { ModalComponent } from './modal.component';

@NgModule({
    declarations: [ModalComponent],
    imports: [
        CommonModule,
    ],
    exports: [ModalComponent]
})
export class ModalModule { }
