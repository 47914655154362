<div class="container">
  <h2 class="text-danger my-4">Access Denied.</h2>

  <div class="my-5" *ngIf="userService.user$ | async as user; else notLoggedIn">
    <ngb-alert type="info" class="fade show" [dismissible]="false">
      <p class="alert-heading" style="font-weight: 500;">
        Hello, {{ user.hawkId }},
      </p>
      <p>
        Your account doesn't have enough permission to view protected content in
        this website.
      </p>
    </ngb-alert>
  </div>
  <ng-template class="my-5" #notLoggedIn>
    <ngb-alert type="danger" class="fade show" [dismissible]="false">
      <p class="alert-heading" style="font-weight: 500;">Hello,</p>
      <p>
        You haven't logged in our system or your account doesn't have enough
        permission.
      </p>
    </ngb-alert>
  </ng-template>
  <button class="btn btn-primary" (click)="login()">
    HawkID Login
  </button>
</div>
