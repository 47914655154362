import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WlRqDtlComponent } from './wl-rq-dtl/wl-rq-dtl.component';


const routes: Routes = [{
  path: ':rqid',
  component: WlRqDtlComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WlRqDtlRoutingModule { }
