import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerModule } from '@uiowa/spinner';
import { SharedModule } from 'src/app/shared';
import { PayrollDeductRunComponent } from './payroll-deduct-run/payroll-deduct-run.component';
import { PayrollDeductRunRoutingModule } from './payroll-deduct-run-routing.module';
import { PayrollService } from 'src/app/core/services/payroll.service';

@NgModule({
  declarations: [PayrollDeductRunComponent],
  imports: [CommonModule, SharedModule, FormsModule, SpinnerModule, NgbModule, PayrollDeductRunRoutingModule],
  providers: [PayrollService],
})
export class PayrollDeductRunModule {}
