import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanLoad,
  Route,
  UrlSegment
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { map, take } from 'rxjs/operators';
import { LoginService } from '@uiowa/uiowa-header';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate, CanLoad {
  constructor(
    private router: Router,
    private readonly userService: UserService,
    private readonly loginService: LoginService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    this.loginService.returnUri = state.url;
    const roles = (route.data.roles || []) as Array<string>;
    return this.userService.user$.pipe(
      map(u => {
        if (!u.isAuthenticated()) {
          this.router.navigateByUrl('');
          return false;
        }
        if (!roles.includes(u.role)) {
          this.router.navigateByUrl('/access-denied');
          return false;
        }
        return true;
      })
    );
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.loginService.returnUri = segments.map(x => x.path).join('/');
    const roles = (route.data.roles || []) as Array<string>;
    return this.userService.user$.pipe(
      take(1),
      map(u => {
        if (!u.isAuthenticated()) {
          this.loginService.login();
          return false;
        }
        if (!roles.includes(u.role)) {
          this.router.navigateByUrl('/access-denied');
          return false;
        }
        return true;
      })
    );
  }
}
