import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRoute } from '@angular/router';
import { HomeComponent } from './home/home.component';
import {
  RoleGuard,
  AccessDeniedComponent,
  ImpersonationComponent,
  HomeGuard,
  FaqGuard
} from './core';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    canActivate: [HomeGuard],
    component: HomeComponent
  },
  {
    path: 'faq',
    pathMatch: 'full',
    canActivate: [FaqGuard],
    component: HomeComponent
  },
  {
    path: 'impersonation',
    component: ImpersonationComponent,
    canActivate: [RoleGuard],
    data: { roles: ['Admin'] }
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canLoad: [RoleGuard],
    data: { roles: ['Admin'] }
  },
  { 
    path: 'employee', 
    canLoad: [RoleGuard],
    data: {
      roles: ['Employee', 'Admin']
    },
    loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule) 
  },
  {
    path: 'joinwl',
    canLoad: [RoleGuard],
    data: {
      roles: ['Employee', 'Admin'],
      title: "Join WL"
    },
    loadChildren: () => import('./employee/join/join.module').then(m => m.JoinModule)
  },
  {
    path: 'mywlhistory',
    canLoad: [RoleGuard],
    data: {
      roles: ['Employee', 'Admin'],
      title: "My Waiting list history"
    },
    loadChildren: () => import('./employee/my-wl-history/my-wl-history.module').then(m => m.MyWlHistoryModule)
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent
  },
  {
    path: '**',
    redirectTo: '',
    canActivate: [RoleGuard],
    data: {
      roles: ['Employee',  'Admin']
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
