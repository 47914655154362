import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from 'src/app/shared/components/datatable/datatable.component';
import { TableColumn, SortPropDir } from '@siemens/ngx-datatable';
import { SubriderRequest } from 'src/app/core/models/subrider-request';
import { WaitlistService, ConfigService, Util } from 'src/app/core';
import { DatasetCommand } from 'src/app/core/models/dataset';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'admin-subrider-rqs',
  templateUrl: './subrider-rqs.component.html',
  styleUrls: ['./subrider-rqs.component.scss']
})
export class SubriderRqsComponent implements OnInit {
  @ViewChild('datatable') datatable: DatatableComponent;

  columns: TableColumn[];
  sorts: SortPropDir[] = [];
  filter: { [key: string]: string } = {};
  filterChoices: { [key: string]: string[] };
  defaultRow = new SubriderRequest();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private wlistSvc: WaitlistService,
    private cfgSvc: ConfigService
  ) { 
    if (this.activatedRoute.snapshot.queryParamMap.get('pending')) {
      this.filter = {
        status: '=Initialized'
      };
    }
  }

  ngOnInit(): void {
    const em = Util.getEM();
    this.columns = [  // $$Implements SW.ADM.WLIST.PG_ALL
      { name: "Vanpool", prop: "vpname", headerTemplate: "textSearch", width: 12*em },
      { name: "Work Hours", prop: "hours", headerTemplate: "selectSearch" },
      { name: "Univ ID", prop: "univId", width: 5 * em, headerTemplate: "textSearch" },
      { name: "First Name", prop: "first_name", canAutoResize: true, headerTemplate: "textSearch" },
      { name: "Last Name", prop: "last_name", headerTemplate: "textSearch" },
      { name: "Status", prop: "status", headerTemplate: "selectSearch" },
      { name: "Last Change", prop: "change_date", width: 10 * em, headerTemplate: "dateSearch", cellTemplate: "dateColumn" },
      { name: "Comment", prop: "comment", width: 22 * em, headerTemplate: "textSearch" },
    ];
    this.filterChoices = {
      'hours': [...this.cfgSvc.current.work_hours],
      'status': ["Initialized","Approved","Denied"]
    }
  }

  getData = (cmd: DatasetCommand) => this.wlistSvc.getSubriderRequests(cmd);

  onSelect(event: { row: SubriderRequest, type: string }) {
    if (event.type != "click")
      return;
    this.router.navigate(['admin', 'srdr-rq-dtl', event.row.id]);
  }
}
