import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgChartsModule } from 'ng2-charts';

import { SharedModule } from '../shared/shared.module';
import { AdminRoutingModule } from './admin-routing.module';
import { VanpoolsModule } from './vanpools/vanpools.module';
import { VehiclesModule } from './vehicles/vehicles.module';
import { LoanersModule } from './loaners/loaners.module';
import { PeopleModule } from './people/people.module';
import { MembersModule } from './members/members.module';
import { SubridersModule } from './subriders/subriders.module';
import { MileageModule } from './mileage/mileage.module';
import { RequestsModule } from './requests/requests.module';

import { LayoutComponent } from './layout/layout.component';
import { HomeComponent } from './home/home.component';
import { PdriversModule } from './pdrivers/pdrivers.module';
import { SubriderRqsModule } from './subrider-rqs/subrider-rqs.module';
import { WlRqDtlModule } from './wl-rq-dtl/wl-rq-dtl.module';
import { PoffersModule } from './poffers/poffers.module';
import { MembDtlModule } from './memb-dtl/memb-dtl.module';
import { SubriderRqDtlModule } from './subrider-rq-dtl/subrider-rq-dtl.module';
import { SubriderDtlModule } from './subrider-dtl/subrider-dtl.module';
import { VanpoolDtlModule } from './vanpool-dtl/vanpool-dtl.module';
import { RateHistoryModule } from './rate-history/rate-history.module';
import { VanpoolAddModule } from './vanpool-add/vanpool-add.module';
import { VehicleAddModule } from './vehicle-add/vehicle-add.module';
import { VehicleDtlModule } from './vehicle-dtl/vehicle-dtl.module';
import { VehicleHistoryModule } from './vehicle-history/vehicle-history.module';
import { PayrollDeductModule } from './payroll-deduct/payroll-deduct.module';
import { PayrollDeductRunModule } from './payroll-deduct-run/payroll-deduct-run.module';

@NgModule({
  declarations: [LayoutComponent, HomeComponent],
  imports: [
    CommonModule,
    NgbModule,
    NgChartsModule,
    SharedModule,
    AdminRoutingModule,
    VanpoolsModule,
    VehiclesModule,
    LoanersModule,
    PeopleModule,
    MembersModule,
    SubridersModule,
    MileageModule,
    RequestsModule,
    PdriversModule,
    SubriderRqsModule,
    WlRqDtlModule,
    PoffersModule,
    MembDtlModule,
    SubriderRqDtlModule,
    SubriderDtlModule,
    VanpoolDtlModule,
    RateHistoryModule,
    VanpoolAddModule,
    VehicleAddModule,
    VehicleDtlModule,
    VehicleHistoryModule,
    PayrollDeductModule,
    PayrollDeductRunModule,
  ],
})
export class AdminModule {}
