<div class="d-flex flex-column">
  <div class="d-flex flex-column">
    <header>
      <div class="d-flex justify-content-between align-items-end">
        <h2 class="my-1 text-primary">
          <a routerLink="/admin/" title="admin home">
            <i class="fa fa-home fa-lg"></i>
            <i class="fa fa-angle-right fa-lg"></i>
          </a>
          Vehicles History
        </h2>
      </div>
      <hr class="mt-0" />
    </header>
    <section class="d-flex flex-column flex-fill">
      <shared-datatable #datatable [columns]="vhclColumns" [getData]="getVhclData" [defaultRow]="vhclDefaultRow"
        [sorts]="vhclSorts" [filter]="vhclFilter" [filterChoices]="vhclFilterChoices" class="bootstrap"
        style="height: 530px;">
      </shared-datatable>
    </section>
  </div>
  <div class="d-flex flex-column">
    <header>
      <div class="d-flex justify-content-between align-items-end">
        <h2 class="my-1 text-primary">
          <a routerLink="/admin/" title="admin home">
            <i class="fa fa-home fa-lg"></i>
            <i class="fa fa-angle-right fa-lg"></i>
          </a>
          Loaner History
        </h2>
      </div>
      <hr class="mt-0" />
    </header>
    <section class="d-flex flex-column flex-fill">
      <shared-datatable #datatable [columns]="loanerColumns" [getData]="getLoanerData" [defaultRow]="loanerDefaultRow"
        [sorts]="loanerSorts" [filter]="loanerFilter" [filterChoices]="loanerFilterChoices" class="bootstrap"
        style="height: 530px;">
      </shared-datatable>
    </section>
  </div>
</div>