export class Mileage {
  id: number = 0;
  vpoolid: number = 0;
  vpname: string = "";
  vanid: number = 0;
  eqno: string = "";
  start_date: Date = new Date(0);
  begin_mlg: number = 0;
  end_mlg: number = 0;
  ttl_mlg: number = 0;
  lnr_mlg: number = 0;
  grnd_ttl: number = 0;
  yr_avg: number = 0;
  comment: string = "";
}