import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VanpoolAddRoutingModule } from './vanpool-add-routing.module';
import { VanpoolAddComponent } from './vanpool-add/vanpool-add.component';
import { ModalService, SharedModule } from 'src/app/shared';
import { ConfigService, Util } from 'src/app/core';
import { FormsModule } from '@angular/forms';
import { SpinnerModule } from '@uiowa/spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [VanpoolAddComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    SpinnerModule,
    NgbModule,
    VanpoolAddRoutingModule
  ],
  providers: [
    ModalService,
    ConfigService,
    Util
  ]
})
export class VanpoolAddModule { }
