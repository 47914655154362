import { Component, OnInit, OnDestroy } from '@angular/core';
import { CompleterItem } from '@vldmr-bus/ng-completer';
import { of, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { PersonData } from "./person-data";
import { PeopleService } from 'src/app/core/services/people.service';
import { PersonLookup } from "../../../core/models/person-lookup";
import { ModalService } from 'src/app/shared';
import { PersonInfo } from 'src/app/core/models/person-info';
import { MemberRecord } from 'src/app/core/models/member-record';
import { TableColumn } from '@siemens/ngx-datatable';
import { MembersService, WaitlistService, Util, UserService, ImpersonationService, ApplicationUser } from 'src/app/core';
import { WlRequest } from 'src/app/core/models/wl-request';

@Component({
  selector: 'admin-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss']
})
export class PeopleComponent implements OnInit, OnDestroy {
  selectedPerson:any;
  personsData: PersonData;
  info: PersonInfo;
  homePhone: string;
  cellPhone: string;
  loading = false;

  membHistoryColumns: TableColumn[];
  membHistoryData: MemberRecord[];
  wlHistoryColumns: TableColumn[];
  wlHistoryData: WlRequest[];

  private userSubscription: Subscription;
  private user:ApplicationUser;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private pplSvc: PeopleService,
    private membSvc: MembersService,
    private wlSvc: WaitlistService,
    private readonly userService: UserService,
    private readonly impersonationService: ImpersonationService,
    private modal: ModalService
  ) {
    this.personsData = new PersonData(this.pplSvc);
  }

  ngOnInit(): void {
    this.userSubscription = this.userService.user$.subscribe(user =>this.user = user);

    const em = Util.getEM();
    this.membHistoryColumns = [
      { name: "Vanpool", prop: "vpname", width: 12*em },
      { name: "Member Type", prop: "type" },
      { name: "Start Date", prop: "startDate", width: 6 * em },
      { name: "End Date", prop: "endDate", width: 6 * em },
      { name: "Membership End Reason", prop: "end_reason", width: 12 * em },
    ];
    this.wlHistoryColumns = [
      { name: "Vanpool", prop: "vpname", width: 12*em },
      { name: "Pos.", prop: "position" },
      { name: "Request Date", prop: "startDate", width: 6 * em },
      { name: "Removed Date", prop: "endDate", width: 6 * em },
      { name: "Status", prop: "status" },
    ];
    this.activatedRoute.queryParams.subscribe(p => this.loadData(p["uid"]));
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }


  reset() {
    this.selectedPerson=undefined;
    this.info = undefined;
    this.membHistoryData = undefined;
    this.wlHistoryData = undefined;
  }

  loadData(uid: string) {
    if (!uid) {
      this.reset();
      return;
    }
    this.pplSvc.getPersonInfo(uid)
      .subscribe(
        i => {
          this.loading = false;
          this.info = i;
          this.homePhone = i.homePhone;
          this.cellPhone = i.cellPhone;
          this.selectedPerson = i.displayName;
        },
        e => {
          this.loading=false;
          this.reset();
          this.modal.error(e).subscribe();
        }
      );
    this.membSvc.getAll({OrderBy:"start_date.desc",Filter:[`univId=~${uid}`]})
        .subscribe(
          dataset => this.membHistoryData = [...dataset.data.map(m => {return {
            startDate: Util.dateString(m.start_date),
            endDate: Util.dateString(m.end_date),
            ...m
          }})
          ],
          e => this.loading = !this.modal.error(e).subscribe()
        );
      this.wlSvc.getAll({ OrderBy: "date.desc", Filter: [`univId=~${uid}`]})
      .subscribe(
        dataset => this.wlHistoryData = [...dataset.data.map(r => {
          return {
            startDate: Util.dateString(r.date),
            endDate: Util.dateString(r.removed_date),
            ...r
          }
        })
        ],
        e => this.loading = !this.modal.error(e).subscribe()
      )
  }

  onSelected(selected: CompleterItem) {
    let p = selected.originalObject as PersonLookup;
    if (p && p.uid) {
      let uid = p.uid;
      this.router.navigate(this.activatedRoute.snapshot.children,  {queryParams:{uid}});  
    } else 
      this.router.navigate(this.activatedRoute.snapshot.children);
  }

  onMembSelect(event: { row: MemberRecord, type: string }) {
    if (event.type != "click")
      return;
    this.router.navigate(['admin', 'memb-dtl', event.row.id]);
  }

  onWlSelect(event: { row: WlRequest, type: string }) {
    if (event.type != "click")
      return;
    this.router.navigate(['admin', 'wl-rq-dtl', event.row.id]);
  }


  savePhones() {
    let data = {
      univid:this.info.univId,
      homePhone: this.homePhone,
      cellPhone: this.cellPhone
    };
    this.pplSvc.updatePersonInfo(data).subscribe(
      res => res && this.loadData(this.info.univId),
      e => this.loading = !this.modal.error(e).subscribe()
    )
  }

  addToWaitlists() {
    if (this.user.hawkId == this.info.hawkeyeId) {
      this.router.navigate(['joinwl']);
    } else {
      this.impersonationService.impersonate(this.info.hawkeyeId,'joinwl');
    }

  }
}
